<script setup lang="ts">
import ApiService from '@/api/ApiService'
import type { SearchCustomerRequest } from '@/api/models/customer/SearchCustomerRequest'
import type { ToastMessageOptions } from 'primevue/toast'
import type { SelectCustomer } from '@/api/models/customer/SelectCustomer'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import type { CustomerDataTable } from '@/models/CustomerDataTable'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'
import { CustomerMapper } from '@/utils/CustomerDataTableMapper'
import { useRouter } from 'vue-router'
import ConversionUtility from '@/utils/ConversionUtility'
import SearchComponent from '@/components/SearchComponent.vue'
import Storage from '@/utils/Storage'
import LoadingComponent from '@/components/LoadingComponent.vue'
import NotFound from '@/assets/not_found.svg'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { Customer } from '@/api/models/customer/Customer'
import type { EditCustomerRequest } from '@/api/models/customer/EditCustomerRequest'
import type { UpdatedBy } from '@/api/models/customer/AddCustomerRequest'
import ValidationUtility from '@/utils/ValidationUtility'
import InputNumber from 'primevue/inputnumber'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

const toast = useToast()
const router = useRouter()
const customer = ref<CustomerDetailsData>()
const selectCustomer = ref<SelectCustomer>()
const mappedCustomers = ref<CustomerDataTable[]>([])
const searchQuery = ref('')
const isLoading = ref(false)
const visibleBottom = ref(false)
const visibleEditCustomer = ref(false)
const editCustomerDetails = ref<Customer>()

const columns = [
  { field: 'customerId', header: 'Cust ID' },
  { field: 'customerName', header: 'Name' },
  { field: 'phoneNumber', header: 'Phone Number' },
  { field: 'aadharNumber', header: 'Aadhar Number' },
  { field: 'panNumber', header: 'PAN Number' },
  { field: 'address', header: 'Address' },
  { field: 'pincode', header: 'Pincode' }
]

const CustomerAccountDetails = [
  { field: 'accountId', header: 'ID / Number' },
  { field: 'accountType', header: 'Type' }
]

const CustomerDetails = [
  { field: 'customerId', header: 'Cust ID' },
  { field: 'name', header: 'Name' },
  { field: 'gender', header: 'Gender' },
  { field: 'dateOfBirth', header: 'DOB' },
  { field: 'phoneNumber', header: 'Phone Number' },
  { field: 'email', header: 'Email' },
  { field: 'aadharNumber', header: 'Aadhar Number' },
  { field: 'panNumber', header: 'PAN Number' },
  { field: 'drivingLicence', header: 'Driving Licence' },
  { field: 'address', header: 'Address' },
  { field: 'city', header: 'City' },
  { field: 'taluk', header: 'Taluk' },
  { field: 'district', header: 'District' },
  { field: 'pincode', header: 'Pincode' },
  { field: 'updatedAt', header: 'Created on' }
]

const pigmyAccountDetails = [
  { field: 'id', header: 'ID' }
]

const shareholderDetails = [
  { field: 'id', header: 'ID' },
  { field: 'customerId', header: 'Customer ID' }
]
function handleCustomerSearch(query: string) {
  isLoading.value = true
  searchQuery.value = query
  if (!searchQuery.value) {
    showToast('Invalid search query', 'Please provide search details of the customer', 'error')
    isLoading.value = false
    mappedCustomers.value = []
    return
  }
  const searchCustomerRequest: SearchCustomerRequest = {
    query: searchQuery.value
  }
  ApiService.searchCustomer(searchCustomerRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        mappedCustomers.value = CustomerMapper.mapToTableData(response.data ?? [])
      } else {
        mappedCustomers.value = []
        showToast('Failed to search customer', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      mappedCustomers.value = []
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const onRowClick = (onClickResponseData: any) => {
  isLoading.value = true
  const getCustomerRequest: CustomerDetailsRequest = {
    cusId: String(onClickResponseData.data.customerId)
  }
  ApiService.getCustomerDetails(getCustomerRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customer.value = response.data
        editCustomerDetails.value = JSON.parse(JSON.stringify(customer.value.customer))
        selectCustomer.value = CustomerMapper.mapToSelectCustomer(customer.value.customer)
        visibleBottom.value = true
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function closeDialog(){
  visibleEditCustomer.value = false
  editCustomerDetails.value = JSON.parse(JSON.stringify(customer.value?.customer!))
}
function updateCustomerDetails(){
  if (!ValidationUtility.validateString(editCustomerDetails.value?.firstName.trim()!)) {
    showToast('First name is invalid/empty', 'Validation failed!', 'error')
    return
  }
  if (editCustomerDetails.value?.middleName! && !ValidationUtility.validateString(editCustomerDetails.value?.middleName.trim()!)) {
    showToast('Middle name is invalid', 'Validation failed!', 'error')
    return
  }
  if (!ValidationUtility.validateString(editCustomerDetails.value?.lastName.trim()!)) {
    showToast('Last name is invalid/empty', 'Validation failed!', 'error')
    return
  }
  if (!editCustomerDetails.value?.phoneNumber || editCustomerDetails.value?.phoneNumber.startsWith('0')) {
    showToast('Phone number is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (Number(editCustomerDetails.value?.phoneNumber[0]) != 6 && Number(editCustomerDetails.value?.phoneNumber[0] ) != 7 && Number(editCustomerDetails.value?.phoneNumber[0]) != 8 && Number(editCustomerDetails.value?.phoneNumber [0]) != 9) {
    showToast('Invalid Phone Number', 'Phone Number must start with (6, 7, 8, 9)', 'error');
    return
  }

  if (editCustomerDetails.value?.email! && !ValidationUtility.checkEmail(editCustomerDetails.value?.email!)) {
    showToast('Email is invalid', 'Validation failed!', 'error')
    return
  }

  if (!editCustomerDetails.value.aadharNumber || editCustomerDetails.value.aadharNumber.startsWith('0')) {
    showToast('Aadhar number is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!editCustomerDetails.value.panNumber) {
    showToast('PAN number is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!editCustomerDetails.value?.houseNumber) {
    showToast('House no is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!editCustomerDetails.value?.addressLine1) {
    showToast('Address Line 1 is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(editCustomerDetails.value?.city)) {
    showToast('City is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (editCustomerDetails.value?.city.length < 3) {
    showToast('City should be greater than 3 Characters', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(editCustomerDetails.value?.taluk!)) {
    showToast('Taulk is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (editCustomerDetails.value?.taluk.length < 3) {
    showToast('Taluk should be greater than 3 Characters', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(editCustomerDetails.value?.district)) {
    showToast('District is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (editCustomerDetails.value?.district.length < 3) {
    showToast('District should be greater than 3 Characters', 'Validation failed!', 'error')
    return
  }

  if (!editCustomerDetails.value?.pincode) {
    showToast('Pincode is invalid/empty', 'Validation failed!', 'error')
    return
  }

  isLoading.value = true
  const updatedBy: UpdatedBy = {
    empID: Storage.getEmpId()!!,
    updatedAt: Date.now()
  }

  const editCustomerRequest: EditCustomerRequest = {
    customerId: Number(editCustomerDetails.value?.customerId),
    firstName: editCustomerDetails.value?.firstName!,
    middleName: editCustomerDetails.value?.middleName!,
    lastName: editCustomerDetails.value?.lastName!,
    phoneNumber: editCustomerDetails.value?.phoneNumber!,
    email: editCustomerDetails.value?.email!,
    panNumber: editCustomerDetails.value.panNumber!.toUpperCase(),
    aadharNumber: editCustomerDetails.value.aadharNumber!,
    houseNumber: editCustomerDetails.value?.houseNumber!,
    addressLine1: editCustomerDetails.value?.addressLine1!,
    addressLine2: editCustomerDetails.value?.addressLine2!,
    city: editCustomerDetails.value?.city!,
    taluk: editCustomerDetails.value?.taluk!,
    district: editCustomerDetails.value?.district!,
    pincode: Number(editCustomerDetails.value?.pincode!),
    updatedAt: Date.now(),
    updatedBy: updatedBy
  }

  ApiService.editCustomer(editCustomerRequest)
  .then((response) => {
    if(response.statusCode == 200){
      showToast('Updated successfully', 'Saved', 'success')
    }
    else{
      showToast('Failed', response.data.toString(), 'error')
    }
  })
  .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      visibleEditCustomer.value = false
      visibleBottom.value = false
      handleCustomerSearch(editCustomerDetails.value?.customerId!)
    })
}

const generatePDF = (customer: CustomerDetailsData) => {
  const doc = new jsPDF();
  let y = 15; // Initial Y position

  // Bank Name at the Top
  doc.setFontSize(24);
  doc.text('Bhavsar Patini Sahakar Sanga(R),', 105, y, { align: 'center'  });
  doc.setFontSize(18);
  doc.text('Badami, Tq:Badami, Dist:Bagalkot', 105, y+10, { align: 'center' });
  y += 20;

  doc.setFontSize(18);
  doc.text('Customer Details', 14, y);
  y += 5;

  if (customer) {
    const customerDetails: string[][] = [
      ['Customer ID', customer.customer?.customerId || '', 'Aadhar Number', customer.customer?.aadharNumber || ''],
      ['Name', `${customer.customer?.firstName || ''} ${customer.customer?.middleName || ''} ${customer.customer?.lastName || ''}`.trim(), 'Gender', customer.customer?.gender || ''],
      ['Date of Birth', ConversionUtility.toDateFormat(customer.customer?.dateOfBirth!) || '', 'Phone Number', customer.customer?.phoneNumber || ''],
      ['Email', customer.customer?.email || '--', 'PAN Number', customer.customer?.panNumber || ''],
      ['Driving Licence', customer.customer?.drivingLicence || '--', 'Pincode', customer.customer?.pincode?.toString() || ''],
      ['Address', `${customer.customer?.houseNumber || ''}, ${customer.customer?.addressLine1 || ''}, ${customer.customer?.addressLine2 || ''}, ${customer.customer?.city || ''}, ${customer.customer?.taluk || ''}, ${customer.customer?.district || ''}`.replace(/,\s*,/g, ',').trim(), 'Created On', ConversionUtility.toDateFormat(customer.customer?.createdAt!) || ''],
    ];

    autoTable(doc, {
      startY: y,
      body: customerDetails,
      styles: { fontSize: 10 },
      columnStyles: {
        0: { fontStyle: 'bold' },
        2: { fontStyle: 'bold' }
      }
    });

    y += customerDetails.length * 10;
  }

  doc.setFontSize(18);
  doc.text('Savings Account Details', 14, y);
  y += 5;

  if (customer?.sbAccount) {
    autoTable(doc, {
      startY: y,
      body: [
        ['Account Number', customer.sbAccount.accountId || '---'],
        ['Balance', customer.sbAccount.balance ? ConversionUtility.toRuppesCoversion(customer.sbAccount.balance) + ' Rs' : '--'],
        ['Status', customer.sbAccount.isClosed ? 'Closed' : 'Active'],
        ['Created On', ConversionUtility.toDateFormat(customer.sbAccount.createdAt) || '--']
      ],
      theme: 'grid',
      styles: { fontSize: 10 }
    });
    y += 40;
  } else {
    doc.setFontSize(8);
    doc.text('No Savings Bank Account Found', 14, y);
    y += 10;
  }

  doc.setFontSize(18);
  doc.text('Shareholder Details', 14, y);
  y += 5;

  if (customer?.shareholder) {
    autoTable(doc, {
      startY: y,
      body: [
        ['Shareholder ID', customer.shareholder.id || '--'],
        ['Share Amount', customer.shareholder.shareAmount ? ConversionUtility.toRuppesCoversion(customer.shareholder.shareAmount) + ' Rs' : '--'],
        ['Created On', ConversionUtility.toDateFormat(customer.shareholder.createdAt) || '--']
      ],
      theme: 'grid',
      styles: { fontSize: 10 }
    });
    y += 40;
  } else {
    doc.setFontSize(8);
    doc.text('No Shareholder Details Found', 14, y);
    y += 10;
  }

  const sections = [
    { title: 'Pigmy Account Details', data: customer?.pigmyAccount, columns: [['ID', 'Pigmy Balance', 'Pigmy Amount', 'Created On']], map: (p: any) => [p.id, `${ConversionUtility.toRuppesCoversion(p.balance)} Rs`, `${ConversionUtility.toRuppesCoversion(p.pigmyAmount)} Rs`, ConversionUtility.toDateFormat(p.createdAt!)] },
    { title: 'FD Account Details', data: customer?.fdAccount, columns: [['ID', 'Principal Amount', 'ROI', 'Maturity Date', 'Created On']], map: (fd: any) => [fd.fdId, `${ConversionUtility.toRuppesCoversion(fd.principleAmount)} Rs`, `${fd.rateOfInterest} %`, ConversionUtility.toDateFormat(fd.maturityAt)!, ConversionUtility.toDateFormat(fd.createdAt!)] },
    { title: 'RD Account Details', data: customer?.rdAccount, columns: [['ID', 'Principal Amount', 'ROI', 'Maturity Date', 'Created On', 'Status']], map: (rd: any) => [rd.rdId, `${ConversionUtility.toRuppesCoversion(rd.totalDeposit)} Rs`, `${rd.rateOfInterest} %`, ConversionUtility.toDateFormat(rd.maturityAt), ConversionUtility.toDateFormat(rd.createdAt!), rd.isClosed ? 'Closed' : 'Active'] },
    { title: 'Loan Account Details', data: customer?.loanAccount, columns: [['Loan ID', 'Application ID', 'Type', 'Amount', 'ROI', 'Status']], map: (loan: any) => [loan.loanId,loan.applicationId, loan.loanType, `${ConversionUtility.toRuppesCoversion(loan.loanAmount)} Rs`, `${loan.interestRate} %`, (loan as any).loanStatus || loan.status] },
  ];

  sections.forEach((section) => {
    doc.setFontSize(18);
    doc.text(section.title, 14, y);
    y += 5;

    if (Array.isArray(section.data) && section.data.length > 0) {
      autoTable(doc, {
        startY: y,
        head: section.columns,
        body: section.data.map(section.map),
      });
      y = (doc as any).lastAutoTable.finalY + 15;
    } else {
      doc.setFontSize(8);
      doc.text(`No ${section.title} Found`, 14, y);
      y += 10;
    }
  });
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
  doc.save('CUS_ID_' + customer.customer?.customerId + '_details.pdf');
};

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <main class="main">
    <Toast class="error-p-toast" />
    <card class="hereCard">
      <template #content>
        <SearchComponent
          title="Search Customer"
          subtitle="Search customers using Customer ID, Phone Number or First Name."
          placeholder="Search Customer"
          @search-click="handleCustomerSearch"
        />
        <div v-if="mappedCustomers.length <= 0 && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip
            label="No customers found for the provided search query!"
            style="font-weight: 600"
          />
        </div>
        <div v-if="mappedCustomers.length > 0 && !isLoading">
          <DataTable
            :value="mappedCustomers"
            paginator
            :rows="10"
            :rows-per-page-options="[5, 10, 20]"
            selection-mode="single"
            @row-click="onRowClick"
          >
            <Column
              v-for="col of columns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
            />
            <template #paginatorend>
              <CustomButton
                title="clear"
                icon="times"
                @click="mappedCustomers = []"
                :outlined="true"
              />
            </template>
          </DataTable>
        </div>
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
      </template>
    </card>
    <Drawer
      v-model:visible="visibleBottom"
      header="Customer Details"
      position="bottom"
      style="height: 90vh"
    >
      <section style="overflow-y: scroll">
        <Divider style="margin-top: 0" />
        <section class="selectCustomerSection">
          <div v-for="column in CustomerDetails" :key="column.field" class="selectCustomerDiv">
            <span class="header">{{ column.header }}</span>
            <span class="field">{{
                (selectCustomer as any)[`${column.field}`]
                  ? (selectCustomer as any)[`${column.field}`]
                  : ' ---'
              }}</span>
          </div>
        </section>
        <div style="display: flex; justify-content: flex-end; margin: 20px;gap: 1rem">
              <CustomButton title="Edit" icon="pen" @click="visibleEditCustomer = true" v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2' || Storage.getEmpAccessRole() == null)"/>
            <CustomButton title="Print" icon="print" @click="generatePDF(customer!)" v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2' || Storage.getEmpAccessRole() == null)"/>

        </div>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Account Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="customer?.sbAccount">
          <div v-for="det in CustomerAccountDetails" :key="det.field"  class="selectCustomerDiv">
            <span class="header">{{ det.header }}</span>
            <span class="field">{{
                (customer.sbAccount as any)[`${det.field}`]
                  ? (customer.sbAccount as any)[`${det.field}`]
                  : ' ---'
              }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Balance</span>
            <span class="field">{{
                customer.sbAccount?.balance
                  ? ConversionUtility.toStringRupeesConversion(customer.sbAccount?.balance)
                  : '--'
              }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Status</span>
            <span class="field">{{ customer.sbAccount?.isClosed ? 'Closed' : 'Active' }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Created on</span>
            <span class="field">{{
                customer.sbAccount?.createdAt
                  ? ConversionUtility.toDateFormat(customer.sbAccount?.createdAt)
                  : '--'
              }}</span>
          </div>
        </section>
        <section v-else class="no-details-section">No SB Account Attached</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Pigmy Details</h2>
        <Divider />
        <section class="selectCustomerSection cont" v-if="(customer?.pigmyAccount as any)[0]" >
          <DataTable :value="(customer!!.pigmyAccount as any)" paginator :rows="5">
            <Column v-for="col in pigmyAccountDetails" :key="col.field" :field="col.field" :header="col.header" />
            <Column header="Pigmy Balance" field="balance" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.balance) }}
              </template>
            </Column>
            <Column header="Pigmy Amount" field="pigmyAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.pigmyAmount) }}
              </template>
            </Column><Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">No Pigmy Account Attached</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">FD Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="(customer?.fdAccount as any)[0]">
          <DataTable :value="(customer!!.fdAccount as any)">
            <Column header="Id" field="fdId" />
            <Column header="Principal Amount" field="principleAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.principleAmount) }}
              </template>
            </Column>
            <Column header="Rate of Interest" field="rateOfInterest" >
              <template #body="slotProps">
                {{ slotProps.data.rateOfInterest }} %
              </template>
            </Column>
            <Column header="Matures On" field="maturityAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.maturityAt) }}
            </template>
          </Column>
            <Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">The Customer does not have any FD's.</section>
<Divider/>
        <h2 class="text-2xl font-bold" style="font-weight: 550">RD Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="(customer?.rdAccount as any)[0]">
          <DataTable :value="(customer!!.rdAccount as any)">
            <Column header="Id" field="rdId" />
            <Column header="Principal Amount" field="principleAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.totalDeposit) }}
              </template>
            </Column>
            <Column header="Rate of Interest" field="rateOfInterest" >
              <template #body="slotProps">
                {{ slotProps.data.rateOfInterest }} %
              </template>
            </Column>
            <Column header="Matures On" field="maturityAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.maturityAt) }}
            </template>
          </Column>
            <Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column header="Created On">
            <template #body="slotProps">
              <Tag :severity="slotProps.data.isClosed ? 'warn' : 'success'" class="text"
                >{{ slotProps.data.isClosed ? 'Closed' : 'Active' }}<br
              /></Tag>
            </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">The Customer does not have any RD's.</section>



        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Loan Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="(customer?.loanAccount as any)[0]">
          <DataTable :value="(customer!!.loanAccount as any)">
            <Column header="Id" field="loanId" />
            <Column header="Loan Type" field="loanType" />
            <Column header="Application Id" field="applicationId" />
            <Column header="Principal Amount" field="loanAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.loanAmount) }}
              </template>
            </Column>
            <Column header="Rate of Interest" field="interestRate" >
              <template #body="slotProps">
                {{ slotProps.data.interestRate }} %
              </template>
            </Column>
            <Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column header="Status">
          <template #body="slotProps">
            <Tag :severity="slotProps.data.loanStatus == 'ACTIVE' ? 'success' : 'warn'" class="text"
                >{{ slotProps.data.loanStatus }}<br
              /></Tag>
          </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">The Customer does not have any Loan accounts open.</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Shareholder Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="customer?.shareholder">
          <div v-for="details in shareholderDetails" :key="details.field" class="selectCustomerDiv">
            <span class="header">{{ details.header }}</span>
            <span class="field">{{(customer.shareholder as any)[`${details.field}`]}}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Share Amount</span>
            <span class="field">{{
                  ConversionUtility.toStringRupeesConversion(customer.shareholder?.shareAmount)
              }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Created on</span>
            <span class="field">{{
                ConversionUtility.toDateFormat(customer.shareholder?.createdAt)
              }}</span>
          </div>
        </section>
        <section v-else class="no-details-section">The Customer is not a Shareholder.</section>
      </section>
    </Drawer>


    <Dialog
    v-model:visible="visibleEditCustomer"
    :draggable="false"
    modal
    :style="{ width: '60rem' }"
    :closable="false"
  >
    <span class="dialog-header">Edit existing customer details.</span>
    <div style="display: flex; justify-content: flex-start; margin-left: 30px">
      <label style="font-size: 12px; color: #181818; font-style: italic">
        Note: Only some information/details can be changed
      </label>
    </div>
    <Divider />
    <div class="form" v-if="editCustomerDetails">
      <FloatLabel>
        <InputText id="cusId" v-model="editCustomerDetails.customerId" disabled class="input-text" size="large" />
        <label for="cusId" class="mandatory">Customer ID</label>
      </FloatLabel>

      <FloatLabel>
        <InputText id="firstName" v-model="editCustomerDetails.firstName" class="input-text" size="large" />
        <label for="firstName" class="mandatory">First Name</label>
      </FloatLabel>

      <FloatLabel>
        <InputText id="middleName" v-model="editCustomerDetails.middleName" class="input-text" size="large" />
        <label for="middleName">Middle Name</label>
      </FloatLabel>

      <FloatLabel>
        <InputText id="lastName" v-model="editCustomerDetails.lastName" class="input-text" size="large" />
        <label for="lastName" class="mandatory">Last Name</label>
      </FloatLabel>

      <FloatLabel>
        <InputText id="gender" v-model="editCustomerDetails.gender" disabled class="input-text" size="large" />
        <label for="gender" class="mandatory">Gender</label>
      </FloatLabel>

      <FloatLabel>
        <InputMask
          id="phoneNumber"
          v-model="editCustomerDetails.phoneNumber"
          class="input-text"
          size="large"
          mask="99999-99999?"
          placeholder="999-99-9999?"
        />
        <label for="phoneNumber" class="mandatory">Phone Number</label>
      </FloatLabel>

      <FloatLabel>
        <InputText id="email" v-model="editCustomerDetails.email" class="input-text" size="large" />
        <label for="email" class="">Email</label>
      </FloatLabel>

      <FloatLabel>
        <InputText id="dateOfBirth" v-bind:model-value ="ConversionUtility.toDateFormat(editCustomerDetails?.dateOfBirth!)" disabled class="input-text" size="large" />
        <label for="dateOfBirth" class="mandatory">Date of Birth</label>
      </FloatLabel>

      <FloatLabel>
        <InputMask
          id="aadharNumber"
          v-model="editCustomerDetails.aadharNumber"
          class="input-text"
          size="large"
          mask="9999-9999-9999?"
          placeholder="9999-9999-9999?"
        />
        <label for="aadharNumber" class="mandatory">Aadhar Number</label>
      </FloatLabel>

      <FloatLabel>
        <InputMask
          id="panNumber"
          v-model="editCustomerDetails.panNumber"
          class="input-text upper"
          size="large"
          mask="aaaaa9999a?"
          placeholder="aaaaa9999a?"
        />
        <label for="panNumber" class="mandatory">Pan Number</label>
      </FloatLabel>

      <FloatLabel>
            <InputMask
              id="drivingLicence"
              v-model="editCustomerDetails.drivingLicence"
              class="input-text upper"
              size="large"
              mask="aa99-99999999999?"
              placeholder="aa99-99999999999?"
              disabled
            />
            <label for="drivingLicence">Driving Licence</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="houseNumber" v-model="editCustomerDetails.houseNumber" class="input-text" size="large" />
            <label for="houseNumber" class="mandatory">House No</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="addressLine1" v-model="editCustomerDetails.addressLine1" class="input-text" size="large" />
            <label for="addressLine1" class="mandatory">Address Line 1</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="addressLine2" v-model="editCustomerDetails.addressLine2" class="input-text" size="large" />
            <label for="addressLine2">Address Line 2</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="city" v-model="editCustomerDetails.city" class="input-text" size="large" />
            <label for="city" class="mandatory">City</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="taluk" v-model="editCustomerDetails.taluk" class="input-text" size="large" />
            <label for="taluk" class="mandatory">Taluk</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="district" v-model="editCustomerDetails.district" class="input-text" size="large" />
            <label for="district" class="mandatory">District</label>
          </FloatLabel>
          <FloatLabel>
            <InputNumber
              id="pincode"
              v-model="editCustomerDetails.pincode"
              class="input-text"
              :min="100000"
              :max="999999"
              placeholder="6-digit pin code"
              mode="decimal" 
              :useGrouping="false"
            />
            <label for="pincode" class="mandatory">Pin Code</label>
          </FloatLabel>
    </div>
    <template #footer>
      <div style="margin-top: 1rem; display: flex; gap: 1rem">
        <CustomButton
          :outlined="true"
          title="Close"
          icon="times"
          @click="closeDialog"
        />
        <CustomButton title="Save" icon="check" @click="updateCustomerDetails" />
      </div>
    </template>
  </Dialog>


  </main>
</template>

<style scoped>
.main {
  overflow-y: scroll;
}
.hereCard {
  width: 100%;
  margin-bottom: 4rem;
}
.form{
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}
.selectCustomerSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  row-gap: 16px;
}
.selectCustomerDiv {
  display: flex;
  flex-direction: column;
  .header {
    font-size: small;
    margin-bottom: 5px;
  }
  .field {
    font-weight: 600;
  }
}
.no-details-section {
  display: flex;
  justify-content: center;
  font-weight: 600;
  margin: 2rem;
}
</style>