<script setup lang="ts">
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import ProgressBar from 'primevue/progressbar'
import Card from 'primevue/card'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import type { Cashbook } from '@/api/models/reports/Cashbook'
import type { CashbookRequest } from '@/api/models/reports/CashbookRequest'
import ConversionUtility from '../utils/ConversionUtility'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

const isLoading = ref<boolean>(false)
const selectedDate = ref<Date | null>(null)
const cashbook = ref<Cashbook | null>(null)
const toast = useToast()

function handlePrint(cashbook: Cashbook){
  const doc = new jsPDF();
  let y = 15; // Initial Y position

  // Bank Name Header
  doc.setFontSize(24);
  doc.text('Bhavsar Patini Sahakar Sanga(R),', 105, y, { align: 'center' });
  doc.setFontSize(18);
  doc.text('Badami, Tq:Badami, Dist:Bagalkot', 105, y + 10, { align: 'center' });
  y += 20;

  // Title
  doc.setFontSize(18);
  doc.text('Cashbook Report', 14, y);
  y += 10;

  // Date of Entry
  doc.setFontSize(12);
  doc.text(`Date: ${ConversionUtility.toDateFormat(cashbook.createdAt)}`, 14, y);
  y += 10;

  // Cashbook Summary Table
  const summaryData = [
    ['Opening Balance', `${ConversionUtility.toRuppesCoversion(cashbook.openingBalance)}`],
    ['Closing Balance', `${ConversionUtility.toRuppesCoversion(cashbook.closingBalance)}`]
  ];

  autoTable(doc, {
    startY: y,
    body: summaryData,
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 3 }
  });

  y = (doc as any).lastAutoTable.finalY + 10;

  // Credit Details
  doc.setFontSize(16);
  doc.text('Credit Transactions', 14, y);
  y += 5;

  autoTable(doc, {
    startY: y,
    body: [
      ['Income', `${ConversionUtility.toRuppesCoversion(cashbook.credit.income)}`],
      ['Cash Inflow', `${ConversionUtility.toRuppesCoversion(cashbook.credit.cashInFlow)}`],
      ['Pigmy Collection', `${ConversionUtility.toRuppesCoversion(cashbook.credit.pigmyCollection)}`],
      ['Shareholder Deposit', `${ConversionUtility.toRuppesCoversion(cashbook.credit.shareholderDeposit)}`]
    ],
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 3 }
  });

  y = (doc as any).lastAutoTable.finalY + 10;

  // Debit Details
  doc.setFontSize(16);
  doc.text('Debit Transactions', 14, y);
  y += 5;

  autoTable(doc, {
    startY: y,
    body: [
      ['Expense', `${ConversionUtility.toRuppesCoversion(cashbook.debit.expense)}`],
      ['Cash Outflow', `${ConversionUtility.toRuppesCoversion(cashbook.debit.cashOutFlow)}`],
      ['Employee Salary', `${ConversionUtility.toRuppesCoversion(cashbook.debit.employeeSalary)}`]
    ],
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 3 }
  });

  // Print & Save
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
  doc.save(`CASHBOOK_${cashbook.createdAt}.pdf`);
};

function getCashBook() {
  console.log(selectedDate.value)
  if (!selectedDate.value) {
    showToast('Error', 'Please select a date.', 'error')
    return
  }

  isLoading.value = true
  const getMidnightTimestamp = (date: Date): number => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime()
  }

  const midnightTimestamp = getMidnightTimestamp(selectedDate.value)
  const req: CashbookRequest = {
    date: midnightTimestamp
  }
  ApiService.cashbook(req)
    .then((response) => {
      console.log('API Response:', response) // Debugging Log

      if (!response || !response.data) {
        showToast('Error', 'Invalid response from the server.', 'error')
        return
      }

      // if (response.statusCode === 401) {
      //   Storage.clearData()
      //   router.push({ name: 'login' })
      // }
      if (response.statusCode === 200) {
        cashbook.value = response.data
      }
    })
    .catch((error) => {
      console.error('API Error:', error) // Log full error for debugging
      showToast('Cashbook', error.message || 'An error occurred', 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(
  summary: string,
  detail: string,
  severity: 'success' | 'info' | 'warn' | 'error'
) {
  toast.add({
    severity,
    summary,
    detail,
    life: 5000
  })
}
</script>

<template>
  <Toast />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="text-3xl font-bold">Cashbook</h2>
      </template>
      <template #content>
        <Divider />
        <div style="width: 20vw; margin-top: 2rem">
          <FloatLabel>
            <label for="date" class="mandatory">Date</label>
            <DatePicker
              v-model="selectedDate"
              id="date"
              class="input-text"
              :maxDate="new Date()"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>
        </div>
        <Panel class="mt-4" v-if="cashbook" header="Cashbook">
          <Divider style="margin-top: 0" />
          <div class="flex flex-row justify-between">
            <div class="flex flex-col items-start">
              <span class="text-heading">Opening Balance :</span>
              <span class="text"
                >Rs. {{ ConversionUtility.toRuppesCoversion(cashbook.openingBalance) }}</span
              >
            </div>
            <div class="flex flex-col items-start">
              <span class="text-heading">Closing Balance :</span>
              <span class="text"
                >Rs. {{ ConversionUtility.toRuppesCoversion(cashbook.closingBalance) }}</span
              >
            </div>
          </div>
          <Divider />
          <div class="flex flex-row mt-5">
            <div class="flex flex-col items-start w-1/2 gap-5">
              <span class="text-xl font-bold">Credit</span>
              <Divider />
              <div class="flex flex-col items-start">
                <span class="text-heading">Income :</span>
                <span class="text"
                  >Rs. {{ ConversionUtility.toRuppesCoversion(cashbook.credit.income) }}</span
                >
              </div>
              <div class="flex flex-col items-start">
                <span class="text-heading">Cash In-Flow :</span>
                <span class="text"
                  >Rs. {{ ConversionUtility.toRuppesCoversion(cashbook.credit.cashInFlow) }}</span
                >
              </div>
              <div class="flex flex-col items-start">
                <span class="text-heading">Pigmy Cash Collection :</span>
                <span class="text"
                  >Rs.
                  {{ ConversionUtility.toRuppesCoversion(cashbook.credit.pigmyCollection) }}</span
                >
              </div>
              <div class="flex flex-col items-start">
                <span class="text-heading">Shareholder Deposit :</span>
                <span class="text"
                  >Rs.
                  {{
                    ConversionUtility.toRuppesCoversion(cashbook.credit.shareholderDeposit)
                  }}</span
                >
              </div>
            </div>
            <Divider layout="vertical" />
            <div class="flex flex-col items-start w-1/2 gap-5">
              <span class="text-xl font-bold">Debit</span>
              <Divider />
              <div class="flex flex-col items-start">
                <span class="text-heading">Expense :</span>
                <span class="text"
                  >Rs. {{ ConversionUtility.toRuppesCoversion(cashbook.debit.expense) }}</span
                >
              </div>
              <div class="flex flex-col items-start">
                <span class="text-heading">Cash Out-Flow :</span>
                <span class="text"
                  >Rs. {{ ConversionUtility.toRuppesCoversion(cashbook.debit.cashOutFlow) }}</span
                >
              </div>
              <div class="flex flex-col items-start">
                <span class="text-heading">Employee Salaries :</span>
                <span class="text"
                  >Rs.
                  {{ ConversionUtility.toRuppesCoversion(cashbook.debit.employeeSalary) }}</span
                >
              </div>
            </div>
          </div>
          <Divider />
          <div class="mt-5 w-full flex flex-row-reverse">
            <CustomButton icon="print" title="Print Cashbook" @click="handlePrint(cashbook)"/>
          </div>
        </Panel>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: flex-start; margin-top: 20px; gap: 1rem">
          <CustomButton icon="book" title="Fetch Cashbook" @click="getCashBook()" />
        </div>

        <Dialog
          :visible="isLoading"
          modal
          :draggable="false"
          :closable="false"
          style="display: flex; align-items: center"
        >
          <span>Please wait while we generate your cashbook.</span>
          <ProgressBar mode="indeterminate" style="height: 6px; margin-top: 1.5rem"></ProgressBar>
        </Dialog>
      </template>
    </Card>
  </main>
</template>

<style scoped></style>
