<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue'
import Card from 'primevue/card'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import type { ToastMessageOptions } from 'primevue/toast'
import ConversionUtility from '@/utils/ConversionUtility'
import * as XLSX from 'xlsx'
import ProgressBar from 'primevue/progressbar'
import type GetAllPigmyReportsReq from '@/api/models/reports/GetAllPigmyReportsReq'
import type AllPigmyReportsData from '@/api/models/reports/AllPigmyReportsData'

const loadingProgress = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const nextPageKey = ref(null)
const minDate = ref(new Date(2024, 8, 1))
const maxDate = ref(new Date())
const fromDate = ref<Date>()
const toDate = ref<Date | null>()
const FDate = ref()
const EDate = ref()
const isClosed = ref(false)
const pigmyReports = ref<AllPigmyReportsData[]>([])

function getPigmyReports(nextKey: number) {
  if (fromDate.value && !toDate.value) {
    showToast('To Date cannot be empty', 'You have selected from date. Please Select To Date', 'error')
    return
  }

  if (toDate.value && !fromDate.value) {
    showToast('From Date cannot be empty', 'You have selected to date. Please Select To Date', 'error')
    return
  }

  isLoading.value = true

  const requestParam: GetAllPigmyReportsReq = {
    startDate: fromDate.value ? new Date(fromDate.value).setHours(0, 0, 0, 0) : null,
    endDate: toDate.value ? new Date(toDate.value).setHours(23, 59, 59, 999) : null,
    isClosed: isClosed.value ? isClosed.value : null,
    nextPageKey: nextKey
  }

  ApiService.getAllPigmyReports(requestParam)
    .then((response) => {
      if (response.statusCode == 200) {
        pigmyReports.value.push(...response.data.data)
        if (response.data.nextPageKey != null) {
          getPigmyReports(response.data.nextPageKey)
        }
        if (response.data.nextPageKey == null) {
          downloadReport()
        }
      } else {
        showToast('Error', 'No customers', 'error')
      }
    })
    .catch((error) => {
      showToast('Errorerr', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function downloadReport() {
  loadingProgress.value = 25
  isLoading.value = true

  const reportDataDownload = [
    ['Bhavasar Patini Sahakar Sanga', '', '', '', '', '', '', '', '', '', ''],
    ['Tq: Badami, Dis: Bagalkot', '', '', '', '', '', '', '', '', '', ''],
    [],
    ['Pigmy Report Detail'],
    ['Total', pigmyReports.value.length],
    [],
    [
      'Sl No',
      'Customer Name',
      'Pigmy ID',
      'Customer ID',
      'Pigmy Amount',
      'Balance',
      'Interest Amount',
      'Penalty Amount',
      'SB ID',
      'Created On',
      'Status'
    ]
  ]

  let index = 0
  pigmyReports.value?.forEach((pigmyReport) => {
    index = index + 1
    reportDataDownload.push([
      index,
      pigmyReport.customerName,
      pigmyReport.id,
      pigmyReport.cusId,
      ConversionUtility.toRuppesCoversion(pigmyReport.pigmyAmount),
      ConversionUtility.toRuppesCoversion(pigmyReport.balance),
      ConversionUtility.toRuppesCoversion(pigmyReport.interestAmount),
      ConversionUtility.toRuppesCoversion(pigmyReport.penaltyAmount),
      pigmyReport.transferredSBId,
      ConversionUtility.toDateFormat(pigmyReport.createdAt),
      isClosed.value ? 'Closed' : ''
    ])
  })

  const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload)

  // Merge cells to center the bank name and location
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, // Merge A1:K1 for Bank Name
    { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } } // Merge A2:K2 for Location
  ]

  // Apply styles for bank name and location
  worksheet['A1'].s = {
    font: { bold: true, sz: 18 },
    alignment: { horizontal: 'center', vertical: 'center' }
  }
  worksheet['A2'].s = {
    font: { bold: true, sz: 16 },
    alignment: { horizontal: 'center', vertical: 'center' }
  }

  worksheet['!cols'] = [
    { wch: 5 },
    { wch: 25 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 10 }
  ]
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    `All_Pigmy_Detail_Report_as_on_${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`
  )
  document.body.appendChild(link)
  link.click()

  isLoading.value = false
  pigmyReports.value = []
  showToast('Success', 'Report has been downloaded', 'success')
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <Toast class="error-p-toast" />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Customers Report</h2>
      </template>
      <template #content>
        <Divider />
        <div class="form-2">
          <FloatLabel>
            <label for="fromDate">From</label>
            <DatePicker
              v-model="fromDate"
              id="fromDate"
              class="input-text"
              :min-date="minDate"
              :max-date="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>
          <FloatLabel>
            <label for="toDate">To</label>
            <DatePicker
              v-model="toDate"
              id="toDate"
              class="input-text"
              :min-date="fromDate"
              :maxDate="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>

          <div style="align-items: start; display: flex; justify-content: space-evenly" >
            <label for="toDate">Get only Closed: </label>
            <ToggleSwitch v-model="isClosed" />
          </div>
          <CustomButton title="Download" @click="getPigmyReports(0)" class="cstmbtn" />
        </div>

        <Dialog
          :visible="isLoading"
          modal
          :draggable="false"
          :closable="false"
          style="width: 60vw"
          header="Downloading....."
        >
          <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
        </Dialog>
      </template>
    </Card>
  </main>
</template>
<style></style>
