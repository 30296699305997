<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { ref } from 'vue'
import type { Account } from '@/api/models/account/Account'
import type { SearchAccountRequest } from '@/api/models/account/SearchAccountRequest'
import type { ToastMessageOptions } from 'primevue/toast'
import type { AccountWithdrawalBy } from '@/api/models/account/AccountWithdrawalBy'
import type { AccountWithdrawRequest } from '@/api/models/account/AccountWithdrawRequest'
import type { AccountDepositBy } from '@/api/models/account/AccountDepositBy'
import type { AccountDepositRequest } from '@/api/models/account/AccountDepositRequest'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import SearchComponent from '@/components/SearchComponent.vue'
import ApiService from '@/api/ApiService'
import router from '@/router'
import Storage from '@/utils/Storage'
import NotFound from '@/assets/not_found.svg'
import ConversionUtility from '@/utils/ConversionUtility'
import type { SBStatementRequest } from '@/api/models/account/SBStatementRequest'
import type { TransactionDetails } from '@/api/models/account/TransactionDetails'
import ValidationUtility from '@/utils/ValidationUtility'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import type { CloseAccountRequest } from '@/api/models/account/CloseAccountRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import autoTable from 'jspdf-autotable'
import { jsPDF } from 'jspdf'

const toast = useToast()
const confirmDialog = useConfirm()
const searchQuery = ref<string>('')
const customer = ref<CustomerDetailsData>()
const isLoading = ref(false)
const accountDetails = ref<Account | undefined>(undefined)
const transactions = ref<TransactionDetails[] | undefined>(undefined)
const amount = ref(0)
const voucherNo = ref<number>()
const isWithdrawVisible = ref<boolean>(false)
const isDepositVisible = ref<boolean>(false)
const sbStatementColumns = [
  { field: 'voucherNo', header: 'Voucher Number', colStyle: { width: '15%' } },
  { field: 'createdAt', header: 'Date', colStyle: { width: '15%' } },
  { field: 'src', header: 'Description/Source', colStyle: { width: '20%' } },
  { field: 'openingBalance', header: 'Opening Balance(₹)', colStyle: { width: '20%' } },
  { field: 'deposit', header: 'Deposit(₹)', colStyle: { width: '20%' } },
  { field: 'withdraw', header: 'Withdraw(₹)', colStyle: { width: '20%' } },
  { field: 'closingBalance', header: 'Closing Balance(₹)', colStyle: { width: '25%' } }
]

const handelSearch = (searchResponse: string) => {
  searchQuery.value = searchResponse
  transactions.value = undefined
  handleAccountSearch()
}

function handleAccountSearch() {
  if (!searchQuery.value) {
    showToast('Invalid Account Number!', 'Please Enter account number', 'error')
    accountDetails.value = undefined
    return
  }
  getAccount(searchQuery.value)
}

function getAccount(accId: string) {
  if (!ValidationUtility.validateNumber(accId)) {
    showToast('Invalid Account Number!!', 'Please provide a valid account number!', 'error')
    return
  }
  isLoading.value = true

  const searchAccountRequest: SearchAccountRequest = {
    accId: accId
  }

  ApiService.searchAccount(searchAccountRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        accountDetails.value = response.data

        const searchCustomer: CustomerDetailsRequest = {
          cusId: String(accountDetails.value?.customerId)
        }
        ApiService.getCustomerDetails(searchCustomer)
          .then((response) => {
            setTimeout(() => {
              if (response.statusCode == 401) {
                Storage.clearData()
                router.push({
                  name: 'login'
                })
              }
            }, 2000)
            if (response.statusCode == 200) {
              customer.value = response.data
            }
          })
          .catch((error) => {
            showToast('Failed to Customer Details', error, 'error')
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        accountDetails.value = undefined
        showToast('Failed to fetch Account', response.error ?? 'no error response', 'error')
      }
    })
    .catch((error) => {
      accountDetails.value = undefined
      showToast('Failed to fetch Account', error, 'error')
    })
    .finally(() => {
      isDepositVisible.value = false
      isWithdrawVisible.value = false
      isLoading.value = false
    })
}

function handleAccountDeposit() {
  if (amount.value < 1) {
    showToast('Invalid deposit amount', 'Amount is less than Rs. 1', 'error')
    return
  }

  if (!voucherNo.value) {
    showToast('Invalid voucher number', 'Please enter voucher number', 'error')
    return
  }

  if (voucherNo.value.toString().length > 8) {
    showToast('Invalid voucher number', 'Voucher number should be less than 8 digits!', 'error')
    return
  }
  confirmDialog.require({
    message: `Are you sure you want to deposit ${ConversionUtility.toStringRupees(amount.value)}?`,
    header: 'Confirmation to deposit',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Deposit'
    },
    accept() {
      isLoading.value = true

      const depositedBy: AccountDepositBy = {
        empId: Storage.getEmpId()!!,
        createdAt: Date.now()
      }

      const accountDepositRequest: AccountDepositRequest = {
        accountId: accountDetails.value?.accountId!!,
        customerId: accountDetails.value?.customerId!!,
        depositAmount: ConversionUtility.toPaiseConversion(amount.value),
        createdAt: Date.now(),
        voucherNo: voucherNo.value!!,
        src: 'Deposit @ Bank Counter',
        depositedBy: depositedBy
      }

      ApiService.accountDeposit(accountDepositRequest)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast(
              `${ConversionUtility.toStringRupees(amount.value)} deposited Successfully`,
              'Success',
              'success'
            )
          } else {
            showToast(
              `Failed to deposit ${ConversionUtility.toStringRupees(amount.value)}`,
              response.error,
              'error'
            )
          }
        })
        .catch((error) => {
          showToast(
            `Failed to deposit ${ConversionUtility.toStringRupees(amount.value)}`,
            error,
            'error'
          )
        })
        .finally(() => {
          amount.value = 0
          voucherNo.value = undefined
          isDepositVisible.value = false
          getAccount(accountDetails.value?.accountId.toString()!!)
        })
    },
    reject() {}
  })
}

function handleAccountWithdrawal() {
  if (accountDetails.value?.balance!! < 1) {
    showToast('Cannot withdraw from savings account', 'balance is Rs. 0', 'error')
    return
  }
  if (amount.value < 1) {
    showToast('Invalid withdrawal amount', 'Amount is less than Rs. 1', 'error')
    return
  }
  if (amount.value > ConversionUtility.toRuppesCoversion(accountDetails.value?.balance!!)) {
    showToast('Invalid withdrawal amount', 'Amount is greater than account balance', 'error')
    return
  }
  if (!voucherNo.value) {
    showToast('Invalid voucher number', 'Please enter voucher number', 'error')
    return
  }

  if (voucherNo.value.toString().length > 8) {
    showToast('Invalid voucher number', 'Voucher number should be less than 8 digits!', 'error')
    return
  }
  confirmDialog.require({
    message: `Are you sure you want to withdraw ${ConversionUtility.toStringRupees(amount.value)}?`,
    header: 'Confirmation to withdraw',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Withdraw'
    },
    accept() {
      isLoading.value = true

      const withdrawBy: AccountWithdrawalBy = {
        empId: Storage.getEmpId()!!,
        createdAt: Date.now()
      }

      const accountWithdrawRequest: AccountWithdrawRequest = {
        accountId: accountDetails.value?.accountId!!,
        customerId: accountDetails.value?.customerId!!,
        withdrawalAmount: ConversionUtility.toPaiseConversion(amount.value),
        createdAt: Date.now(),
        voucherNo: voucherNo.value!!,
        src: 'Withdraw @ Bank Counter',
        withdrawalBy: withdrawBy
      }

      ApiService.accountWithdraw(accountWithdrawRequest)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast(
              `${ConversionUtility.toStringRupees(amount.value)} withdrew Successfully`,
              'Success',
              'success'
            )
          } else {
            showToast(
              `Failed to withdraw ${ConversionUtility.toStringRupees(amount.value)}`,
              response.error,
              'error'
            )
          }
        })
        .catch((error) => {
          showToast(
            `Failed to withdraw ${ConversionUtility.toStringRupees(amount.value)}`,
            error,
            'error'
          )
        })
        .finally(() => {
          amount.value = 0
          voucherNo.value = undefined
          isWithdrawVisible.value = false
          getAccount(accountDetails.value?.accountId.toString()!!)
        })
    },
    reject() {}
  })
}

function showSBStatement() {
  isLoading.value = true
  isDepositVisible.value = false
  isWithdrawVisible.value = false

  const sbStatementRequest: SBStatementRequest = {
    accId: accountDetails.value?.accountId.toString()!!,
    offset: undefined
  }

  ApiService.getSBStatement(sbStatementRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200 && response.data) {
        transactions.value = response.data
      } else {
        showToast(
          'Failed to fetch SB account statements',
          response.error ?? 'no error response',
          'error'
        )
      }
    })
    .catch((error) => {
      showToast('Failed to fetch SB account statements', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handleDataTableProps(index: number, data: any): string {
  switch (index) {
    case 0: {
      //Date --> createdAt
      return data.voucherNo ?? '-'
    }
    case 1: {
      //Date --> createdAt
      return ConversionUtility.toDateFormat(data.createdAt)
    }
    case 2: {
      // Description/Source --> src
      return data.src
    }
    case 3: {
      //Opening Balance --> openingBalance
      return ConversionUtility.toStringRupeesConversion(data.openingBalance)
    }
    case 4: {
      //Deposit --> deposit
      return ConversionUtility.toStringRupeesConversion(data.deposit)
    }
    case 5: {
      //Withdraw --> withdraw
      return ConversionUtility.toStringRupeesConversion(data.withdraw)
    }
    case 6: {
      //Closing Balance --> closingBalance
      return ConversionUtility.toStringRupeesConversion(data.closingBalance)
    }
    default: {
      return 'NA'
    }
  }
}

function showDeposit() {
  isLoading.value = false
  isDepositVisible.value = true
  isWithdrawVisible.value = false
  transactions.value = undefined
  voucherNo.value = undefined
  amount.value = 0
}

function showWithdraw() {
  isLoading.value = false
  isDepositVisible.value = false
  isWithdrawVisible.value = true
  transactions.value = undefined
  voucherNo.value = undefined
  amount.value = 0
}

function showWithdrawAll() {
  isLoading.value = false
  isDepositVisible.value = false
  isWithdrawVisible.value = true
  transactions.value = undefined
  amount.value = ConversionUtility.toRuppesCoversion(accountDetails.value?.balance!)
  voucherNo.value = undefined
}

const printPassbook = (
  transactions: TransactionDetails[],
  accountId: string,
  accountName: string
) => {
  const doc = new jsPDF()
  let y = 15 // Initial Y position

  // Bank Name at the Top
  doc.setFontSize(24)
  doc.text('Bhavsar Patini Sahakar Sanga(R),', 105, y, { align: 'center' })
  doc.setFontSize(18)
  doc.text('Badami, Tq:Badami, Dist:Bagalkot', 105, y + 10, { align: 'center' })
  y += 20

  doc.setFontSize(18)
  doc.text('Passbook Details', 14, y)
  y += 5

  if (transactions.length > 0) {
    const accountSummary: string[][] = [
      ['Account ID', accountId],
      ['Customer Name', accountName],
      [
        'Total Deposits',
        `${ConversionUtility.toRuppesCoversion(transactions.reduce((sum, t) => sum + t.deposit, 0))}`
      ],
      [
        'Total Withdrawals',
        `${ConversionUtility.toRuppesCoversion(transactions.reduce((sum, t) => sum + t.withdraw, 0))}`
      ]
    ]

    autoTable(doc, {
      startY: y,
      body: accountSummary,
      theme: 'grid',
      styles: { fontSize: 10 }
    })

    y += accountSummary.length * 15
  }

  doc.setFontSize(18)
  doc.text('Transaction Details', 14, y)
  y += 5

  if (transactions.length > 0) {
    autoTable(doc, {
      startY: y,
      head: [['Date', 'Deposit', 'Withdraw', 'Closing Balance', 'Source']],
      body: transactions.map((t) => [
        ConversionUtility.toDateFormat(t.createdAt),
        t.deposit > 0 ? `${ConversionUtility.toRuppesCoversion(t.deposit)}` : '--',
        t.withdraw > 0 ? `${ConversionUtility.toRuppesCoversion(t.withdraw)}` : '--',
        `${ConversionUtility.toRuppesCoversion(t.closingBalance)}`,
        t.src || '--'
      ]),
      styles: { fontSize: 10 }
    })

    y = (doc as any).lastAutoTable.finalY + 15
  } else {
    doc.setFontSize(8)
    doc.text('No transactions found for this account.', 14, y)
    y += 10
  }

  doc.autoPrint()
  window.open(doc.output('bloburl'), '_blank')
  doc.save(`PASSBOOK_${accountId}.pdf`)
}

function allPigmyAccountsClosed(pigmyAccount: any): boolean {
  const pigmyAccounts = pigmyAccount

  for (let i = 0; i < pigmyAccounts.length; i++) {
    if (pigmyAccounts[i].isClosed !== true) {
      return false
    }
  }
  return true
}

console.log()

function validateSBDetails() {
  if (accountDetails.value?.balance!! != 0) {
    showToast('Account Cannot be closed', 'Account balance must be 0', 'error')
    return
  }
  if (!allPigmyAccountsClosed) {
    showToast(
      'Account Cannot be closed',
      'There are pigmy accounts not closed with this customr',
      'error'
    )
    return
  }

  isLoading.value = true

  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }

  const CloseSBDetails: CloseAccountRequest = {
    customerId: accountDetails.value?.customerId!,
    sbAccountId: accountDetails.value?.accountId!,
    requestedAt: Date.now(),
    requestBy: createBy
  }

  ApiService.closeSB(CloseSBDetails)
    .then((response) => {
      if (response.statusCode == 200) {
        showToast('Account Closed Successfully', '', 'success')
        handelSearch(accountDetails.value?.customerId.toString()!)
      } else {
        showToast('Unable to close', response.data, 'error')
      }
    })
    .catch((error) => {
      showToast('Something is wrong', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <Toast class="error-p-toast" />

        <SearchComponent
          title="Search Accounts"
          subtitle="Search for accounts using Account ID (Eg. - 10000001)."
          placeholder="Search Account"
          @search-click="handelSearch"
        />

        <div v-if="!accountDetails && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No accounts found for the provided search query!" style="font-weight: 600" />
        </div>
        <div class="cont" v-if="accountDetails">
          <div class="holder-details">
            <div>
              <span class="text-heading">Acc Id: </span>
              <span class="text">{{ accountDetails?.accountId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Cus Id: </span>
              <span class="text">{{ accountDetails?.customerId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Name: </span>
              <span class="text"
                >{{ customer?.customer?.firstName }} {{ customer?.customer?.middleName ?? '' }}
                {{ customer?.customer?.lastName }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Type: </span>
              <span class="text">{{ accountDetails?.accountType.toLocaleUpperCase() }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Balance: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(accountDetails?.balance!!) }}<br
              /></span>
            </div>
            <div v-if="accountDetails.isClosed">
              <span class="text-heading">Status: </span>
              <span class="text"><Tag severity="danger">Closed</Tag><br /></span>
            </div>
            <div v-else>
              <span class="text-heading">Status: </span>
              <span class="text"><Tag severity="success">Active</Tag><br /></span>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <CustomButton icon="book" title="View SB Statement" @click="showSBStatement()" />
              <CustomButton
                icon="add"
                title="Deposit"
                @click="showDeposit()"
                :disabled="accountDetails.isClosed"
                v-if="
                  !(
                    Storage.getEmpAccessRole() == 'Operator_L3' ||
                    Storage.getEmpAccessRole() == null
                  )
                "
              />
              <CustomButton
                icon="minus"
                title="Withdraw"
                @click="showWithdraw()"
                :disabled="accountDetails.isClosed"
                v-if="
                  !(
                    Storage.getEmpAccessRole() == 'Operator_L3' ||
                    Storage.getEmpAccessRole() == null
                  )
                "
              />
              <CustomButton
                icon="money"
                title="Withdraw all"
                @click="showWithdrawAll()"
                :disabled="accountDetails.isClosed"
                v-if="
                  !(
                    Storage.getEmpAccessRole() == 'Operator_L3' ||
                    Storage.getEmpAccessRole() == null
                  )
                "
              />
            </div>
            <CustomButton
              icon="ban"
              severity="danger"
              title="Close Account"
              @click="validateSBDetails"
              :disabled="accountDetails.isClosed"
              v-if="
                !(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == null)
              "
            />
          </div>
        </div>
        <div
          class="view-statement-container"
          v-if="transactions && transactions.length != 0 && !isLoading"
        >
          <DataTable :value="transactions" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
            <Column
              v-for="(col, index) in sbStatementColumns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              :style="col.colStyle"
            >
              <template #body="slotProps">
                {{ handleDataTableProps(index, slotProps.data) }}
              </template>
            </Column>
            <template #footer>
              <CustomButton
                icon="book"
                severity="success"
                title="Print Passbook"
                @click="
                  printPassbook(
                    transactions,
                    accountDetails?.accountId.toString()!,
                    `${customer?.customer?.firstName} ${customer?.customer?.middleName??``} ${customer?.customer?.lastName}`
                  )
                "
                :disabled="transactions.length == 0"
                v-if="
                  !(
                    Storage.getEmpAccessRole() == 'Operator_L3' ||
                    Storage.getEmpAccessRole() == null
                  )
                "
              />
            </template>
          </DataTable>
        </div>
        <div class="horizontal-alignment-with-border" v-if="isDepositVisible">
          <h3 class="text-2xl font-semibold">Deposit</h3>
          <InputNumber
            class="amount-input"
            v-model="amount"
            locale="en-IN"
            :minFractionDigits="0"
            fluid
            :min="0"
          />
          <InputNumber
            class="amount-input"
            v-model="voucherNo"
            :useGrouping="false"
            placeholder="Voucher Number"
            :min="1"
          />
          <CustomButton
            class="button"
            title="Deposit"
            icon="indian-rupee"
            @click="handleAccountDeposit()"
          />
          <CustomButton
            class="button"
            title="Close"
            icon="times"
            :outlined="true"
            @click="isDepositVisible = false"
          />
        </div>
        <div class="horizontal-alignment-with-border" v-if="isWithdrawVisible">
          <h3 class="text-2xl font-semibold">Withdraw</h3>
          <InputNumber
            class="amount-input"
            v-model="amount"
            locale="en-IN"
            :minFractionDigits="0"
            fluid
          />
          <InputNumber
            class="amount-input"
            :useGrouping="false"
            v-model="voucherNo"
            placeholder="Voucher Number"
            :min="1"
          />
          <CustomButton
            class="button"
            title="Withdraw"
            icon="indian-rupee"
            @click="handleAccountWithdrawal()"
          />
          <CustomButton
            class="button"
            title="Close"
            icon="times"
            :outlined="true"
            @click="isWithdrawVisible = false"
          />
        </div>
        <LoadingComponent
          text="Please wait while we process the request!"
          :dialogVisiblity="isLoading"
        />
        <ConfirmDialog :draggable="false" :closable="false" />
      </template>
    </Card>
  </main>
</template>

<style scoped>
.horizontal-alignment-with-border {
  margin: 16px 0;
  background-color: white;
  padding: 1.5rem 1.5rem;
  border-radius: 18px;
  border: solid rgba(0, 0, 0, 0.4) 1px;
  display: flex;
  gap: 16px;
  align-items: center;

  .amount-input {
    height: 40px;
    width: 40%;
  }

  .button {
    height: 40px;
  }
}
</style>
