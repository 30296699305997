<script setup lang="ts">
import Card from 'primevue/card';
import CustomButton from '@/components/CustomComponent/CustomButton.vue';
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import ApiService from '@/api/ApiService';
import type { ReportRequestByNxtKey } from '@/api/models/reports/ReportRequestByNxtKey';
import type { ToastMessageOptions } from 'primevue/toast';
import ConversionUtility from '@/utils/ConversionUtility';
import * as XLSX from 'xlsx'
import ProgressBar from 'primevue/progressbar';
import type { LoanReportDetail } from '@/api/models/reports/LoanReportDetails';

const loadingProgress = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const nextPageKey = ref(null)
const loanReportDetail = ref<LoanReportDetail[]>([])

function getLoanReportDetail(nextPageKey:any){
    isLoading.value = true
    const requestParam: ReportRequestByNxtKey ={
        nextPageKey: nextPageKey
    }

    ApiService.loanReport(requestParam)
    .then((response) => {
        if(response.statusCode == 200){
        loanReportDetail.value.push(...response.data.loanReportDetails)
          if (response.data.nextPageKey != null){           
            getLoanReportDetail(response.data.nextPageKey)
          }
          if(response.data.nextPageKey == null){
            downloadReport()
          }
        }
        else{
            showToast('Error','No FD Detail', 'error')
        }
    })
    .catch((error) => {
        showToast('Errorerr', error, 'error')
    })
    .finally()
}
function downloadReport(){
  loadingProgress.value = 25;
    isLoading.value = true
    const reportDataDownload = [
  ["Loan accounts Details Report"],
  ["Total Loan Accounts", loanReportDetail.value.length], 
  [],
  ["Loan ID", "Customer ID", 'Application ID', 'Type', "Loan Amount", "ROI", "Start Date", "End Date", "Interest Amount", "Outstanding Amount", "Remaining Principle Amt", "Remaining Interest Amt", "EMI Amt", "Total Paid", "Status"], 
];

loanReportDetail.value?.forEach(account => {
  reportDataDownload.push([
    account.loanId, 
    account.customerId, 
    account.applicationId,
    account.loanType,
    ConversionUtility.toRuppesCoversion(account.loanAmount),
    account.interestRate+'%',
    ConversionUtility.toDateFormat(account.loanStartDate),
    ConversionUtility.toDateFormat(account.loanEndDate),
    ConversionUtility.toRuppesCoversion(account.interestAmount),
    ConversionUtility.toRuppesCoversion(account.outstandingAmount),
    ConversionUtility.toRuppesCoversion(account.remainingPrincipalAmount),
    ConversionUtility.toRuppesCoversion(account.remainingInterestAmount),
    ConversionUtility.toRuppesCoversion(account.emiAmount),
    ConversionUtility.toRuppesCoversion(account.totalPaid),
    account.loanStatus
]
  )
});

const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload);
worksheet['!cols'] = [
  { wch: 10 },
  { wch: 10 },
  { wch: 15 },
  { wch: 15 },
  { wch: 20 },
  { wch: 10 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' });

const url = window.URL.createObjectURL(blob);
const link = document.createElement('a');
link.href = url;
link.setAttribute('download', `Loan_Report as on${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`);
document.body.appendChild(link);
link.click();
isLoading.value= false
loanReportDetail.value = []
showToast('Success', 'Report has been downloaded', 'success')
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
    <Toast class="error-p-toast" />
    <main class="main">
        <Card class="search-card">
            <template #title>
                <h2 class="title-right text-3xl font-bold">Loan Account Report</h2>
            </template>
            <template #content>
                <div v-if="loanReportDetail" style="display: flex; justify-content: flex-start; margin-top: 20px;gap: 1rem">
                    <CustomButton title="Download Report" @click="getLoanReportDetail(nextPageKey)"/>
                </div>

                <Dialog
              :visible="isLoading"
              modal
              :draggable="false"
              :closable="false"
              style="width: 60vw"
              header="Downloading....."
            >
            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
            </Dialog>
            </template>
        </Card>
    </main>
</template>
<style>
</style>