<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue';
import Card from 'primevue/card';
import CustomButton from '@/components/CustomComponent/CustomButton.vue';
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import ApiService from '@/api/ApiService';
import type { ToastMessageOptions } from 'primevue/toast';
import ConversionUtility from '@/utils/ConversionUtility';
import * as XLSX from 'xlsx'
import ProgressBar from 'primevue/progressbar';
import type { RdReportDetail } from '@/api/models/reports/RDReportDetails';

const loadingProgress = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const rdReportDet = ref<RdReportDetail[]>([])

function getRDReportDetail(){
    isLoading.value = true
    ApiService.RDReport()
    .then((response) => {
        if(response.statusCode == 200){
          rdReportDet.value = response.data.rdReportDetails
          downloadReport()
        }
        else{
            showToast('Error','No RD Detail', 'error')
        }
    })
    .catch((error) => {
        showToast('Error', error, 'error')
    })
    .finally()
}
function downloadReport(){
  loadingProgress.value = 25;
    isLoading.value = true
    const reportDataDownload = [
      ["Bhavasar Patini Sahakar Sanga", "", ""],
      ["Tq: Badami, Dis: Bagalkot", "", ""],
      [],
  ["Recurring Deposit Details Report"],
  ["Total RD Accounts", rdReportDet.value.length],
  [],
  ["RD ID", "Customer ID","Name", "Account ID","Nominee ID", "Nominee Name", "Nominee Contact", "Total Deposit", "Rate of Interest", "Deposit Amount", "Tenure", "Interest Amt", "Maturity Date", "Nominee Customer ID" , "Created On"],
];

rdReportDet.value.forEach(account => {
  reportDataDownload.push([
    account.rdId,
    account.customerId,
    account.customerName,
    account.accountId,
    account.nomineeCustomerId,
    account.nomineeName,
    account.nomineePhoneNumber,
    ConversionUtility.toRuppesCoversion(account.totalDeposit),
    account.rateOfInterest + '%',
    ConversionUtility.toRuppesCoversion(account.balance),
    `${account.tenureInMonths / 12} ${account.tenureInMonths / 12 === 1 ? 'Year' : 'Years'}`,
    ConversionUtility.toRuppesCoversion(account.interestAmount),
    ConversionUtility.toDateFormat(account.maturityAt),
    account.nomineeCustomerId,
    ConversionUtility.toDateFormat(account.createdAt),
  ]);
});

const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload);

// Merge cells to center the bank name and location
  worksheet["!merges"] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // Merge A1:C1 for Bank Name
    { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } } // Merge A2:C2 for Location
  ]

worksheet['!cols'] = [
  { wch: 10 },
  { wch: 10 },
  { wch: 20 },
  { wch: 15 },
  { wch: 20 },
  { wch: 25 },
  { wch: 15 },
  { wch: 25 },
  { wch: 10 }
];
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' });

const url = window.URL.createObjectURL(blob);
const link = document.createElement('a');
link.href = url;
link.setAttribute('download', `RD_Detail_Report as on${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`);
document.body.appendChild(link);
link.click();
isLoading.value= false
rdReportDet.value = []
showToast('Success', 'Report has been downloaded', 'success')
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
    <Toast class="error-p-toast" />
    <main class="main">
        <Card class="search-card">
            <template #title>
                <h2 class="title-right text-3xl font-bold">Recurring Deposit Report</h2>
            </template>
            <template #content>
                <div v-if="rdReportDet" style="display: flex; justify-content: flex-start; margin-top: 20px;gap: 1rem">
                    <CustomButton title="Download Report" @click="getRDReportDetail"/>
                </div>

                <Dialog
              :visible="isLoading"
              modal
              :draggable="false"
              :closable="false"
              style="width: 60vw"
              header="Downloading....."
            >
            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
            </Dialog>
            </template>
        </Card>
    </main>
</template>
<style>
</style>