<script setup lang="ts">
import ApiService from '@/api/ApiService'
import type BalancesheetData from '@/api/models/reports/BalancesheetData'
import type BalancesheetRequest from '@/api/models/reports/BalancesheetRequest'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import ConversionUtility from '@/utils/ConversionUtility'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { computed, ref } from 'vue'
import NotFound from '@/assets/not_found.svg'

const isLoading = ref<boolean>(false)
const toast = useToast()
const selectedMonth = ref<Date>()
const balanceSheet = ref()
const currentYear = new Date().getFullYear();
const selectedYear = ref<number>();

function getBalanceSheet() {
  if (!selectedYear.value) {
    showToast('Financial Year cannot be empty', 'Select Financial Year', 'error')
    return
  }

  isLoading.value = true

  const request: BalancesheetRequest = {
    startDate: new Date(selectedYear.value, 3, 1,0,0,0).getTime().toString(),
    endDate: new Date(selectedYear.value + 1, 2, 31, 23, 59, 59, 999).getTime().toString()
  }

  ApiService.getBalancesheet(request)
    .then((response) => {
      if(response.statusCode === 200) {
        balanceSheet.value = response.data
      }
      else {
        balanceSheet.value = ''
      }

      console.log(response)
      isLoading.value = false
    })
    .catch((error) => {
      isLoading.value = false
      showToast('Error', error.response.data.message, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const bankName = 'Bhavsar Patini Sahakar Sanga(R), Badami'
const reportDate = new Date().toLocaleDateString()
const branch = 'Badami'
const tq = 'Badami'
const dist = 'Bagalkote'

const downloadPDF = () => {
  const doc = new jsPDF();

  // Bank Name (Centered)
  doc.setFontSize(18);
  doc.text(bankName, 105, 15, { align: "center" });

  // Branch & Date Details
  doc.setFontSize(12);
  doc.text(`Branch: ${branch} | Tq: ${tq} | Dist: ${dist}`, 105, 23, { align: "center" });

  // Balance Sheet Title
  doc.setFontSize(14);
  doc.text(`Balance Sheet for FY ${selectedYear.value!} - ${(Number(selectedYear.value!)+1)}`, 105, 35, { align: "center" });

  // Function to format table data
  const formatTableData = (data: Record<string, number>) => {
    const formattedData = Object.entries(data).map(([key, value]) => [
      key.replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())
        .trim(),
      ConversionUtility.toRuppesCoversion(value)
    ]);

    // Calculate total
    const total = Object.values(data).reduce((sum, value) => sum + value, 0);

    // Append Total row
    formattedData.push([
      "Total", // This will be bold
      ConversionUtility.toRuppesCoversion(total)
    ]);

    return { formattedData, total };
  };

  let startY = 55; // Initial Y position
  let grandTotal = 0;

  // Assets Table
  const { formattedData: assetsData, total: assetsTotal } = formatTableData(balanceSheet.value.assets);
  autoTable(doc, {
    startY,
    head: [["Assets", "Amount"]],
    body: assetsData,
    styles: { fontSize: 10 },
    didDrawCell: ({ row, column, cell }) => {
      if (row.index === assetsData.length - 1) {
        cell.styles.fontStyle = "bold"; // Make the "Total" row bold
      }
    }
  });

  startY += 10 + assetsData.length * 8; // Adjust Y position
  grandTotal += assetsTotal;

  // Liabilities Table
  const { formattedData: liabilitiesData, total: liabilitiesTotal } = formatTableData(balanceSheet.value.liabilities);
  autoTable(doc, {
    startY,
    head: [["Liabilities", "Amount"]],
    body: liabilitiesData,
    styles: { fontSize: 10 },
    didDrawCell: ({ row, column, cell }) => {
      if (row.index === liabilitiesData.length - 1) {
        cell.styles.fontStyle = "bold"; // Make the "Total" row bold
      }
    }
  });

  startY += 10 + liabilitiesData.length * 8;
  grandTotal += liabilitiesTotal;

  // Shareholder Equity Table
  const { formattedData: equityData, total: equityTotal } = formatTableData(balanceSheet.value.shareHolderEquity);
  autoTable(doc, {
    startY,
    head: [["Shareholder Equity", "Amount"]],
    body: equityData,
    styles: { fontSize: 10 },
    didDrawCell: ({ row, column, cell }) => {
      if (row.index === equityData.length - 1) {
        cell.styles.fontStyle = "bold"; // Make the "Total" row bold
      }
    }
  });

  startY += 10 + equityData.length * 8;
  grandTotal += equityTotal;
  // Save PDF
  doc.save("Balance_Sheet.pdf");
};
const formatTableData = (data: Record<string, number>) => {
  return Object.entries(data).map(([key, value]) => ({
    type: key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim(),
    amount: ConversionUtility.toStringRupeesConversion(value)
  }))
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}

const financialYearsss = computed(() => {
  const years = [];
  for (let i = currentYear - 1; i <= currentYear -1; i++) {
    years.push({
      label: `${i}-${(i + 1).toString().slice(-2)}`,
      value: i
    });
  }
  return years;
});
const financialYears = [
  {label: '2024-25', value: '2024'},
]
</script>
<template>
  <Toast class="error-p-toast" />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Balancesheet</h2>
      </template>
      <template #content>
        <Divider />
        <div
          style="margin: 30px; display: grid; grid-template-columns: repeat(4, 2fr); gap: 3.5rem"
        >
          <FloatLabel>

            <Select
              v-model="selectedYear"
              id="selectedDate"
              class="input-text"
              :options="financialYears"
              optionLabel="label"
              optionValue="value"
            />
            <label for="selectedDate" class="mandatory">Select Financial Year</label>
          </FloatLabel>
          <CustomButton title="get" @click="getBalanceSheet()" class="cstmbtn" />
        </div>
        <div v-if="balanceSheet" class="p-6">
          <div class="text-center mb-6">
            <h2 class="text-2xl font-bold">{{ bankName }}</h2>
            <p class="text-sm">Branch: {{ branch }} | Tq: {{ tq }} | Dist: {{ dist }}</p>
            <p class="text-sm"></p>
            <h2 class="text-xl font-bold">
              Balance Sheet for FY {{ selectedYear }} - {{ (Number(selectedYear!)+1) }}
            </h2>
          </div>
          <div class="grid grid-cols-1 justify-center gap-6">
            <!-- Assets Card -->
            <Card style="width: 75%; margin: auto">
              <template #content>
                <DataTable :value="formatTableData(balanceSheet.assets)" striped-rows size="small">
                  <Column field="type" header="Assets" style="width: 60%"></Column>
                  <Column field="amount" header="Amount" style="width: 50%">
                    <template #body="slotProps">
                      {{ slotProps.data.amount.toLocaleString() }}
                    </template>
                  </Column>
                </DataTable>
              </template>
            </Card>

            <!-- Liabilities Card -->
            <Card style="width: 75%; margin: auto">
              <template #content>
                <DataTable :value="formatTableData(balanceSheet.liabilities)" striped-rows size="small">
                  <Column field="type" header="Liabilities" style="width: 60%"></Column>
                  <Column field="amount" header="Amount" style="width: 50%">
                    <template #body="slotProps">
                      {{ slotProps.data.amount.toLocaleString() }}
                    </template>
                  </Column>
                </DataTable>
              </template>
            </Card>

            <!-- Shareholder Equity Card -->
            <Card style="width: 75%; margin: auto">
              <template #content>
                <DataTable :value="formatTableData(balanceSheet.shareHolderEquity)" striped-rows size="small">
                  <Column field="type" header="Shareholder Equity" style="width: 60%"></Column>
                  <Column field="amount" header="Amount" style="width: 50%">
                    <template #body="slotProps">
                      {{ slotProps.data.amount.toLocaleString() }}
                    </template>
                  </Column>
                </DataTable>

              </template>
            </Card>
            <div class="flex justify-end mb-4">
              <CustomButton title="Download PDF" icon="download" @click="downloadPDF" />
            </div>
          </div>

        </div>
        <div v-if="!balanceSheet && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No accounts found for the provided search query!" style="font-weight: 600" />
        </div>
      </template>
    </Card>
  </main>
  <div v-if="isLoading">
    <LoadingComponent
      text="Please wait while we process the request!"
      :dialogVisiblity="isLoading"
    />
  </div>
  <ConfirmDialog :draggable="false" :closable="false" />
</template>
<style>
.main {
  padding-bottom: 80px;
}

.form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 2.5rem;
}

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10%;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-header {
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Darker text color */
  font-weight: bold;
}

.input-text {
  height: 45px;
}

.dropdown-select .p-dropdown-item {
  color: rgb(50, 95, 186);
}

.dropdown-overlay {
  --p-select-overlay-color: rgb(50, 95, 186);
}

.dropdown-select {
  width: 100%;
  height: 45px;
  font-family: Epilogue;
}
</style>