<script setup lang="ts">
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import SearchComponent from '@/components/SearchComponent.vue'
import { ref } from 'vue'
import NotFound from '@/assets/not_found.svg'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { SearchEmployeeRequest } from '@/api/models/operations/employees/SearchEmployeeRequest'
import type { Employee } from '@/api/models/operations/employees/Employee'
import ConversionUtility from '@/utils/ConversionUtility'
import ValidationUtility from '@/utils/ValidationUtility'
import type { CreateEmployeeRequest } from '@/api/models/operations/employees/CreateEmployeeRequest'
import { useConfirm } from 'primevue/useconfirm'
import type { Branch } from '@/api/models/operations/branches/Branch'
import type { BranchCode } from '@/api/models/employee/BranchCode'
import type { AccessRoles } from '@/models/AccessRoles'
import type CloseEmpRequest from '@/api/models/operations/employees/CloseEmpRequest'
import type Salary from '@/api/models/operations/employees/Salary'
import type CreditSalaryRequest from '@/api/models/employee/CreditSalaryRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'

const toast = useToast()
const confirmDialog = useConfirm()
const addEmployeeVisible = ref(false)
const employee = ref<Employee>()
const isLoading = ref(false)
const searchQuery = ref('')
const firstName = ref('')
const lastName = ref('')
const phoneNumber = ref('')
const userName = ref('')
const password = ref('')
const branchCode = ref<BranchCode>()
const validBranches = ref<BranchCode[]>([])
const passwordVisible = ref(false)
const userRoles = ref<AccessRoles[]>([
  { name: 'Operator L1' },
  { name: 'Operator L2' },
  { name: 'Operator L3' }
])
const maxDate = ref<Date>(new Date(new Date().setMonth(new Date().getMonth() + 1)))
const addSalaryVisible = ref(false)
const salaryAmount = ref<number>()
const salaryMonth = ref<Date>()
const pensionAmount = ref<number>()
const salaryRecords = ref<Salary[]>([])

const selectedUserRole = ref<AccessRoles>()

function handelSearchEmployee(empID: string) {
  if (!ValidationUtility.validateNumber(empID)) {
    employee.value = undefined
    showToast('Invalid Employee ID!!', 'Please provide a valid employee ID!', 'error')
    return
  }
  if (empID.length < 4) {
    employee.value = undefined
    showToast('Invalid Employee ID!!', 'ID needs to be four digits!', 'error')
    return
  }
  isLoading.value = true
  searchQuery.value = empID
  employee.value = undefined
  if (!searchQuery.value) {
    showToast('Please provide a valid employee Id!', 'Validation error', 'error')
    isLoading.value = false
    return
  }
  if (!isLoading.value) isLoading.value = true

  const searchEmployeeRequest: SearchEmployeeRequest = {
    empId: empID
  }

  ApiService.searchEmployee(searchEmployeeRequest)
    .then((response) => {
      if (response.statusCode == 200) {
        employee.value = response.data
        console.log(employee.value)
      } else {
        showToast('Failed to fetch Employee', response.error ?? 'no error response', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Employee', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelSearchBranch() {
  isLoading.value = true
  ApiService.searchBranch()
    .then((response) => {
      if (response.statusCode == 200) {
        validBranches.value = response.data.map((branch: Branch) => {
          addEmployeeVisible.value = true
          return {
            name: branch.branchCode,
            code: branch.branchId
          }
        })
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Branch', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handleAddClick() {
  firstName.value = ''
  lastName.value = ''
  phoneNumber.value = ''
  userName.value = ''
  password.value = ''
  branchCode.value = undefined
  selectedUserRole.value = undefined
  isLoading.value = true
  handelSearchBranch()
}

function handleAddEmployee() {
  if (!ValidationUtility.validateString(firstName.value)) {
    showToast('First name is blank/empty', 'Validation failed!', 'error')
    return
  }

  if (firstName.value.toString().length < 3) {
    showToast('Unable to Proceed', 'First Name must be min 3 characters', 'error')
    return
  }

  if (!ValidationUtility.validateString(lastName.value)) {
    showToast('Last name is blank/empty', 'Validation failed!', 'error')
    return
  }

  if (lastName.value.toString().length < 3) {
    showToast('Unable to Proceed', 'Last Name must be min 3 characters', 'error')
    return
  }

  if (password.value.includes('admin')) {
    showToast('Unable to Proceed', 'Password cannot contain admin', 'error')
    return
  }
  if (!phoneNumber.value) {
    showToast('Phone number is blank/empty', 'Validation failed!', 'error')
    return
  }
  if (
    Number(phoneNumber.value[0]) != 6 &&
    Number(phoneNumber.value[0]) != 7 &&
    Number(phoneNumber.value[0]) != 8 &&
    Number(phoneNumber.value[0]) != 9
  ) {
    showToast('Invalid Phone Number', 'Phone Number must start with (6, 7, 8, 9)', 'error')
    return
  }

  if (!branchCode.value) {
    showToast('Branch is blank/empty', 'Validation failed!', 'error')
    return
  }

  if (!userRoles.value[0].name) {
    showToast('Role is blank/empty', 'Validation failed!', 'error')
    return
  }

  if (!userName.value) {
    showToast('User name is blank', 'Please enter username', 'error')
    return
  }

  if (userName.value.toString().length < 6) {
    showToast('Unable to Proceed', 'Username must be 6 characters', 'error')
    return
  }

  if (userName.value.includes('admin')) {
    showToast('Unable to Proceed', 'Username cannot contain admin', 'error')
    return
  }

  if (!password.value) {
    showToast('Password is blank/empty', 'Validation failed!', 'error')
    return
  }

  if (password.value.length < 6) {
    showToast('Password is Weak', 'Password Must 6 character long', 'error')
    return
  }

  if (!selectedUserRole.value) {
    showToast('Userrole cannot be blank', 'Select Role of user', 'error')
    return
  }
  isLoading.value = true

  const addEmployeeRequest: CreateEmployeeRequest = {
    userName: userName.value,
    password: password.value,
    firstName: firstName.value,
    lastName: lastName.value,
    phoneNumber: phoneNumber.value,
    isActive: true,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    branch: branchCode.value.code,
    branchCode: branchCode.value.name,
    accessRole: `Operator_${selectedUserRole.value?.name!!.split(' ')[1]}`
  }

  ApiService.createEmployee(addEmployeeRequest)
    .then((response) => {
      if (response.statusCode == 200) {
        showToast(
          'Employee Created Successfully',
          'Employee with ID ' + response.data.empId + ' created',
          'success'
        )
        addEmployeeVisible.value = false
      } else {
        showToast('Failed to create Employee', response.error, 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to create Employee', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function closeEmployeeAccount() {
  confirmDialog.require({
    message: `Are you sure you want to close this Employee account?`,
    header: 'Confirmation to close',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, close Employee Account'
    },
    accept() {
      isLoading.value = true
      const request: CloseEmpRequest = {
        empId: employee.value?.empId!,
        createdAt: new Date().getTime()
      }
      ApiService.closeEmp(request)
        .then((response) => {
          //  setTimeout(() => {
          //    if (response.statusCode == 401) {
          //      Storage.clearData()
          //      router.push({
          //        name: 'login'
          //      })
          //    }
          //  }, 2000)
          if (response.statusCode == 200) {
            showToast('Successfully', response.data!, 'success')
            addEmployeeVisible.value = false
          } else {
            showToast('Failed to create Employee', response.error!, 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to create Employee', error, 'error')
        })
        .finally(() => {
          isLoading.value = false
          handelSearchEmployee(employee.value?.empId.toString()!)
        })
    },
    reject() {}
  })
}

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value
}

function creditSalary() {
  if (!salaryMonth.value) {
    showToast('Salary Month is blank/empty', 'Validation failed!', 'error')
    return
  }
  if (!salaryAmount.value) {
    showToast('Salary Amount is blank/empty', 'Validation failed!', 'error')
    return
  }
  if (!salaryMonth.value) {
    showToast('Salary Month is blank/empty', 'Validation failed!', 'error')
    return
  }
  if (!pensionAmount.value) {
    showToast('PF Amount is blank/empty', 'Validation failed!', 'error')
    return
  }
  if (ConversionUtility.toPaiseConversion(pensionAmount.value) > ConversionUtility.toPaiseConversion(salaryAmount.value)) {
    showToast('Error', 'PF Amount cannot be more than salary amount!', 'error')
    return
  }
  isLoading.value = true
  const createdBy: CreatedBy = {
    empId: Storage.getEmpId()!,
    createdAt: new Date().getTime()
  }
  const salary: Salary = {
    salaryAmount: ConversionUtility.toPaiseConversion(salaryAmount.value),
    pensionAmount: ConversionUtility.toPaiseConversion(pensionAmount.value),
    salaryMonth: new Date(salaryMonth.value).getTime(),
    createdBy: createdBy
  }
  const request: CreditSalaryRequest = {
    empId: employee.value?.empId!,
    salaryRecord: salary
  }
  ApiService.creditSalary(request)
    .then((response) => {
      if (response.statusCode == 200) {
        showToast('Salary Credited Successfully', '', 'success')
        addSalaryVisible.value = false
        salaryAmount.value = undefined
        salaryMonth.value = undefined
        pensionAmount.value = undefined
        handelSearchEmployee(employee.value?.empId.toString()!)
      } else {
        showToast('Failed to credit Salary', response.error!, 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to credit Salary', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <Toast class="error-p-toast" />
  <main class="main" v-bind="$attrs">
    <Card class="search-card">
      <template #content>
        <SearchComponent
          title="Search Employee"
          subtitle="Search for employee using Employee ID. (Eg. - 1001)."
          placeholder="Search Employee"
          @search-click="handelSearchEmployee"
        />
        <div v-if="employee == undefined && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No Employee found for the provided search query!" style="font-weight: 600" />
        </div>
        <div class="cont" v-if="employee != undefined && !isLoading">
          <div class="holder-details">
            <div>
              <span class="text-heading">Emp Id: </span>
              <span class="text">{{ employee.empId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Name: </span>
              <span class="text">{{ employee.firstName }} {{ employee.lastName }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Username: </span>
              <span class="text">{{ employee.userName }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Phone Number: </span>
              <span class="text">{{ employee.phoneNumber }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Started on: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(employee.createdAt) }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Branch: </span>
              <span class="text">{{ employee.branch }}<br /></span>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <CustomButton
                icon="bank"
                title="Credit Salary"
                @click="addSalaryVisible = !addSalaryVisible"
                :disabled="!employee.isActive!"
              />
            </div>
            <Tag v-if="!employee.isActive" severity="danger">This Employee is not Active.</Tag>
            <CustomButton
              icon="ban"
              severity="danger"
              title="Close Employee Account"
              @click="closeEmployeeAccount"
              :disabled="!employee.isActive!"
            />
          </div>

          <DataTable
            v-if="employee.salaryRecord"
            :value="
              employee.salaryRecord.sort((a, b) => b.createdBy.createdAt - a.createdBy.createdAt)
            "
            paginator
            :rows="10"
            :rowsPerPageOptions="[10, 20, 50]"
          >
            <Column header="Salary Month">
              <template #body="slotProps">
                {{ new Date(slotProps.data.salaryMonth).getMonth() + 1 }}/{{
                  new Date(slotProps.data.salaryMonth).getFullYear()
                }}
              </template>
            </Column>
            <Column header="Salary Amount">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.salaryAmount) }}
              </template>
            </Column>
            <Column header="PF Amount">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.pensionAmount) }}
              </template>
            </Column>
            <Column header="Credited On">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(slotProps.data.createdBy.createdAt) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </Card>
  </main>
  <Dialog
    v-model:visible="addEmployeeVisible"
    :draggable="false"
    modal
    :style="{ width: '60rem' }"
    :closable="false"
  >
    <span class="dialog-header">Add Employee</span>
    <Divider />
    <div class="form">
      <Fieldset legend="Personal Details">
        <div class="fieldset">
          <FloatLabel>
            <InputText class="input-text" id="firstName" v-model="firstName"></InputText>
            <label for="firstName" class="mandatory">First Name</label>
          </FloatLabel>
          <FloatLabel>
            <InputText class="input-text" id="lastName" v-model="lastName"></InputText>
            <label for="lastName" class="mandatory">Last Name</label>
          </FloatLabel>
          <FloatLabel>
            <InputMask
              id="phoneNumber"
              v-model="phoneNumber"
              class="input-text"
              size="large"
              mask="9999999999?"
              placeholder="9999999999?"
            />
            <label for="phoneNumber" class="mandatory">Phone Number</label>
          </FloatLabel>
        </div>
      </Fieldset>
      <Fieldset legend="Employee Details">
        <div class="fieldset">
          <FloatLabel>
            <Select
              v-model="branchCode"
              inputId="branchCode"
              :options="validBranches"
              optionLabel="name"
              class="branch-select"
              overlayClass="branch-overlay"
            />
            <label for="branchCode" class="mandatory">Branch Code</label>
          </FloatLabel>

          <FloatLabel>
            <Select
              v-model="selectedUserRole"
              inputId="userRole"
              :options="userRoles"
              optionLabel="name"
              class="branch-select"
              overlayClass="branch-overlay"
            />
            <label for="userRole" class="mandatory">User Role</label>
          </FloatLabel>
        </div>
      </Fieldset>
      <Fieldset legend="Login Details">
        <div class="fieldset">
          <FloatLabel>
            <InputText class="input-text" id="userName" v-model="userName"></InputText>
            <label for="userName" class="mandatory">Username</label>
          </FloatLabel>
          <FloatLabel>
            <InputText
              :feedback="false"
              :type="passwordVisible ? 'text' : 'password'"
              size="large"
              id="password"
              v-model="password"
              class="input-text"
            ></InputText>
            <label for="password" class="mandatory">Password</label>
            <i
              :class="passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"
              class="toggle-password"
              @click="togglePasswordVisibility"
            ></i>
          </FloatLabel>
        </div>
      </Fieldset>
    </div>
    <template #footer>
      <div style="margin-top: 1rem; display: flex; gap: 1rem">
        <CustomButton
          :outlined="true"
          title="close"
          icon="times"
          @click="(addEmployeeVisible = false), (validBranches = [])"
        />
        <CustomButton title="Save" icon="check" @click="handleAddEmployee" />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="addSalaryVisible"
    :draggable="false"
    modal
    :style="{ width: '40rem' }"
    :closable="false"
  >
    <span class="dialog-header">Salary Details</span>
    <Divider />
    <div class="dialog-form">
      <FloatLabel>
        <label for="selectedDate" class="mandatory">Select Month and Year</label>
        <DatePicker
          v-model="salaryMonth"
          id="selectedDate"
          class="input-text"
          view="month"
          :max-date="maxDate"
          dateFormat="MM/yy"
        />
      </FloatLabel>
      <FloatLabel>
        <InputNumber
          id="salaryAmount"
          input-id="locale-indian"
          locale="en-IN"
          mode="currency"
          currency="INR"
          v-model="salaryAmount"
          :maxFractionDigits="0"
          class="input-text"
          size="large"
          :min="0"
        />
        <label for="salaryAmount" class="mandatory">Salary Amount</label>
      </FloatLabel>
      <FloatLabel>
        <InputNumber
          id="pensionAmount"
          input-id="locale-indian"
          locale="en-IN"
          mode="currency"
          currency="INR"
          v-model="pensionAmount"
          :maxFractionDigits="0"
          class="input-text"
          size="large"
          :min="0"
        />
        <label for="pensionAmount" class="mandatory">PF Amount</label>
      </FloatLabel>
    </div>
    <template #footer>
      <CustomButton
        :outlined="true"
        title="close"
        icon="times"
        @click="
          (addSalaryVisible = false),
            (salaryAmount = undefined),
            (salaryMonth = undefined),
            (pensionAmount = undefined)
        "
      />
      <CustomButton title="Send" icon="check" @click="creditSalary" />
    </template>
  </Dialog>
  <button class="add-employee-button" @click="handleAddClick">
    <span class="hover-icon">+</span>
    <span class="hover-text">Add Employee</span>
  </button>
  <div v-if="isLoading">
    <LoadingComponent
      text="Please wait while we process the request!"
      :dialogVisiblity="isLoading"
    />
  </div>
  <ConfirmDialog :draggable="false" :closable="false" />
</template>
<style scoped>
.add-employee-button {
  --p-button-icon-only-width: 50px;
  position: absolute;
  bottom: 7vh;
  right: 7vh;
  width: 3rem;
  height: 3rem;
  transition: width 0.3s ease;
  display: flex;
  font-size: large;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgb(50, 95, 186);
  color: white;
  border-radius: 10px;
  cursor: pointer;

  .hover-icon {
    font-size: 2rem;
  }

  .hover-text {
    display: none;
  }
}

.add-employee-button:hover {
  width: 12rem;

  .hover-text {
    display: block;
    opacity: 0;
    padding-left: 1rem;
    animation: fadeIn 0.2s ease forwards;
    animation-delay: 0.2s;
    animation-play-state: running;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .input-text {
    height: 40px;
    width: 100%;
  }

  .fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.7rem;
    padding: 1.2rem;
  }
}

.dialog-form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.mandatory::after {
  content: ' *';
  color: red;
}

.branch-select .p-dropdown-item {
  color: rgb(50, 95, 186);
}

.branch-overlay {
  --p-select-overlay-color: rgb(50, 95, 186);
}

.branch-select {
  width: 100%;
  height: 45px;
  font-family: Epilogue;
}

.p-select {
  display: flex;
  font-size: medium;
  align-items: center;

  .p-select-option .p-select-option-selected {
    --p-select-option-selected-background: #ffffff;
    --p-select-option-selected-color: black;
  }
}
</style>
