enum ApiEndpoints {
  Login = 'api/auth',
  Customer_Create = '/api/customer/createCustomerWithShAndSB',
  Search_Customer = 'api/customer/search',
  Get_Customer = 'api/customer/getCustomerDetails',
  Search_Account = 'api/account/sb/searchByAccId',
  Account_Withdraw = 'api/account/sb/withdraw',
  Account_Deposit = 'api/account/sb/deposit',
  SB_Account_Create = 'api/account/sb/create',
  SB_Account_Check = '/api/account/sb/checkAccountAndGet',
  Statement = 'api/account/sb/statement',
  FD_Create = 'api/fd/create',
  FD_Withdraw = 'api/fd/withdraw',
  Apply_Loan = '/api/loan/apply',
  Loan_Pending = '/api/loan/pendingApplication',
  Loan_Approved = '/api/loan/approvedApplication',
  Loan_Approve = '/api/loan/approve',
  Loan_Reject = '/api/loan/reject',
  Loan_Disburse = '/api/loan/disburse',
  Loan_Status = '/api/loan/applicationStatus',
  Search_Loan = '/api/loan/getByLoanId',
  Pay_EMI = '/api/loan/payEmi',
  EMI_Transactions = '/api/loan/getEmiByLoanId',
  Pigmy_Create = 'api/pigmy/create',
  Pigmy_Search = 'api/pigmy/search',
  Get_Pigmies = 'api/pigmy/getByCustomerId',
  Pigmy_Statement = 'api/pigmy/getPigmyTransactionById',
  Pigmy_Approve = 'api/pigmy/approve',
  Pigmy_Pending = 'api/pigmy/pendingApprovals',
  Employee_Search = 'api/employee/getByEmployeeId',
  Employee_Create = 'api/employee/create',
  Branch_Search = 'api/branch/all',
  Branch_Create = 'api/branch/create',
  ShareHolder_Create = 'api/shareholder/create',
  ShareHolder_Transaction = '/api/shareholder/getTransactionsById',
  Get_Settings = '/api/settings/get',
  Save_Settings = '/api/settings/save',
  Get_Fd_Scheme = 'api/settings/fdSchemes',
  Get_Pigmy_Reports = '/api/reports/pigmy',
  Get_Loan_Scheme = '/api/settings/loanSchemes',
  Get_First_Trans = '/api/pigmy/getFirstTransactionDateByPigmyId',
  Close_Pigmy = '/api/pigmy/close',
  Get_Savings_Reports = '/api/sb/account/report',
  Close_SB = '/api/account/sb/close',
  Customer_Details_Report = '/api/customer/all',
  Add_Cash_Flow = '/api/cashFlow/add',
  Get_Cash_Flow = '/api/cashFlow/get',
  FD_Report = '/api/reports/fd',
  Loan_Report = '/api/reports/loan',
  Shareholder_Report = '/api/reports/shareholder',
  Edit_Customer = '/api/customer/edit',
  Close_Shareholder = '/api/shareholder/close',
  Partial_Withdraw = '/api/pigmy/partialWithdraw',
  Delete_In_Ex = '/api/cashFlow/delete',
  Get_Approved_Pigmy = '/api/pigmy/approvedApprovals',
  Create_Rd = '/api/rd/create',
  Deposit_Rd = '/api/rd/deposit',
  Close_Rd = '/api/rd/close',
  Close_Emp = '/api/employee/close',
  Close_Loan = '/api/loan/close',
  RD_Report = '/api/reports/rd',
  Credit_Salary = '/api/employee/paySalary',
  Get_Balancesheet = '/api/reports/balanceSheet',
  All_SB_Reports = '/api/reports/allSBAccounts',
  All_Shareholder_Report = '/api/reports/allShareholders',
  cashbook = "/api/reports/cashbook",
  Get_Banks = '/api/bank/search',
  Add_Bank = '/api/bank/add',
  Add_External_Deposit = '/api/bank/deposit',
  Add_External_Withdraw = '/api/bank/withdraw',
  Get_External_Trans = '/api/bank/details',
  Get_All_PigmyReports = '/api/reports/allPigmy',
  Get_All_LoanReports = '/api/reports/allLoan',

}

export default ApiEndpoints
