<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import { onMounted, ref } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import type ExternalBankDetails from '@/api/models/ExternalBank/ExternalBankDetails'
import type GetBanksReq from '@/api/models/ExternalBank/GetBankReq'
import ApiService from '@/api/ApiService'
import type { ToastMessageOptions } from 'primevue/toast'
import NotFound from '@/assets/not_found.svg'
import ConversionUtility from '../utils/ConversionUtility'
import LoadingComponent from '@/components/LoadingComponent.vue'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type AddBankReq from '@/api/models/ExternalBank/AddBankReq'
import type { CreatedBy } from '@/api/models/CreatedBy'
import Storage from '@/utils/Storage'
import type DepositReq from '@/api/models/ExternalBank/DepositReq'
import type WithdrawReq from '@/api/models/ExternalBank/WithdrawReq'

const toast = useToast()
const isLoading = ref(false)
const confirmDialog = useConfirm()
const addNewBank = ref(false)

const bankName = ref('')
const accountNo = ref('')

const existingBanks = ref<ExternalBankDetails[]>([])
const selectedBank = ref<ExternalBankDetails>()
const viewDialog = ref(false)
const action = ref('')
const amount = ref(0)
const transDate = ref<Date>()

onMounted(() => {
  handleGetBanks()
})

function handleGetBanks() {
  isLoading.value = true
  const req: GetBanksReq = {
    query: ''
  }
  ApiService.getBanks(req)
    .then((response) => {
      if (response.statusCode == 200) {
        existingBanks.value = response.data
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Banks', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function addBank() {
  if (!bankName.value) {
    showToast('Error', 'Please enter Bank Name', 'error')
    return
  }
  if (!accountNo.value) {
    showToast('Error', 'Please enter account No', 'error')
    return
  }

  isLoading.value = true
  const createdBy: CreatedBy = {
    empId: Storage.getEmpId()!,
    createdAt: new Date().getTime()
  }
  const req: AddBankReq = {
    bankName: bankName.value.trim().toString().toUpperCase(),
    accountNumber: accountNo.value.toString(),
    createdBy: createdBy
  }
  ApiService.addExternalBank(req)
    .then((response) => {
      if (response.statusCode == 200) {
        showToast('Success', 'Bank Added', 'success')
        addNewBank.value = false
        bankName.value = ''
        accountNo.value = ''
        handleGetBanks()
      } else {
        showToast('Error', response.error!, 'error')
      }
    })
    .catch((error) => {
      showToast('Error', 'Something is wrong', 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function performAction() {
  if (!transDate.value) {
    showToast('Error', 'Please enter Transaction Date', 'error')
    return
  }
  if (!amount.value) {
    showToast('Error', 'Please enter amount', 'error')
    return
  }
  isLoading.value = true
  if (action.value == 'Deposit') {
    const req: DepositReq = {
      bankName: selectedBank.value?.bankName!,
      deposit: {
        amount: ConversionUtility.toPaiseConversion(amount.value),
        createdAt: new Date(transDate.value).getTime()
      }
    }

    ApiService.addExternalBankDeposit(req)
      .then((response) => {
        if (response.statusCode == 200) {
          showToast(
            'Success',
            amount.value + ' Deposited to ' + selectedBank.value?.bankName,
            'success'
          )
          viewDialog.value = false
          action.value = ''
          transDate.value = undefined
          amount.value = 0
          return
        } else {
          showToast('Error', response.error!, 'error')
        }
      })
      .catch((error) => {
        showToast('Error', 'Something is wrong', 'error')
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  else {
    const req: WithdrawReq = {
      bankName: selectedBank.value?.bankName!,
      withdraw: {
        amount: ConversionUtility.toPaiseConversion(amount.value),
        createdAt: new Date(transDate.value).getTime()
      }
    }

    ApiService.addExternalBankWithdraw(req)
      .then((response) => {
        if (response.statusCode == 200) {
          showToast(
            'Success',
            amount.value + ' Withdrawn from ' + selectedBank.value?.bankName,
            'success'
          )
          viewDialog.value = false
          action.value = ''
          transDate.value = undefined
          amount.value = 0
          return
        } else {
          showToast('Error', response.error!, 'error')
        }
      })
      .catch((error) => {
        showToast('Error', 'Something is wrong', 'error')
      })
      .finally(() => {
        isLoading.value = false
      })
  }
}

function handleAddClick() {
  addNewBank.value = true
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <Toast class="error-p-toast" />
  <main class="main" v-bind="$attrs">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Existing External Banks</h2>
      </template>
      <template #content>
        <div v-if="existingBanks == undefined && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="Currently No Banks found!" style="font-weight: 600" />
        </div>
        <div class="cont">
          <DataTable v-if="existingBanks" :value="existingBanks">
            <Column header="Bank Name" field="bankName" />
            <Column header="Account No" field="accountNumber" />
            <Column header="Balance">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.balance) }}
              </template>
            </Column>
            <Column header="Created on">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(slotProps.data.createdBy.createdAt) }}
              </template>
            </Column>
            <Column header="Deposit">
              <template #body="slotProps">
                <CustomButton
                  title="Deposit"
                  icon="plus"
                  @click="
                    (selectedBank = slotProps.data),
                      (viewDialog = true),
                      (transDate = undefined),
                      (action = 'Deposit'),
                      (amount = 0)
                  "
                />
              </template>
            </Column>
            <Column header="Withdraw">
              <template #body="slotProps">
                <CustomButton
                  :outlined="true"
                  title="Withdraw"
                  icon="minus"
                  @click="
                    (selectedBank = slotProps.data),
                      (viewDialog = true),
                      (transDate = undefined),
                      (action = 'Withdraw'),
                      (amount = 0)
                  "
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </Card>
  </main>
  <Dialog
    v-model:visible="addNewBank"
    :draggable="false"
    modal
    :style="{ width: '40rem' }"
    :closable="false"
  >
    <span class="dialog-header">Add new Bank</span>
    <Divider />
    <div class="dialog-form">
      <FloatLabel>
        <InputText class="input-text" id="bankName" v-model="bankName"></InputText>
        <label for="bankName" class="mandatory">Bank Name</label>
      </FloatLabel>

      <FloatLabel>
        <InputText class="input-text" id="startDuration" v-model="accountNo"></InputText>
        <label for="startDuration" class="mandatory">Account No</label>
      </FloatLabel>
    </div>
    <template #footer>
      <CustomButton
        :outlined="true"
        title="close"
        icon="times"
        @click="(addNewBank = false), (accountNo = ''), (bankName = '')"
      />
      <CustomButton title="Add" icon="check" @click="addBank" />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="viewDialog"
    :draggable="false"
    modal
    :style="{ width: '40rem' }"
    :closable="false"
  >
    <span class="dialog-header">{{ action }} to {{ selectedBank?.bankName }}</span>
    <Divider />
    <div class="dialog-form">
      <FloatLabel>
        <label for="selectedDate" class="mandatory">Transaction Date</label>
        <DatePicker
          v-model="transDate"
          id="selectedDate"
          class="input-text"
          :max-date="new Date()"
          dateFormat="dd/mm/yy"
        />
      </FloatLabel>
      <FloatLabel>
        <InputNumber
          id="salaryAmount"
          input-id="locale-indian"
          locale="en-IN"
          mode="currency"
          currency="INR"
          v-model="amount"
          :maxFractionDigits="0"
          class="input-text"
          size="large"
          :min="0"
        />
        <label for="salaryAmount" class="mandatory">{{ action }} Amount</label>
      </FloatLabel>
    </div>
    <template #footer>
      <CustomButton
        :outlined="true"
        title="close"
        icon="times"
        @click="(viewDialog = false), (action = ''), (transDate = undefined), (amount = 0)"
      />
      <CustomButton title="Add" icon="check" @click="performAction" />
    </template>
  </Dialog>
  <button class="add-employee-button" @click="handleAddClick">
    <span class="hover-icon">+</span>
    <span class="hover-text">Add Bank</span>
  </button>
  <div v-if="isLoading">
    <LoadingComponent
      text="Please wait while we process the request!"
      :dialogVisiblity="isLoading"
    />
  </div>
  <ConfirmDialog :draggable="false" :closable="false" />
</template>
<style scoped>
.add-employee-button {
  --p-button-icon-only-width: 50px;
  position: absolute;
  bottom: 7vh;
  right: 7vh;
  width: 3rem;
  height: 3rem;
  transition: width 0.3s ease;
  display: flex;
  font-size: large;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgb(50, 95, 186);
  color: white;
  border-radius: 10px;
  cursor: pointer;

  .hover-icon {
    font-size: 2rem;
  }

  .hover-text {
    display: none;
  }
}

.add-employee-button:hover {
  width: 12rem;

  .hover-text {
    display: block;
    opacity: 0;
    padding-left: 1rem;
    animation: fadeIn 0.2s ease forwards;
    animation-delay: 0.2s;
    animation-play-state: running;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .input-text {
    height: 40px;
    width: 100%;
  }

  .fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.7rem;
    padding: 1.2rem;
  }
}

.dialog-form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.mandatory::after {
  content: ' *';
  color: red;
}

.branch-select .p-dropdown-item {
  color: rgb(50, 95, 186);
}

.branch-overlay {
  --p-select-overlay-color: rgb(50, 95, 186);
}

.branch-select {
  width: 100%;
  height: 45px;
  font-family: Epilogue;
}

.p-select {
  display: flex;
  font-size: medium;
  align-items: center;

  .p-select-option .p-select-option-selected {
    --p-select-option-selected-background: #ffffff;
    --p-select-option-selected-color: black;
  }
}
</style>
