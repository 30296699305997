<script setup lang="ts">
import * as pack from '../../package.json'

const newFeatures = [
  'Added a complete Loan Report with all fields with date and status filter',
  'Added a complete Pigmy Report with date and status filter',
  'Added the option to download or print Customer Details as a PDF',
  'Display customer name when searching for a loan',
  'Implemented the ability to add external banks',
  'Added deposit and withdrawal functionality for external banks',
  'View transaction history for external banks',
  'Bugfixes'
];
const newFeaturesKannada = [
  'ದಿನಾಂಕ ಮತ್ತು ಸ್ಥಿತಿಯ ಪ್ರಕಾರ ಸಂಪೂರ್ಣ ಸಾಲ ವರದಿ ಸೇರಿಸಲಾಗಿದೆ',
  'ದಿನಾಂಕ ಮತ್ತು ಸ್ಥಿತಿಯ ಪ್ರಕಾರ ಸಂಪೂರ್ಣ ಪಿಗ್ಮಿ ವರದಿ ಸೇರಿಸಲಾಗಿದೆ',
  'ಗ್ರಾಹಕರ ವಿವರಗಳನ್ನು PDF ಆಗಿ ಡೌನ್‌ಲೋಡ್ ಅಥವಾ ಮುದ್ರಿಸಲು ಆಯ್ಕೆ ಸೇರಿಸಲಾಗಿದೆ',
  'ಸಾಲ ಹುಡುಕುವಾಗ ಗ್ರಾಹಕರ ಹೆಸರನ್ನು ತೋರಿಸುತ್ತದೆ',
  'ಬಾಹ್ಯ ಬ್ಯಾಂಕ್‌ಗಳನ್ನು ಸೇರಿಸುವ ಅವಕಾಶ ನೀಡಲಾಗಿದೆ',
  'ಬಾಹ್ಯ ಬ್ಯಾಂಕ್‌ಗಳಿಗೆ ಠೇವಣಿ ಮತ್ತು ಹಣ ಹಿಂಪಡೆಯುವ ಸೌಲಭ್ಯ ಸೇರಿಸಲಾಗಿದೆ',
  'ಬಾಹ್ಯ ಬ್ಯಾಂಕ್‌ಗಳ ವ್ಯವಹಾರ ಇತಿಹಾಸ ವೀಕ್ಷಿಸಲು ಅವಕಾಶ ನೀಡಲಾಗಿದೆ',
  'ಕೆಲವು ದೋಷಗಳನ್ನು ಸರಿಪಡಿಸಲಾಗಿದೆ'
];
</script>

<template>
  <main class="main">
    <div class="flex gap-6 justify-center items-stretch">
      <!-- English Card -->
      <Card class="feature-card">
        <template #title>
          <div class="text-3xl font-semibold">What's new in v.{{ pack.version }}</div>
        </template>
        <template #content>
          <Divider />
          <ul class="list-disc list-inside text-left">
            <li class="py-1 text-l" v-for="(feature, index) in newFeatures" :key="index">
              {{ feature }}
            </li>
          </ul>
        </template>
      </Card>

      <!-- Kannada Card -->
      <Card class="feature-card">
        <template #title>
          <div class="text-3xl font-semibold">v.{{ pack.version }} ನಲ್ಲಿ ಹೊಸದು</div>
        </template>
        <template #content>
          <Divider />
          <ul class="list-disc list-inside text-left">
            <li class="py-1 text-l" v-for="(feature, index) in newFeaturesKannada" :key="index">
              {{ feature }}
            </li>
          </ul>
        </template>
      </Card>
    </div>
  </main>
</template>

<style scoped>
/* Container styling for the main section */
.main {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Card styling */
.feature-card {
  text-align: left;
  padding: 10px;
  width: fit-content;
  background-color: #f9f9f9;
  height: fit-content;
}

/* List styling */
ul {
  padding-left: 20px;
}
</style>
