<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue'
import Card from 'primevue/card'
import { ref, watch } from 'vue'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import type { ReportRequestByDate } from '@/api/models/reports/ReportRequestByDate'
import ApiService from '@/api/ApiService'
import ConversionUtility from '@/utils/ConversionUtility'
import Storage from '@/utils/Storage'
import router from '@/router'
import * as XLSX from 'xlsx'
import type { SavingsReport } from '@/api/models/reports/SavingsReport'
import type AllSavingsReportDetails from '@/api/models/reports/AllSavingsReportDetails'
import type { ReportRequestByNxtKey } from '@/api/models/reports/ReportRequestByNxtKey'
import { all } from 'axios'

const loadingProgress = ref<number>(50)
const toast = useToast()
const minDate = ref(new Date(2024, 8, 1))
const maxDate = ref(new Date())
const fromDate = ref<Date>()
const toDate = ref<Date | null>()
const isLoading = ref<boolean>(false)
const FDate = ref()
const EDate = ref()
const reportDetails = ref<SavingsReport>()
const allReportDetails = ref<AllSavingsReportDetails[]>([])
const nextPageKey = ref(null)

function viewReports() {
  if (!fromDate.value) {
    showToast('From Date cannot be empty', 'Please Select From Date', 'error')
    return
  }

  if (!toDate.value) {
    showToast('To Date cannot be empty', 'Please Select To Date', 'error')
    return
  }
  isLoading.value = true

  FDate.value = new Date(fromDate.value).setHours(0, 0, 0, 0)
  EDate.value = new Date(toDate.value).setHours(23, 59, 59, 999)

  const savingsRequest: ReportRequestByDate = {
    startDate: FDate.value,
    endDate: EDate.value
  }

  ApiService.savingsReport(savingsRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        reportDetails.value = response.data
      }
    })
    .catch((error) => {
      showToast('Settings', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function getAllSavingsReportDetails(nextPageKey:any) {
  isLoading.value = true
  const requestParam: ReportRequestByNxtKey = {
    nextPageKey: nextPageKey
  }
  ApiService.allSavingsReport(requestParam)
    .then((response) => {
      if (response.statusCode == 200) {
        console.log(response.data.nextPageKey)
        allReportDetails.value.push(...response.data.data)
        if (response.data.nextPageKey != null) {
          getAllSavingsReportDetails(response.data.nextPageKey)
        }
        if (response.data.nextPageKey == null) {
          downloadFullReport()
        }
      } else {
        showToast('Error', 'No customers', 'error')
      }
    })
    .catch((error) => {
      showToast('Error', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}

function download() {
  const reportData = [
    ["Bhavasar Patini Sahakar Sanga", "", ""],
    ["Tq: Badami, Dis: Bagalkot", "", ""],
    [],
    ["Savings Bank Account Reports"],
    [],
    ["From:", ConversionUtility.toDateFormat(reportDetails.value?.startDate!!)],
    ["To:", ConversionUtility.toDateFormat(reportDetails.value?.endDate!!)],
    [
      "Total Deposited Amount:",
      ConversionUtility.toRuppesCoversion(reportDetails.value?.totalDeposit!!)
    ],
    [
      "Total Withdrawal:",
      ConversionUtility.toRuppesCoversion(reportDetails.value?.totalWithdrawal!!)
    ],
    ["New Savings Accounts:", reportDetails.value?.totalNewAccountsOpened],
    ["Closed Savings Accounts:", reportDetails.value?.totalSBAccountClosed],
    []
  ]

  const worksheet = XLSX.utils.aoa_to_sheet(reportData)

  // Merge cells to center the bank name and location
  worksheet["!merges"] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // Merge A1:C1 for Bank Name
    { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } } // Merge A2:C2 for Location
  ]

  // Apply styles for bank name and location
  worksheet["A1"].s = {
    font: { bold: true, sz: 18 },
    alignment: { horizontal: "center", vertical: "center" }
  }
  worksheet["A2"].s = {
    font: { bold: true, sz: 16 },
    alignment: { horizontal: "center", vertical: "center" }
  }

  worksheet["!cols"] = [{ wch: 32 }, { wch: 25 }, { wch: 15 }]
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

  loadingProgress.value = 50

  // Generate Excel file as an array buffer
  const workbookArrayBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })

  // Convert the array buffer to a Blob
  const blob = new Blob([workbookArrayBuffer], { type: "application/octet-stream" })
  loadingProgress.value = 75

  // Create a download link and trigger the download
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute(
    "download",
    `Savings_Account_Report_${ConversionUtility.toDateFormat(reportDetails.value?.startDate!!)}_to_${ConversionUtility.toDateFormat(reportDetails.value?.endDate!!)}.xlsx`
  )
  document.body.appendChild(link)
  link.click()
}

function downloadFullReport() {
  loadingProgress.value = 25
  isLoading.value = true

  const reportDataDownload = [
    ['Bhavasar Patini Sahakar Sanga', '', '', ''],
    ['Tq: Badami, Dis: Bagalkot', '', '', ''],
    [],
    ['Savings Account Report - All Customer'],
    ['Total Customers', allReportDetails.value.length],
    [],
    ['Customer Id', 'Account Id', 'Customer Name', 'Phone Number', 'Balance', 'Created On']
  ]

  allReportDetails.value?.forEach((customer) => {
    reportDataDownload.push([
      customer.customerId,
      customer.accountId,
      customer.customerName,
      customer.customerPhoneNumber,
      ConversionUtility.toRuppesCoversion(customer.balance),
      ConversionUtility.toDateFormat(customer.createdAt)
    ])
  })

  const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload)

  // Merge cells for Bank Name and Location
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, // Merge A1:D1 for Bank Name
    { s: { r: 1, c: 0 }, e: { r: 1, c: 3 } } // Merge A2:D2 for Location
  ]

  // Set column widths
  worksheet['!cols'] = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 }
  ]

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    `SB_Report_Customerwise_as_on_${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`
  )
  document.body.appendChild(link)
  link.click()

  isLoading.value = false
  allReportDetails.value = []
  showToast('Success', 'Report has been downloaded', 'success')
}

watch(fromDate, (newFromDate) => {
  if (newFromDate && toDate.value) {
    toDate.value = null
  }
})
</script>

<template>
  <Toast class="error-p-toast" />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Savings Accounts Reports</h2>
      </template>
      <template #content>
        <Divider />

        <div class="form-2">
          <FloatLabel>
            <label for="fromDate" class="mandatory">From</label>
            <DatePicker
              v-model="fromDate"
              id="fromDate"
              class="input-text"
              :min-date="minDate"
              :max-date="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>

          <FloatLabel>
            <label for="toDate" class="mandatory">To</label>
            <DatePicker
              v-model="toDate"
              id="toDate"
              class="input-text"
              :min-date="fromDate"
              :maxDate="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>
          <CustomButton title="View Reports" @click="viewReports" class="cstmbtn" />
          <CustomButton title="Download All Report"  @click="getAllSavingsReportDetails(nextPageKey)" />
        </div>

        <Fieldset
          v-if="reportDetails"
          style="text-align: left; margin: 10px"
          legend="Savings Account report Details"
        >
          <div class="cont">
            <div class="report-details">
              <div class="">
                <span class="">Range from : </span>
                <span class="text"
                >{{ ConversionUtility.toDateFormat(reportDetails?.startDate) }} to
                  {{ ConversionUtility.toDateFormat(reportDetails?.endDate) }}</span
                >
              </div>
              <div class="">
                <span class="">Total Deposits: </span>
                <span class="text">{{
                    ConversionUtility.toStringRupeesConversion(reportDetails?.totalDeposit)
                  }}</span>
              </div>
              <div class="">
                <span class="">Total Withdrawals: </span>
                <span class="text">{{
                    ConversionUtility.toStringRupeesConversion(reportDetails?.totalWithdrawal)
                  }}</span>
              </div>

              <div>
                <span class="">New Savings Accounts: </span>
                <span class="text">{{ reportDetails?.totalNewAccountsOpened }}</span>
              </div>
              <div>
                <span class="">Closed Savings Accounts: </span>
                <span class="text">{{ reportDetails?.totalSBAccountClosed }}</span>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end; margin-top: 20px; gap: 1rem">
            <CustomButton title="Download Report" @click="download" />
          </div>
        </Fieldset>
        <div v-else class="container-footer">
          <Tag severity="secondary">No Savings Reports found for the given time.</Tag>
        </div>

        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
      </template>

      <template #footer> </template>
    </Card>
  </main>
</template>
<style>
.mandatory::after {
  content: ' *';
  color: red;
}

.form-2 {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}

.cstmbtn {
  width: 50%;
}

.input-text {
  height: 45px;
}

.report-details {
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
