<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue';
import Card from 'primevue/card';
import CustomButton from '@/components/CustomComponent/CustomButton.vue';
import { onMounted, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import ApiService from '@/api/ApiService';
import type { ReportRequestByNxtKey }  from '@/api/models/reports/ReportRequestByNxtKey';
import type { CustomerDetails } from '@/api/models/reports/CustomerDetails';
import type { ToastMessageOptions } from 'primevue/toast';
import ConversionUtility from '@/utils/ConversionUtility';
import * as XLSX from 'xlsx'
import ProgressBar from 'primevue/progressbar';

const loadingProgress = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const nextPageKey = ref(null)
const customerDetails = ref<CustomerDetails[]>([])

function getCustomerDetails(nextPageKey:any){
    isLoading.value = true
    const requestParam: ReportRequestByNxtKey ={
        nextPageKey: nextPageKey
    }

    ApiService.customerDetailsReport(requestParam)
    .then((response) => {
        if(response.statusCode == 200){
          customerDetails.value.push(...response.data.customerReportDetail)
          if (response.data.nextPageKey != null){           
            getCustomerDetails(response.data.nextPageKey)
          }
          if(response.data.nextPageKey == null){
            downloadReport()
          }
        }
        else{
            showToast('Error','No customers', 'error')
        }
    })
    .catch((error) => {
        showToast('Errorerr', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    }
    )
}

function downloadReport() {
  loadingProgress.value = 25
  isLoading.value = true

  const reportDataDownload = [
    ['Bhavasar Patini Sahakar Sanga', '', '', '', '', '', ''],
    ['Tq: Badami, Dis: Bagalkot', '', '', '', '', '', ''],
    [],
    ['Customer Details Report'],
    ['Total Customers', customerDetails.value.length],
    [],
    ['Customer ID', 'First Name', 'Middle Name', 'Last Name', 'Phone No', 'Address', 'Created On']
  ]

  customerDetails.value?.forEach((customer) => {
    reportDataDownload.push([
      customer.customerId,
      customer.firstName,
      customer.middleName,
      customer.lastName,
      customer.phoneNumber,
      customer.address,
      ConversionUtility.toDateFormat(customer.createdAt)
    ])
  })

  const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload)

  // Merge cells to center the bank name and location
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }, // Merge A1:G1 for Bank Name
    { s: { r: 1, c: 0 }, e: { r: 1, c: 6 } } // Merge A2:G2 for Location
  ]

  // Apply styles for bank name and location
  worksheet['A1'].s = {
    font: { bold: true, sz: 18 },
    alignment: { horizontal: 'center', vertical: 'center' }
  }
  worksheet['A2'].s = {
    font: { bold: true, sz: 16 },
    alignment: { horizontal: 'center', vertical: 'center' }
  }

  worksheet['!cols'] = [
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
    { wch: 40 },
    { wch: 20 }
  ]
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    `Customer_Detail_Report_as_on_${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`
  )
  document.body.appendChild(link)
  link.click()

  isLoading.value = false
  customerDetails.value = []
  showToast('Success', 'Report has been downloaded', 'success')
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
    <Toast class="error-p-toast" />
    <main class="main">
        <Card class="search-card">
            <template #title>
                <h2 class="title-right text-3xl font-bold">Customers Report</h2>
            </template>
            <template #content>
                <div v-if="customerDetails" style="display: flex; justify-content: flex-start; margin-top: 20px;gap: 1rem">
                    <CustomButton title="Download Report" @click="getCustomerDetails(nextPageKey)"/>
                </div>

                <Dialog
              :visible="isLoading"
              modal
              :draggable="false"
              :closable="false"
              style="width: 60vw"
              header="Downloading....."
            >
            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
            </Dialog>
            </template>
        </Card>
    </main>
</template>
<style>
</style>