import axios, { type AxiosResponse } from 'axios'
import ApiEndpoints from '../api/apiUtils/ApiConstants'
import Storage from '@/utils/Storage'
import type { LoginRequest } from './models/login/LoginRequest'
import type { LoginResponse } from './models/login/LoginResponse'
import type { SearchCustomerRequest } from './models/customer/SearchCustomerRequest'
import type { SearchCustomerResponse } from './models/customer/SearchCustomerResponse'
import type { AddCustomerRequest } from './models/customer/AddCustomerRequest'
import type { AddCustomerResponse } from './models/customer/AddCustomerResponse'
import type { CustomerDetailsRequest } from './models/customer/CustomerDetailsRequest'
import type { CustomerDetailsResponse } from './models/customer/CustomerDetailsResponse'
import type { SearchAccountRequest } from './models/account/SearchAccountRequest'
import type { SearchAccountResponse } from './models/account/SearchAccountResponse'
import type { AccountWithdrawRequest } from './models/account/AccountWithdrawRequest'
import type { AccountWithdrawResponse } from './models/account/AccountWithdrawResponse'
import type { AccountDepositRequest } from './models/account/AccountDepositRequest'
import type { AccountDepositResponse } from './models/account/AccountDepositResponse'
import type { AccountCheckResponse } from '@/api/models/account/AccountCheckResponse'
import type { CreateAccountResponse } from '@/api/models/account/CreateAccountResponse'
import type { CreateAccountRequest } from '@/api/models/account/CreateAccountRequest'
import type { SBStatementRequest } from './models/account/SBStatementRequest'
import type { SBStatementResponse } from './models/account/SBStatementResponse'
import type { SearchPigmyRequest } from './models/pigmy/SearchPigmyRequest'
import type { SearchPigmyResponse } from './models/pigmy/SearchPigmyResponse'
import type { PigmyStatementRequest } from './models/pigmy/PigmyStatementRequest'
import type { PigmyStatementResponse } from './models/pigmy/PigmyStatmentResponse'
import type { CreatePigmyRequest } from './models/pigmy/CreatepigmyRequest'
import type { CreatePigmyResponse } from './models/pigmy/CreatePigmyResponse'
import type { GetPigmiesRequest } from './models/pigmy/GetPigmiesRequest'
import type { GetPigmiesResponse } from './models/pigmy/GetPigmiesResponse'
import type { CreateBranchRequest } from './models/operations/branches/CreateBranchRequest'
import type { CreateBranchResponse } from './models/operations/branches/CreateBranchResponse'
import type { SearchBranchResponse } from './models/operations/branches/SearchBranchResponse'
import type { SearchEmployeeRequest } from './models/operations/employees/SearchEmployeeRequest'
import type { SearchEmployeeResponse } from './models/operations/employees/SearchEmployeeResponse'
import type { CreateEmployeeRequest } from './models/operations/employees/CreateEmployeeRequest'
import type { CreateEmployeeResponse } from './models/operations/employees/CreateEmployeeResponse'
import CryptoUtil from '@/utils/CryptoUtils'
import type { CreateShareHolderRequest } from '@/api/models/shareholder/CreateShareHolderRequest'
import type { CreateShareholderResponse } from '@/api/models/shareholder/CreateShareholderResponse'
import type { PigmyApprovalPendingResponse } from './models/pigmy/PigmyApprovalPendingResponse'
import type { ApprovePigmyRequest } from './models/pigmy/ApprovePigmyRequest'
import type { ApprovePigmyResponse } from './models/pigmy/ApprovePigmyResponse'
import type { CreateFdRequest } from './models/fd/CreateFdRequest'
import type { CreateFdResponse } from './models/fd/CreateFdResponse'
import type { WithdrawFdRequest } from './models/fd/WithdrawFdRequest'
import type { WithdrawFdResponse } from './models/fd/WithdrawFdResponse'
import type { GetAllSettings } from './models/settings/GetAllSettings'
import type { AllSettingsDetails } from './models/settings/AllSettingsDetails'
import type { SaveSettingResponse } from './models/settings/SaveSettingsResponse'
import type { GetFdSchemes } from '@/api/models/setting/GetFdSchemes'
import type { ReportRequestByDate } from './models/reports/ReportRequestByDate'
import type { PigmyReportResponse } from './models/reports/PigmyReportResponse'
import type { GetLoanSchemes } from './models/setting/GetLoanSchemes'
import type { ApplyLoanRequest } from './models/loan/ApplyLoanRequest'
import type { ApplyLoanResponse } from './models/loan/ApplyLoanResponse'
import type { GetPendingLoansResponse } from './models/loan/GetPendingLoansResponse'
import type { ApproveLoanRequest } from './models/loan/ApproveLoanRequest'
import type { ApproveLoanResponse } from './models/loan/ApproveLoanResponse'
import type { RejectLoanRequest } from './models/loan/RejectLoanRequest'
import type { RejectLoanResponse } from './models/loan/RejectLoanResponse'
import type { GetApprovedLoansResponse } from './models/loan/GetApprovedLoansResponse'
import type { LoanStatusRequest } from './models/loan/LoanStatusRequest'
import type { LoanStatusResponse } from './models/loan/LoanStatusResponse'
import type { FirstTransRequest } from './models/pigmy/FirstTransRequest'
import type { FirstTransResponse } from './models/pigmy/FirstTransResponse'
import type { ClosePigmyRequest } from './models/pigmy/ClosePigmyRequest'
import type { ClosePigmyResponse } from './models/pigmy/ClosePigmyResponse'
import type { SavingsReportResponse } from './models/reports/SavingsReportsResponse'
import type { CloseAccountRequest } from './models/account/CloseAccountRequest'
import type { CloseAccountResponse } from './models/account/CloseAccountResponse'
import type { LoanDisburseRequest } from './models/loan/LoanDisburseRequest'
import type { LoanDisburseResponse } from './models/loan/LoanDisburseResponse'
import type { SearchLoanRequest } from './models/loan/SearchLoanRequest'
import type { SearchLoanResponse } from './models/loan/SearchLoanResponse'
import type { payEMIResponse } from './models/loan/PayEMIResponse'
import type { PayEMIRequest } from './models/loan/PayEMIRequest'
import type { EMITransactionsRequest } from './models/loan/EMITransactionsRequest'
import type { EMITransactionsResponse } from './models/loan/EMITransactionsResponse'
import type { GetShareholderTransactionsRequest } from './models/shareholder/GetShareholderTransactionsRequest'
import type { GetShareholderTransactionsResponse } from './models/shareholder/GetShareholderTransactionsResponse'
import type { ReportRequestByNxtKey } from './models/reports/ReportRequestByNxtKey'
import type { CustomerDetailsReportResponse } from './models/reports/CustomerDetailsReportResponse'
import type GetCashFlowRequest from './models/cashflow/GetCashFlowRequest'
import type GetCashFlowResponse from './models/cashflow/GetCashFlowResponse'
import type AddCashFlowRequest from './models/cashflow/AddCashFlowRequest'
import type AddCashFlowResponse from './models/cashflow/AddCashFlowResponse'
import type { ShareholderReportResponse } from './models/reports/ShareholderReportResponse'
import type { FDReportResponse } from './models/reports/FDReportResponse'
import type { LoanReportResponse } from './models/reports/LoanReportResponse'
import type { EditCustomerRequest } from './models/customer/EditCustomerRequest'
import type { EditCustomerResponse } from './models/customer/EditCustomerResponse'
import type CloseShareholderRequest from './models/shareholder/CloseShareholderRequest'
import type PartialWithdrawalRequest from './models/pigmy/PartialWithdrawRequest'
import type PartialWithdrawalResponse from './models/pigmy/PartialWithdrawResponse'
import type DeleteCashflowRequest from './models/cashflow/DeleteCashFlowRequest'
import type DeleteCashflowResponse from './models/cashflow/DeleteCashFlowResponse'
import type GetApprovedPigmies from './models/pigmy/GetApprovedPigmyResponse'
import type CreateRdRequest from './models/rd/CreateRdRequest'
import type CreateRdResponse from './models/rd/CreateRdResponse'
import type DepositRdRequest from './models/rd/DepositRdRequest'
import type DepositRdResponse from './models/rd/DepositRdResponse'
import type CloseRdRequest from './models/rd/CloseRdRequest'
import type CloseRdResponse from './models/rd/CloseRdResponse'
import type CloseEmpRequest from './models/operations/employees/CloseEmpRequest'
import type CloseEmpResponse from './models/operations/employees/CloseEmpResponse'
import type CloseLoanRequest from './models/loan/CloseLoanRequest'
import type CloseLoanResponse from './models/loan/CloseLoanResponse'
import type RDReportResponse from './models/reports/RDReportResponse'
import type CreditSalaryRequest from './models/employee/CreditSalaryRequest'
import type CreditSalaryResponse from './models/employee/CreditSalaryResponse'
import type BalanceSheetResponse from './models/reports/BalanceSheetResponse'
import type BalancesheetRequest from './models/reports/BalancesheetRequest'
import type AllSavingsReportResponse from '@/api/models/reports/AllSavingsReportResponse'
import type AllShareholderReportResponse from '@/api/models/reports/AllShareholderReportResponse'
import type { CashbookResponse } from '@/api/models/reports/CashbookResponse'
import type { CashbookRequest } from '@/api/models/reports/CashbookRequest'
import type GetBanksReq from '@/api/models/ExternalBank/GetBankReq'
import type GetBanksRes from '@/api/models/ExternalBank/GetBanksRes'
import type AddBankReq from '@/api/models/ExternalBank/AddBankReq'
import type AddBankRes from '@/api/models/ExternalBank/AddBankRes'
import type DepositReq from '@/api/models/ExternalBank/DepositReq'
import type WithdrawReq from '@/api/models/ExternalBank/WithdrawReq'
import type GetTransDetailsRes from '@/api/models/ExternalBank/GetTransDetailsRes'
import type GetAllPigmyReportsReq from '@/api/models/reports/GetAllPigmyReportsReq'
import type GetAllPigmyReportsRes from '@/api/models/reports/GetAllPigmyReportsRes'
import type GetAllLoanReportsReq from '@/api/models/reports/GetAllLoanReportReq'
import type GetAllLoanReportRes from '@/api/models/reports/GetAllLoanReportRes'

export default class ApiService {
  private static axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' }
  })

  private static getAuthHeader(): { Authorization: string } {
    const token = Storage.getToken()

    return {
      Authorization: `Bearer ${token}`
    }
  }

  public static async login(request: LoginRequest): Promise<LoginResponse> {
    return this.axiosInstance
      .post<string>(ApiEndpoints.Login, CryptoUtil.encrypt(JSON.stringify(request)))
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as LoginResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as LoginResponse
      })
  }

  public static async searchCustomer(
    request: SearchCustomerRequest
  ): Promise<SearchCustomerResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Search_Customer, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SearchCustomerResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SearchCustomerResponse
      })
  }
  public static async getCustomerDetails(
    request: CustomerDetailsRequest
  ): Promise<CustomerDetailsResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Customer, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CustomerDetailsResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CustomerDetailsResponse
      })
  }
  public static async addCustomer(request: AddCustomerRequest): Promise<AddCustomerResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(
        ApiEndpoints.Customer_Create,
        CryptoUtil.encrypt(JSON.stringify(request)),
        config
      )
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AddCustomerResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AddCustomerResponse
      })
  }

  public static async searchAccount(request: SearchAccountRequest): Promise<SearchAccountResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Search_Account, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SearchAccountResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SearchAccountResponse
      })
  }

  public static async accountWithdraw(
    request: AccountWithdrawRequest
  ): Promise<AccountWithdrawResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(
        ApiEndpoints.Account_Withdraw,
        CryptoUtil.encrypt(JSON.stringify(request)),
        config
      )
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AccountWithdrawResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AccountWithdrawResponse
      })
  }

  public static async accountDeposit(
    request: AccountDepositRequest
  ): Promise<AccountDepositResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(
        ApiEndpoints.Account_Deposit,
        CryptoUtil.encrypt(JSON.stringify(request)),
        config
      )
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AccountDepositResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AccountDepositResponse
      })
  }

  public static async checkAccountAndGetCustomer(
    customerId: number
  ): Promise<AccountCheckResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: {
        cusId: customerId
      }
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.SB_Account_Check, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AccountCheckResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AccountCheckResponse
      })
  }

  public static async createAccount(request: CreateAccountRequest): Promise<CreateAccountResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(
        ApiEndpoints.SB_Account_Create,
        CryptoUtil.encrypt(JSON.stringify(request)),
        config
      )
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreateAccountResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreateAccountResponse
      })
  }

  public static async getSBStatement(request: SBStatementRequest): Promise<SBStatementResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Statement, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SBStatementResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SBStatementResponse
      })
  }

  public static async createFd(request: CreateFdRequest): Promise<CreateFdResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.FD_Create, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreateFdResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreateFdResponse
      })
  }

  public static async withdrawFd(request: WithdrawFdRequest): Promise<WithdrawFdResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.FD_Withdraw, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as WithdrawFdResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as WithdrawFdResponse
      })
  }

  public static async applyLoan(request: ApplyLoanRequest): Promise<ApplyLoanResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Apply_Loan, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as ApplyLoanResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as ApplyLoanResponse
      })
  }

  public static async getPendingLoans(): Promise<GetPendingLoansResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Loan_Pending, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetPendingLoansResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetPendingLoansResponse
      })
  }

  public static async getApprovedLoans(): Promise<GetApprovedLoansResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Loan_Approved, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetApprovedLoansResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetApprovedLoansResponse
      })
  }

  public static async approveLoan(request: ApproveLoanRequest): Promise<ApproveLoanResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .put<string>(ApiEndpoints.Loan_Approve, null, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as ApproveLoanResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as ApproveLoanResponse
      })
  }
  public static async rejectLoan(request: RejectLoanRequest): Promise<RejectLoanResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .put<string>(ApiEndpoints.Loan_Reject, null, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as RejectLoanResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as RejectLoanResponse
      })
  }

  public static async getLoanStatus(request: LoanStatusRequest): Promise<LoanStatusResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Loan_Status, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as LoanStatusResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as LoanStatusResponse
      })
  }

  public static async disburseLoan(request: LoanDisburseRequest): Promise<LoanDisburseResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Loan_Disburse, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as LoanDisburseResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as LoanDisburseResponse
      })
  }

  public static async searchLoan(request: SearchLoanRequest): Promise<SearchLoanResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Search_Loan, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SearchLoanResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SearchLoanResponse
      })
  }

  public static async payEMI(request: PayEMIRequest): Promise<payEMIResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Pay_EMI, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as payEMIResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as payEMIResponse
      })
  }

  public static async getEMITransactions(
    request: EMITransactionsRequest
  ): Promise<EMITransactionsResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.EMI_Transactions, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as EMITransactionsResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as EMITransactionsResponse
      })
  }

  public static async searchPigmy(request: SearchPigmyRequest): Promise<SearchPigmyResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Pigmy_Search, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SearchPigmyResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SearchPigmyResponse
      })
  }

  public static async getPigmyStatement(
    request: PigmyStatementRequest
  ): Promise<PigmyStatementResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Pigmy_Statement, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as PigmyStatementResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as PigmyStatementResponse
      })
  }

  public static async createPigmy(request: CreatePigmyRequest): Promise<CreatePigmyResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Pigmy_Create, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreatePigmyResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreatePigmyResponse
      })
  }

  public static async getPigmies(request: GetPigmiesRequest): Promise<GetPigmiesResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Pigmies, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetPigmiesResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetPigmiesResponse
      })
  }

  public static async getPendingPigmies(): Promise<PigmyApprovalPendingResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Pigmy_Pending, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as PigmyApprovalPendingResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as PigmyApprovalPendingResponse
      })
  }

  public static async approvePigmy(request: ApprovePigmyRequest): Promise<ApprovePigmyResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .post(ApiEndpoints.Pigmy_Approve, null, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as ApprovePigmyResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as ApprovePigmyResponse
      })
  }

  public static async createEmployee(
    request: CreateEmployeeRequest
  ): Promise<CreateEmployeeResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(
        ApiEndpoints.Employee_Create,
        CryptoUtil.encrypt(JSON.stringify(request)),
        config
      )
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreateEmployeeResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreateEmployeeResponse
      })
  }

  public static async searchEmployee(
    request: SearchEmployeeRequest
  ): Promise<SearchEmployeeResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Employee_Search, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SearchEmployeeResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SearchEmployeeResponse
      })
  }

  public static async createBranch(request: CreateBranchRequest): Promise<CreateBranchResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Branch_Create, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreateBranchResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreateBranchResponse
      })
  }

  public static async searchBranch(): Promise<SearchBranchResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Branch_Search, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SearchBranchResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SearchBranchResponse
      })
  }
  public static async createShareholder(
    request: CreateShareHolderRequest
  ): Promise<CreateShareholderResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(
        ApiEndpoints.ShareHolder_Create,
        CryptoUtil.encrypt(JSON.stringify(request)),
        config
      )
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreateShareholderResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreateShareholderResponse
      })
  }

  public static async shareholderTransactions(
    request: GetShareholderTransactionsRequest
  ): Promise<GetShareholderTransactionsResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.ShareHolder_Transaction, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetShareholderTransactionsResponse
      })
      .catch((error) => {
        return JSON.parse(
          CryptoUtil.decrypt(error.response.data)
        ) as GetShareholderTransactionsResponse
      })
  }

  public static async getAllSettings(): Promise<GetAllSettings> {
    const config = {
      headers: this.getAuthHeader()
    }
    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Settings, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetAllSettings
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetAllSettings
      })
  }

  public static async saveAllSettings(request: AllSettingsDetails): Promise<SaveSettingResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Save_Settings, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SaveSettingResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SaveSettingResponse
      })
  }

  public static async getFdScheme(): Promise<GetFdSchemes> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Fd_Scheme, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetFdSchemes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetFdSchemes
      })
  }

  public static async pigmyReport(request: ReportRequestByDate): Promise<PigmyReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Pigmy_Reports, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as PigmyReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as PigmyReportResponse
      })
  }

  public static async getLoanSchemes(): Promise<GetLoanSchemes> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Loan_Scheme, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetLoanSchemes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetLoanSchemes
      })
  }

  public static async getFirstTrans(request: FirstTransRequest): Promise<FirstTransResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_First_Trans, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as FirstTransResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as FirstTransResponse
      })
  }

  public static async ClosePigmy(request: ClosePigmyRequest): Promise<ClosePigmyResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .put<string>(ApiEndpoints.Close_Pigmy, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as ClosePigmyResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as ClosePigmyResponse
      });
  }

  public static async savingsReport(request: ReportRequestByDate): Promise<SavingsReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Savings_Reports, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as SavingsReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as SavingsReportResponse
      })
  }

  public static async closeSB(request: CloseAccountRequest): Promise<CloseAccountResponse> {
    const config = {
      headers: this.getAuthHeader(),
    }
  
    return this.axiosInstance
      .put<string>(ApiEndpoints.Close_SB, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CloseAccountResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CloseAccountResponse
      });
  }
  
  public static async customerDetailsReport(request: ReportRequestByNxtKey): Promise<CustomerDetailsReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Customer_Details_Report, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CustomerDetailsReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CustomerDetailsReportResponse
      })
  }

  public static async getCashFlow(request: GetCashFlowRequest): Promise<GetCashFlowResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
          .get<string>(ApiEndpoints.Get_Cash_Flow, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetCashFlowResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetCashFlowResponse
      })
  }
  public static async shareholderReport(request: ReportRequestByNxtKey): Promise<ShareholderReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Shareholder_Report, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as ShareholderReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as ShareholderReportResponse
      })
  }

  public static async FDReport(request: ReportRequestByNxtKey): Promise<FDReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.FD_Report, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as FDReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as FDReportResponse
      })
  }

  public static async loanReport(request: ReportRequestByNxtKey): Promise<LoanReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Loan_Report, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as LoanReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as LoanReportResponse
      })
  }

  public static async editCustomer(request: EditCustomerRequest): Promise<EditCustomerResponse> {
    const config = {
      headers: this.getAuthHeader(),
    }
  
    return this.axiosInstance
      .put<string>(ApiEndpoints.Edit_Customer, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as EditCustomerResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as EditCustomerResponse
      });
  }

  public static async closeShareholder(request: CloseShareholderRequest): Promise<CloseAccountResponse> {
    const config = {
      headers: this.getAuthHeader(),
    }
  
    return this.axiosInstance
      .post<string>(ApiEndpoints.Close_Shareholder, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CloseAccountResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CloseAccountResponse
      });
  }
  
  public static async partialWithdraw(request: PartialWithdrawalRequest): Promise<PartialWithdrawalResponse> {
    const config = {
      headers: this.getAuthHeader(),
    }
  
    return this.axiosInstance
      .post<string>(ApiEndpoints.Partial_Withdraw, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as PartialWithdrawalResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as PartialWithdrawalResponse
      });
  }

  public static async addCashFlow(request: AddCashFlowRequest): Promise<AddCashFlowResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Add_Cash_Flow, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AddCashFlowResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AddCashFlowResponse
      })
  }

  public static async deleteCashFlow(request: DeleteCashflowRequest): Promise<DeleteCashflowResponse> {
    const config = {
      headers: this.getAuthHeader(),
      data: CryptoUtil.encrypt(JSON.stringify(request))
    }

    return this.axiosInstance
      .delete<string>(ApiEndpoints.Delete_In_Ex, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as DeleteCashflowResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as DeleteCashflowResponse
      })
  }

  public static async getApprovedPigmies(): Promise<GetApprovedPigmies> {
    const config = {
      headers: this.getAuthHeader(),
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Approved_Pigmy, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetApprovedPigmies
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetApprovedPigmies
      })
  }

  public static async createRd(request: CreateRdRequest): Promise<CreateRdResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Create_Rd, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreateRdResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreateRdResponse
      })
  }

  public static async depositRd(request: DepositRdRequest): Promise<DepositRdResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Deposit_Rd, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as DepositRdResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as DepositRdResponse
      })
  }

  public static async closeRd(request: CloseRdRequest): Promise<CloseRdResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Close_Rd, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CloseRdResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CloseRdResponse
      })
  }

  public static async closeEmp(request: CloseEmpRequest): Promise<CloseEmpResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Close_Emp, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CloseEmpResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CloseEmpResponse
      })
  }

  public static async closeLoan(request: CloseLoanRequest): Promise<CloseLoanResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Close_Loan, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CloseLoanResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CloseLoanResponse
      })
  }


  public static async RDReport(): Promise<RDReportResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.RD_Report, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as RDReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as RDReportResponse
      })
  }

  public static async creditSalary(request: CreditSalaryRequest): Promise<CreditSalaryResponse> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Credit_Salary, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CreditSalaryResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CreditSalaryResponse
      })
  }
  public static async getBalancesheet(request: BalancesheetRequest ): Promise<BalanceSheetResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Balancesheet, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as BalanceSheetResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as BalanceSheetResponse
      })
  }

  public static async allSavingsReport(request: ReportRequestByNxtKey): Promise<AllSavingsReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.All_SB_Reports, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AllSavingsReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AllSavingsReportResponse
      })
  }
  public static async allShareholderReport(request: ReportRequestByNxtKey): Promise<AllShareholderReportResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }

    return this.axiosInstance
      .get<string>(ApiEndpoints.All_Shareholder_Report, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AllShareholderReportResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AllShareholderReportResponse
      })
  }

  public static async cashbook(date: CashbookRequest): Promise<CashbookResponse> {
    const config = {
      headers: this.getAuthHeader(),
      params: date
    }
    return this.axiosInstance
      .get<string>(ApiEndpoints.cashbook, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as CashbookResponse
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as CashbookResponse
      })
  }

  public static async getBanks(request: GetBanksReq): Promise<GetBanksRes> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }
    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_Banks, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetBanksRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetBanksRes
      })
  }

  public static async addExternalBank(request: AddBankReq): Promise<AddBankRes> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .post<string>(ApiEndpoints.Add_Bank, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AddBankRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AddBankRes
      })
  }

  public static async addExternalBankDeposit(request: DepositReq): Promise<AddBankRes> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .patch<string>(ApiEndpoints.Add_External_Deposit, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AddBankRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AddBankRes
      })
  }

  public static async addExternalBankWithdraw(request: WithdrawReq): Promise<AddBankRes> {
    const config = {
      headers: this.getAuthHeader()
    }

    return this.axiosInstance
      .patch<string>(ApiEndpoints.Add_External_Withdraw, CryptoUtil.encrypt(JSON.stringify(request)), config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as AddBankRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as AddBankRes
      })
  }

  public static async getExternalTrans(request: { query: string}): Promise<GetTransDetailsRes> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }
    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_External_Trans, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetTransDetailsRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetTransDetailsRes
      })
  }

  public static async getAllPigmyReports(request: GetAllPigmyReportsReq): Promise<GetAllPigmyReportsRes> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }
    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_All_PigmyReports, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetAllPigmyReportsRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetAllPigmyReportsRes
      })
  }

  public static async getAllLoanReports(request: GetAllLoanReportsReq): Promise<GetAllLoanReportRes> {
    const config = {
      headers: this.getAuthHeader(),
      params: request
    }
    return this.axiosInstance
      .get<string>(ApiEndpoints.Get_All_LoanReports, config)
      .then((response: AxiosResponse<string>) => {
        return JSON.parse(CryptoUtil.decrypt(response.data)) as GetAllLoanReportRes
      })
      .catch((error) => {
        return JSON.parse(CryptoUtil.decrypt(error.response.data)) as GetAllLoanReportRes
      })
  }
}
