<script setup lang="ts">
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import { ref } from 'vue'
import NotFound from '@/assets/not_found.svg'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { CreateBranchRequest } from '@/api/models/operations/branches/CreateBranchRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import type { Branch } from '@/api/models/operations/branches/Branch'
import ConversionUtility from '@/utils/ConversionUtility'
import ValidationUtility from '@/utils/ValidationUtility'

const toast = useToast()
const addBranchVisible = ref(false)
const branches = ref<Branch[]>([])
const isLoading = ref(false)
const address = ref('')
const city = ref('')
const pinCode = ref('')

const branchTableColumns = [
  { field: 'branchId', header: 'ID', colStyle: { width: '10%' } },
  { field: 'branchCode', header: 'Branch Code', colStyle: { width: '15%' } },
  { field: 'address', header: 'Address', colStyle: { width: '30%' } },
  { field: 'city', header: 'City', colStyle: { width: '15%' } },
  { field: 'pinCode', header: 'Pin Code', colStyle: { width: '15%' } }
]

function handelSearchBranch() {
  isLoading.value = true
  ApiService.searchBranch()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        branches.value = response.data
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Branch', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handleCreateBranch() {
  if (!address.value) {
    showToast('Address is blank/empty', 'Validation failed!', 'error')
    return
  }
  if (!ValidationUtility.validateString(city.value)) {
    showToast('Invalid City Entered', 'Enter Valid City Name', 'error')
    return
  }
  if (!city.value) {
    showToast('City is blank/empty', 'Validation failed!', 'error')
    return
  }
  if(!(ValidationUtility.validateString(city.value))) {
    showToast('Invalid City Name', 'Validation failed!', 'error')
    return
  }
  if (city.value.length<3){
    showToast('City Name is too short', 'City name must be at least 3 characters', 'error')
    return
  }
  if (!pinCode.value || pinCode.value.startsWith('0')) {
    showToast('Pin Code is invalid/empty', 'Validation failed!', 'error')
    return
  }
  isLoading.value = true
  const createdBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }
  const addBranchRequest: CreateBranchRequest = {
    address: address.value,
    city: city.value,
    pinCode: Number(pinCode.value),
    createdAt: Date.now(),
    createdBy: createdBy
  }

  ApiService.createBranch(addBranchRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        showToast(
          'Branch Created Successfully',
          'Branch with ID ' + response.data.branchId + ' created',
          'success'
        )
        addBranchVisible.value = false
      } else {
        showToast('Failed to create Branch', response.error, 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to create Branch', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      handelSearchBranch()
      address.value = ''
      city.value = ''
      pinCode.value = ''
    })
}
function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
handelSearchBranch()
</script>
<template>
  <Toast class="error-p-toast" />
  <main class="main" v-bind="$attrs">
    <Card class="search-card">
      <template #content>
        <div v-if="branches == undefined && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No branch found for the provided search query!" style="font-weight: 600" />
        </div>
        <div v-if="branches != undefined && !isLoading" class="cont">
          <h1 class="table-title text-3xl font-bold">Branches</h1>
          <Divider />
          <DataTable :value="branches" stripedRows>
            <Column
              v-for="col in branchTableColumns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              :style="col.colStyle"
            ></Column>
            <Column header="Created On" field="createdAt">
              <template #body="slotprops">
                {{ ConversionUtility.toDateFormat(slotprops.data.createdAt) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </Card>
  </main>
  <Dialog
    v-model:visible="addBranchVisible"
    :draggable="false"
    modal
    :style="{ width: '40rem' }"
    :closable="false"
  >
    <span class="dialog-header">Add Branch</span>
    <Divider />
    <div class="form">
      <FloatLabel>
        <InputText class="input-text" id="address" v-model="address"></InputText>
        <label for="address" class="mandatory">Address:</label>
      </FloatLabel>
      <FloatLabel>
        <InputText class="input-text" id="city" v-model="city"></InputText>
        <label for="city" class="mandatory">City</label>
      </FloatLabel>
      <FloatLabel>
        <InputMask
          id="pincode"
          v-model="pinCode"
          class="input-text"
          size="large"
          mask="999999?"
          placeholder="999999?"
        />
        <label for="pincode" class="mandatory">Pin Code</label>
      </FloatLabel>
    </div>
    <template #footer>
      <CustomButton :outlined="true" title="close" icon="times" @click="addBranchVisible = false" />
      <CustomButton title="Save" icon="check" @click="handleCreateBranch" />
    </template>
  </Dialog>
  <button class="add-branch-button" @click="addBranchVisible = true">
    <span class="hover-icon">+</span>
    <span class="hover-text">Add branch</span>
  </button>
  <div v-if="isLoading">
    <LoadingComponent text="Please wait while we process the request!" :dialogVisiblity="isLoading" />
  </div>
</template>
<style scoped>
.add-branch-button {
  --p-button-icon-only-width: 50px;
  position: absolute;
  bottom: 7vh;
  right: 7vh;
  width: 3rem;
  height: 3rem;
  transition: width 0.3s ease;
  display: flex;
  font-size: large;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgb(50, 95, 186);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  .hover-icon {
    font-size: 2rem;
  }
  .hover-text {
    display: none;
  }
}
.add-branch-button:hover {
  width: 12rem;
  .hover-text {
    display: block;
    opacity: 0;
    padding-left: 1rem;
    animation: fadeIn 0.2s ease forwards;
    animation-delay: 0.2s;
    animation-play-state: running;
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;
  padding: 1rem 2rem;
  .input-text {
    height: 40px;
  }
}
.table-title {
  text-align: start;
  margin: 0.5rem 2rem;
}
</style>
