<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import { onMounted, ref } from 'vue'
import type ExternalBankDetails from '@/api/models/ExternalBank/ExternalBankDetails'
import type GetBanksReq from '@/api/models/ExternalBank/GetBankReq'
import ApiService from '@/api/ApiService'
import type { ToastMessageOptions } from 'primevue/toast'
import NotFound from '@/assets/not_found.svg'
import ConversionUtility from '../utils/ConversionUtility'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type ExternalTransactionDetails from '@/api/models/ExternalBank/ExternalTransactionDetails'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'

const toast = useToast()
const isLoading = ref(false)

const transactions = ref<ExternalTransactionDetails>()
const existingBanks = ref<ExternalBankDetails[]>([])
const selectedBank = ref('')

onMounted(() => {
  handleGetBanks()
})

function handleGetBanks() {
  isLoading.value = true
  const req: GetBanksReq = {
    query: ''
  }
  ApiService.getBanks(req)
    .then((response) => {
      if (response.statusCode == 200) {
        existingBanks.value = response.data
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Banks', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handleGetTransactions() {
  if (!selectedBank.value) {
    showToast('Error', 'Please select Bank', 'error')
    return
  }
  isLoading.value = true

  const req: { query: string } = {
    query: selectedBank.value
  }
  ApiService.getExternalTrans(req)
    .then((response) => {
      if (response.statusCode == 200) {
        console.log(response.data)
        transactions.value = response.data
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Transactions', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <Toast class="error-p-toast" />
  <main class="main" v-bind="$attrs">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Existing External Banks</h2>
      </template>
      <template #content>
        <Divider />
        <div class="form-2">
          <FloatLabel>
            <Select
              v-model="selectedBank"
              inputId="startDay"
              :options="existingBanks"
              optionLabel="bankName"
              option-value="bankName"
              class="dropdown-select"
              overlayClass="dropdown-overlay"
            />
            <label for="startDay" class="mandatory">Select Bank</label>
          </FloatLabel>

          <CustomButton title="View Transactions" @click="handleGetTransactions" class="cstmbtn" />
        </div>
        <div v-if="transactions == undefined && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="Currently No Banks found!" style="font-weight: 600" />
        </div>
        <div class="cont">
          <div class="holder-details">
            <div>
              <span class="text-heading">Bank Name: </span>
              <span class="text">{{ transactions?._id! }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Account No: </span>
              <span class="text">{{ transactions?.accountNumber! }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Balance: </span>
              <span class="text"
                >{{
                  transactions?.balance!
                    ? ConversionUtility.toStringRupeesConversion(transactions?.balance!)
                    : '--'
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Created On: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(transactions?.createdBy.createdAt!!) }}<br
              /></span>
            </div>
          </div>
          <h2 class="text-3xl font-bold mt-3 text-start">Deposits</h2>
          <DataTable v-if="transactions?.deposits!" :value="transactions?.deposits">
            <Column header="Amount">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.amount) }}
              </template>
            </Column>
            <Column header="Transaction Date">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
              </template>
            </Column>
          </DataTable>
          <Tag v-else class="mb-2" severity="secondary">No deposits found for the time.</Tag><br>
          <h2 class="text-3xl font-bold mt-3 text-start">Withdrawals</h2>
          <DataTable v-if="transactions?.withdraw" :value="transactions?.withdraw">
            <Column header="Amount">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.amount) }}
              </template>
            </Column>
            <Column header="Transaction Date">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
              </template>
            </Column>
          </DataTable>
          <Tag v-else class="mb-2" severity="secondary">No withdrawals found for the time.</Tag><br>
        </div>
      </template>
    </Card>
  </main>
  <div v-if="isLoading">
    <LoadingComponent
      text="Please wait while we process the request!"
      :dialogVisiblity="isLoading"
    />
  </div>
  <ConfirmDialog :draggable="false" :closable="false" />
</template>
<style scoped>
.form-2 {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}

.cstmbtn {
  width: 50%;
}
</style>
