<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue'
import Card from 'primevue/card'
import { ref} from 'vue'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import ConversionUtility from '@/utils/ConversionUtility'
import * as XLSX from 'xlsx'
import type AllSavingsReportDetails from '@/api/models/reports/AllSavingsReportDetails'
import type { ReportRequestByNxtKey } from '@/api/models/reports/ReportRequestByNxtKey'

const loadingProgress = ref<number>(50)
const toast = useToast()
const isLoading = ref<boolean>(false)
const allReportDetails = ref<AllSavingsReportDetails[]>([])
const nextPageKey = ref(null)

function getAllSavingsReportDetails(nextPageKey:any) {
  isLoading.value = true
  const requestParam: ReportRequestByNxtKey = {
    nextPageKey: nextPageKey
  }
  ApiService.allSavingsReport(requestParam)
    .then((response) => {
      if (response.statusCode == 200) {
        console.log(response.data.nextPageKey)
        allReportDetails.value.push(...response.data.data)
        if (response.data.nextPageKey != null) {
          getAllSavingsReportDetails(response.data.nextPageKey)
        }
        if (response.data.nextPageKey == null) {
          downloadFullReport()
        }
      } else {
        showToast('Error', 'No customers', 'error')
      }
    })
    .catch((error) => {
      showToast('Error', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
function downloadFullReport() {
  loadingProgress.value = 25
  isLoading.value = true

  const reportDataDownload = [
    ['Bhavasar Patini Sahakar Sanga', '', ''],
    ['Tq: Badami, Dis: Bagalkot', '', ''],
    [],
    ['SB Details Report'],
    ['Total Customers', allReportDetails.value.length],
    [],
    ['Customer Name', 'Phone Number', 'Balance', 'Created On']
  ]

  allReportDetails.value?.forEach((customer) => {
    reportDataDownload.push([
      customer.customerName,
      customer.customerPhoneNumber,
      customer.balance,
      ConversionUtility.toDateFormat(customer.createdAt)
    ])
  })

  const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload)

  // Merge cells to center the bank name and location
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, // Merge A1:D1 for Bank Name
    { s: { r: 1, c: 0 }, e: { r: 1, c: 3 } } // Merge A2:D2 for Location
  ]

  // Apply styles for bank name and location
  worksheet['A1'].s = {
    font: { bold: true, sz: 18 },
    alignment: { horizontal: 'center', vertical: 'center' }
  }
  worksheet['A2'].s = {
    font: { bold: true, sz: 16 },
    alignment: { horizontal: 'center', vertical: 'center' }
  }

  worksheet['!cols'] = [{ wch: 25 }, { wch: 20 }, { wch: 15 }, { wch: 15 }]
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

  const workbookArrayBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  })
  const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    `SB_Report_Customerwise_as_on_${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`
  )
  document.body.appendChild(link)
  link.click()

  isLoading.value = false
  allReportDetails.value = []
  showToast('Success', 'Report has been downloaded', 'success')
}

</script>

<template>
  <Toast class="error-p-toast" />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">All Savings Accounts Reports</h2>
      </template>
      <template #subtitle>
        <h2 class="title-right text-3xl font-bold">Reports will be downloaded as on today.</h2>
      </template>
      <template #content>
        <Divider />
        <div class="form-2">
          <CustomButton title="Download All Report"  @click="getAllSavingsReportDetails(nextPageKey)" />
        </div>
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
      </template>

      <template #footer> </template>
    </Card>
  </main>
</template>
<style>
.mandatory::after {
  content: ' *';
  color: red;
}

.form-2 {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}

.cstmbtn {
  width: 50%;
}

.input-text {
  height: 45px;
}

.report-details {
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
