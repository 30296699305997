<script setup lang="ts">
import ApiService from '@/api/ApiService';
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData';
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest';
import router from '@/router';
import Storage from '@/utils/Storage';
import NotFound from '@/assets/not_found.svg'
import ValidationUtility from '@/utils/ValidationUtility';
import type { ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ConversionUtility from '@/utils/ConversionUtility';
import SearchComponent from '@/components/SearchComponent.vue';
import CustomButton from '@/components/CustomComponent/CustomButton.vue';
import type { RDSchemes } from '@/api/models/setting/RDScheme';
import DateUtils from '@/utils/DateUtils';
import type { Relationship } from '@/models/Relationship';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import type { CreatedBy } from '@/api/models/CreatedBy';
import type CreateRdRequest from '@/api/models/rd/CreateRdRequest';
import type { UpdatedBy } from '@/api/models/customer/AddCustomerRequest';
import type RD from '@/api/models/rd/Rd';
import { InterestAndTotalCalculator } from '@/utils/InterestAndTotalCalculator';
import type DepositRdRequest from '@/api/models/rd/DepositRdRequest';
import type CloseRdRequest from '@/api/models/rd/CloseRdRequest';
import type RdTransactions from '@/api/models/rd/RdTransactions';

const isLoading = ref(false)
const toast = useToast()
const confirmDialog = useConfirm()

const customerDetails = ref<CustomerDetailsData>()
const nomineeDetails = ref<CustomerDetailsData>()
const nomineeCustomerId = ref<number>()
const isCreateRdVisible = ref(false)
const currentStep = ref('1')
const schemes = ref<RDSchemes[]>([])
const selectedScheme = ref<RDSchemes>()
const rdInterestRate = ref<number>()
const rdAmount = ref<number>()
const startDate = ref<Date>(new Date())
const depositDate = ref<number>()
const minDate = new Date(new Date().setMonth(new Date().getMonth() - 2))
const maxDate = new Date()
const nomineeRelation = ref<Relationship>()
const withInterest = ref<number>()
const interestAmount = ref<number>()
const nomineeVisible = ref(false)
const withdrawRdVisible = ref(false)
const penaltyAmount = ref<number>()
const voucherNo = ref<number>()
const depositAmt = ref<number>()
const withdrawalRd = ref<RD>()
const withdrawTo  = ref('Customer')
const depositRdVisible = ref(false)
const transVisible = ref(false)
const activeRdId = ref<number | null>()


const relationships = ref<Relationship[]>([
  { name: 'Husband', code: 'Husband' },
  { name: 'Wife', code: 'Wife' },
  { name: 'Son', code: 'Son' },
  { name: 'Daughter', code: 'Daughter' },
  { name: 'Siblings', code: 'Siblings' },
  { name: 'Cousin', code: 'Cousin' },
  { name: 'Father', code: 'Father' },
  { name: 'Mother', code: 'Mother' },
  { name: 'Uncle', code: 'Uncle' },
  { name: 'Aunt', code: 'Aunt' },
  { name: 'GrandFather', code: 'Grand Father' },
  { name: 'GrandMother', code: 'Grand Mother' },
  { name: 'FatherInLaw', code: 'Father-In-Law' },
  { name: 'MotherInLaw', code: 'Mother-In-Law' },
  { name: 'UncleInLaw', code: 'Uncle-In-Law' },
  { name: 'AuntInLaw', code: 'Aunt-In-Law' },
  { name: 'Guardian', code: 'Guardian' },
  { name: 'Friend', code: 'Friend' },
  { name: 'Other', code: 'Other' }
])

function onCustomerSearch(query: string) {
  customerDetails.value = undefined
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid Customer ID', 'Please provide search details of the customer', 'error')
    return
  }
  if (Number(query.length) < 6) {
    showToast('Invalid Customer ID', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  isLoading.value = true
  const getCustomerRequest: CustomerDetailsRequest = {
    cusId: query
  }
  ApiService.getCustomerDetails(getCustomerRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customerDetails.value = response.data
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelNomineeSearch(query: string) {
  nomineeDetails.value = undefined
  nomineeCustomerId.value = Number(query)
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid search query', 'Please provide search details of the nominee', 'error')
    return
  }
  if (Number(query.length) < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  if (query == customerDetails.value?.customer?.customerId) {
    showToast('Invalid nominee id', 'Customer cannot be nominee for their own RD.', 'error')
    return
  }
  isLoading.value = true
  const getNomineeRequest: CustomerDetailsRequest = {
    cusId: query
  }
  ApiService.getCustomerDetails(getNomineeRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        if(response.data.sbAccount?.isClosed){
          showToast('Cannot select this customer as nominee', 'Customer ID ' + query +' SB account is closed', 'error')
          return
        }
        nomineeDetails.value = response.data
      } else {
        showToast('Failed to fetch nominee', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch nominee', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function searchRDSchemes(){
  if (!customerDetails.value?.sbAccount?.balance) {
    showToast('You do not have sufficient balance to open RD','Add some balance to create RD','error')
    return
  }

  ApiService.getAllSettings()
  .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        schemes.value = response.data.recurringInterestRates
        isCreateRdVisible.value = true
      } else {
        showToast('Failed to fetch schemes', 'Check RD Schemes avilable!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch schemes', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function calculations() {
  if (!rdAmount.value) {
    showToast('Error', 'Please enter RD amount', 'error');
    return;
  }
  if (ConversionUtility.toPaiseConversion(rdAmount.value) > customerDetails.value?.sbAccount?.balance!){
    showToast('RD amount is Invalid', 'RD Amount should be less than SB Account Balance', 'error');
    return;
  }

//duration in selectedScheme is stored in years

  let paisaRdAmount = ref(ConversionUtility.toPaiseConversion(rdAmount.value));
  let n = 1
  withInterest.value = 0
  interestAmount.value = 0
/*
  withInterest.value = InterestAndTotalCalculator.calculateLoanInterest(
    (paisaRdAmount.value * (selectedScheme.value?.duration!*12)), 
    rdInterestRate.value!,
    new Date(startDate.value).getTime(),
    DateUtils.getTimestampAfterYears(new Date(startDate.value).getTime(), selectedScheme.value?.duration!)
  )
    */

  for (let month = 1; month <= selectedScheme.value?.duration! * 12; month++) {
    withInterest.value += paisaRdAmount.value * Math.pow(1 + rdInterestRate.value! / 100 / n, (n * month) / 12)
  }
  interestAmount.value = Number((withInterest.value - paisaRdAmount.value * (selectedScheme.value?.duration! * 12)).toFixed(2))


/*according to fd formula
interestAmount.value =
    new Date().getFullYear() -
      new Date(customerDetails.value?.customer?.dateOfBirth!!).getFullYear() >=
    60
      ? paisaRdAmount.value *
          Math.pow(
            selectedScheme.value?.scInterestRate!! / 100 + 1,
            selectedScheme.value?.duration!
          ) -
        paisaRdAmount.value
      : paisaRdAmount.value *
          Math.pow(
            selectedScheme.value?.generalInterestRate!! / 100 + 1,
            selectedScheme.value?.duration!
          ) -
          paisaRdAmount.value
  withInterest.value = paisaRdAmount.value + interestAmount.value
  */

}

function depositRD(){
  if(!voucherNo.value || voucherNo.value.toString().length > 8){
    showToast('Voucher No is Invalid/Empty', 'Voucher no should be less than 8 digits', 'error')
    return
  }

  if(!penaltyAmount.value) {
    penaltyAmount.value = 0 
  }
  if(ConversionUtility.toPaiseConversion(penaltyAmount.value) > ConversionUtility.toPaiseConversion(depositAmt.value!)) {
    showToast('Penalty amount is Invalid', 'Penalty amount should be less than deposit amount', 'error')
    return
  }

  if(ConversionUtility.toPaiseConversion(depositAmt.value!) > customerDetails.value?.sbAccount?.balance!){
    showToast('No Sufficient Balance in SB Account', 'Please check your SB Account balance.', 'error')
    return
  }
  isLoading.value = true
  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: new Date().getTime()
  }

  const request: DepositRdRequest = {
    rdId: Number(activeRdId.value),
    customerId: Number(customerDetails.value?.customer?.customerId!),
    accountId: Number(customerDetails.value?.sbAccount?.accountId!),
    depositAmount: ConversionUtility.toPaiseConversion(depositAmt.value!),
    penaltyAmount: ConversionUtility.toPaiseConversion(penaltyAmount.value!),
    createdAt: new Date().getTime(),
    voucherNo: Number(voucherNo.value),
    createdBy: createBy
  }

  ApiService.depositRd(request)
  .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        showToast('Successfully Deposited', 'Rs ' + depositAmt.value + ' Deposited to ' + activeRdId.value, 'success')
      } else {
        showToast('Failed to Deposit', 'No RD Accounts Found', 'error')
      }
    })
    .catch((error) => {
      showToast('Something is wrong', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      onCustomerSearch(customerDetails.value?.customer?.customerId!)
      voucherNo.value = undefined
        penaltyAmount.value = undefined
        depositRdVisible.value = false
        activeRdId.value = undefined
    })
}

function clearAllData() {
  selectedScheme.value = undefined
  rdInterestRate.value = undefined
  currentStep.value = '1'
  depositDate.value = undefined
  startDate.value = new Date()
  rdAmount.value = undefined
  nomineeDetails.value = undefined
  nomineeRelation.value = undefined
  nomineeCustomerId.value = undefined
  interestAmount.value = undefined
  withInterest.value = undefined
}

function handleCreateRD(){
  
  if (!nomineeRelation.value) {
    showToast(
      'Enter nominee relation',
      'No relation between nominee and customer is provided.',
      'error'
    )
    return
  }

  confirmDialog.require({
    message: `Are you sure you want to Create Recurring Deposit with amount ${ConversionUtility.toStringRupees(rdAmount.value!!)} monthly till ${ConversionUtility.toDateFormat(DateUtils.getTimestampAfterYears(new Date(startDate.value!).getTime(), selectedScheme.value?.duration!))} (${selectedScheme.value?.duration!}-Years)?`,
    header: 'Confirmation to create RD',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Create RD'
    },
    accept() {
      if (!rdAmount.value || !startDate.value || !rdInterestRate.value) {
        rdAmount.value = undefined
        rdInterestRate.value = undefined
        showToast('Invalid calculation details', 'Please provide calculation details', 'error')
        return
      }

      isLoading.value = true
      const createBy: CreatedBy = {
        empId: Storage.getEmpId()!!,
        createdAt: new Date().getTime()
      }

      const updatedBy: UpdatedBy = {
        empID: Storage.getEmpId()!!,
        updatedAt: new Date().getTime()
      }

      const request: CreateRdRequest = {
        customerId: Number(customerDetails.value?.customer?.customerId),
        accountId: Number(customerDetails.value?.sbAccount?.accountId!),
        depositAmount: Number(ConversionUtility.toPaiseConversion(rdAmount.value)),
        rdDate: Number(depositDate.value),
        rateOfInterest: Number(rdInterestRate.value),
        interestAmount: Number(interestAmount.value!.toFixed(0)),
        tenureInMonths: Number(selectedScheme.value?.duration! * 12),
        maturityAt: DateUtils.getTimestampAfterYears(new Date(startDate.value).getTime(), selectedScheme.value?.duration!),
        nomineeCustomerId: Number(nomineeDetails.value?.customer?.customerId!),
        nomineeRelationship: nomineeRelation.value?.name!,
        updatedAt: new Date().getTime(),
        updatedBy: updatedBy,
        createdAt: new Date().getTime(),
        createdBy: createBy
      }
      ApiService.createRd(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast('RD Created successfully!', '', 'success')
            isCreateRdVisible.value = false
            onCustomerSearch(customerDetails.value?.customer?.customerId!!)
          } else {
            showToast('Failed to create Rd', '', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to create RD', error, 'error')
        })
        .finally(() => {
          isLoading.value = false
          isCreateRdVisible.value = false
          clearAllData()
        })
    },
    reject() {}
  })
}

function handelWithdraw(){
  if(!voucherNo.value || voucherNo.value.toString().length > 8){
    showToast('Voucher No is Invalid/Empty', 'Voucher No must be less than 8 digits', 'error')
    return
  }
  if(penaltyAmount.value && ConversionUtility.toPaiseConversion(penaltyAmount.value) > withdrawalRd.value?.depositAmount!){
    showToast('Penalty amount is very large', 'Please enter valid penalty amount', 'error')
    return
  }
  isLoading.value = true

  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: new Date().getTime()
  }

  const request: CloseRdRequest = {
    rdId: withdrawalRd.value?.rdId!,
    customerId: withdrawTo.value == 'Customer' ?  withdrawalRd.value?.accountId! : nomineeDetails.value?.sbAccount?.accountId!,
    accountId: withdrawTo.value == 'Customer' ?  withdrawalRd.value?.accountId! : nomineeDetails.value?.sbAccount?.accountId!,
    penaltyAmount: penaltyAmount.value ? ConversionUtility.toPaiseConversion(penaltyAmount?.value!) : null,
    createdAt: new Date().getTime(),
    voucherNo: Number(voucherNo.value),
    createdBy: createBy
  }

  ApiService.closeRd(request)
  .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        showToast('Account Closed Successfully', 'All balance has been withdrawan.', 'success')
      } else {
        showToast('Error', response.error!, 'error')
      }
    })
    .catch((error) => {
      showToast('Something is wrong', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      voucherNo.value = undefined
      onCustomerSearch(customerDetails.value?.customer?.customerId!)
      penaltyAmount.value = undefined
      withdrawRdVisible.value = false
      withdrawalRd.value = undefined
    })

}
function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
    <main class="main">
      <Toast class="error-p-toast" />
      <Card class="search-card">
        <template #content>
          <ConfirmDialog :draggable="false" :closable="false" />
          <SearchComponent
            title="Search RD"
            placeholder="Search with Customer ID."
            subtitle="Search and Create RD's for a Linked SB Account."
            @search-click="onCustomerSearch"
            @enter-press="onCustomerSearch"
          />
          <div v-if="!customerDetails && !isLoading" class="notFound">
            <Image :src="NotFound" width="800" />
            <Chip
              label="No customers found for the provided search query!"
              style="font-weight: 600"
            />
          </div>
          <div v-if="isLoading">
            <LoadingComponent text="Please wait while we process the request!" :dialogVisiblity="isLoading"/>
          </div>

          <div class="cont" v-if="customerDetails?.customer && !isLoading">
            <div class="holder-details">
              <div>
                <span class="text-heading">Customer ID: </span>
                <span class="text">{{ customerDetails?.customer?.customerId }}<br /></span>
              </div>
              <div>
                <span class="text-heading">Customer Name: </span>
                <span class="text">
                  {{ customerDetails?.customer?.firstName }}
                  {{ customerDetails?.customer?.middleName ?? '' }}
                  {{ customerDetails?.customer?.lastName }}<br/>
                </span>
              </div>
              <div>
                <span class="text-heading">Balance: </span>
                <span class="text">
                  {{ ConversionUtility.toStringRupeesConversion(customerDetails?.sbAccount?.balance!!) }}<br/>
                </span>
              </div>
              <div>
                <span class="text-heading">Created On: </span>
                <span class="text">
                  {{ ConversionUtility.toDateFormat(customerDetails?.customer?.createdAt!!) }}<br/>
                </span>
              </div>
            </div>

            <Divider/>

            <h2 class="text-3xl text-start font-bold m-0">Linked RD's</h2>
            <div v-if="customerDetails?.rdAccount!![0]" :value="customerDetails.rdAccount">

              <div class="cont" v-for="rdAccount in customerDetails.rdAccount" :key="rdAccount.rdId">
                <div class="holder-details-rd">
                  <div>
                    <span class="text-heading">RD ID: </span>
                    <span class="text">{{ rdAccount.rdId }}<br/></span>
                  </div>
                  <div>
                    <span class="text-heading">Created On : </span>
                    <span class="text">{{ ConversionUtility.toDateFormat(rdAccount.createdAt) }}<br/></span>
                  </div>
                  <div>
                    <span class="text-heading">Maturity On: </span>
                    <span class="text">{{ ConversionUtility.toDateFormat(rdAccount.maturityAt) }}<br/></span>
                  </div>
                  <div>
                    <span class="text-heading">Duration: </span>
                    <span class="text">{{ rdAccount.tenureInMonths / 12 }} {{ rdAccount.tenureInMonths / 12 > 1 ? 'Years': 'Year' }} <br/></span>
                  </div>
                  <div>
                    <span class="text-heading">Status: </span>
                    <span class="text">
                      <Tag v-if="rdAccount.isClosed" severity="danger">Closed</Tag>
                      <Tag v-else severity="success">Open</Tag>
                      <br/>
                    </span>
                  </div>
                  <div>
                    <span class="text-heading">Deposit Amount: </span>
                    <span class="text">{{ ConversionUtility.toStringRupeesConversion(rdAccount.depositAmount) }}<br/></span>
                  </div>
                    <div>
                      <span class="text-heading">Tot. Investment: </span>
                      <span class="text">{{ ConversionUtility.toStringRupeesConversion(rdAccount.totalDeposit) }}<br/></span>
                    </div>
                    <div>
                      <span class="text-heading">Interest Amount: </span>
                      <span class="text">{{  ConversionUtility.toStringRupeesConversion(rdAccount.interestAmount)  }}<br/></span>
                    </div>

                    <div>
                      <span class="text-heading">Tot. Returns: </span>
                      <span class="text">{{ ConversionUtility.toStringRupeesConversion(rdAccount.interestAmount + rdAccount.totalDeposit) }}<br/></span>
                    </div>
                    <div>
                      <span class="text-heading">Interest Rate: </span>
                      <span class="text">{{ rdAccount.rateOfInterest }}%<br/></span>
                    </div>
                    <div>
                      <span class="text-heading">Deposit Date: </span>
                      <span class="text">{{ rdAccount.rdDate }}{{ rdAccount.rdDate ? ([11, 12, 13].includes(rdAccount.rdDate % 100) ? 'th' : ['st', 'nd', 'rd'][(rdAccount.rdDate % 10) - 1] || 'th') : '' }}<br/></span>
                    </div>
                    <div>
                      <span class="text-heading">Nominee Details: </span>
                      <span class="text cursor-pointer text-blue-800 underline" @click="{handelNomineeSearch(rdAccount.nomineeCustomerId.toString()); nomineeVisible = true}" >{{ rdAccount.nomineeCustomerId }}</span>
                    </div>
                    <Drawer v-model:visible="nomineeVisible" position="bottom" style="height: auto">
                      <Fieldset legend="Nominee Details">
                        <div class="nomineeSection">
                          <div class="nomineeDiv">
                            <span class="text-heading">Nominee Name: </span>
                            <span class="text"
                              >{{ nomineeDetails?.customer?.firstName }}
                              {{ nomineeDetails?.customer?.middleName ?? '' }}
                              {{ nomineeDetails?.customer?.lastName }}<br
                            /></span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Nominee Relation: </span>
                            <span class="text">{{ rdAccount.nomineeRelationship }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Phone number: </span>
                            <span class="text">{{ nomineeDetails?.customer?.phoneNumber }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Customer ID: </span>
                            <span class="text">{{ nomineeDetails?.customer?.customerId }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Email: </span>
                            <span class="text">{{ nomineeDetails?.customer?.email ?? '--' }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">DOB: </span>
                            <span class="text">{{
                              ConversionUtility.toDateFormat(nomineeDetails?.customer?.dateOfBirth!!)
                            }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Aadhar Number: </span>
                            <span class="text">{{ nomineeDetails?.customer?.aadharNumber }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">PAN: </span>
                            <span class="text">{{ nomineeDetails?.customer?.panNumber }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Created on: </span>
                            <span class="text">{{
                              ConversionUtility.toDateFormat(nomineeDetails?.customer?.createdAt!!)
                            }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Address: </span>
                            <span class="text"
                              >{{ nomineeDetails?.customer?.houseNumber
                              }}{{ nomineeDetails?.customer?.addressLine1
                              }}{{ nomineeDetails?.customer?.addressLine2 ?? '' }}</span
                            >
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">Taluk: </span>
                            <span class="text">{{ nomineeDetails?.customer?.taluk }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">City: </span>
                            <span class="text">{{ nomineeDetails?.customer?.city }}</span>
                          </div>
                          <div class="nomineeDiv">
                            <span class="text-heading">District: </span>
                            <span class="text">{{ nomineeDetails?.customer?.district }}</span>
                          </div>
                        </div>
                      </Fieldset>
                    </Drawer>

                </div>
                <div class="operations ">
                  <div class="button-transactions">
                    <CustomButton 
                    icon="book" 
                    title="View transactions" 
                    @click="(
                      activeRdId = rdAccount.rdId, 
                      transVisible = !transVisible, 
                      depositRdVisible= false
                      )" 
                      />
                    <CustomButton 
                    v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2' || Storage.getEmpAccessRole() == null)" 
                    icon="indian-rupee" 
                    :disabled="rdAccount.isClosed! || rdAccount.transactions?.length == rdAccount.tenureInMonths" 
                    title="Deposit" 
                    @click="(
                      activeRdId = rdAccount.rdId, 
                      voucherNo =  undefined, 
                      depositRdVisible= !depositRdVisible, 
                      transVisible= false, 
                      depositAmt = ConversionUtility.toRuppesCoversion(rdAccount.depositAmount)
                      )" 
                      />
                    <Tag v-if="rdAccount.transactions?.length == rdAccount.tenureInMonths" severity="warn">All installments are paid for this RD ID.</Tag><br>
                  </div>
                  <CustomButton 
                  v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2' || Storage.getEmpAccessRole() == null)" 
                  icon="ban" 
                  severity="danger" 
                  title="Close Account" 
                  @click="(
                    withdrawRdVisible = true, 
                    withdrawalRd = rdAccount,
                    withdrawTo = 'Customer',
                    handelNomineeSearch(rdAccount.nomineeCustomerId.toString()))"
                    :disabled="rdAccount.isClosed!" 
                    />
                </div>

                <div v-if="activeRdId === rdAccount.rdId && depositRdVisible" class="operations mt-5">
                  <div class="button-transactions">
                    <FloatLabel>
                      <InputNumber
                      v-model="depositAmt"
                      class="amount-input"
                      mode="currency"
                      currency="INR"
                      :min-fraction-digits="0"
                      :min="0"
                      fluid
                      locale="en-IN"
                      :disabled="true"
                    />
                    </FloatLabel>
                    <InputNumber
                      v-model="voucherNo"
                      :useGrouping="false"
                      class="input-text"
                      :min="1"
                      placeholder="Voucher No"
                    />
                    <InputNumber
                    v-if="rdAccount.transactions?.length >= 0 &&(
                      new Date(rdAccount.transactions[rdAccount.transactions.length-1!].createdAt).getFullYear() == new Date().getFullYear() &&
                      new Date(rdAccount.transactions[rdAccount.transactions.length-1!].createdAt).getMonth() < new Date().getMonth() &&
                      new Date().getDate() > rdAccount.rdDate
                      )"
                      v-model="penaltyAmount"
                      class="amount-input"
                      mode="currency"
                      currency="INR"
                      :min-fraction-digits="0"
                      :min="0"
                      fluid
                      locale="en-IN"
                      placeholder="Penalty Amount"
                    />
                    
                  </div>
                  <div class="flex gap-4">
                    <CustomButton icon="indian-rupee" title="Deposit" @click="depositRD"  :disabled="rdAccount.isClosed!" v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == null)" />
                    <CustomButton icon="times" :outlined="true" title="Close" @click="(voucherNo = undefined, depositRdVisible = false)"  v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == null)" />
                  </div>
                </div>
                <Panel class='mt-5' header="Transaction Details" v-if="activeRdId === rdAccount.rdId && transVisible && rdAccount.transactions" toggleable>
                  <DataTable 
                  :value="rdAccount.transactions"
                  :sortable="true" 
                  :sortFunction="(e:any) => e.data.sort((a:RdTransactions, b:RdTransactions) => e.order * (b.createdAt - a.createdAt))"
                  >
                  <Column header="Voucher No" field="voucherNo"></Column>
                  <Column header="Date">
                    <template #body="slotProps">
                      {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
                    </template>
                  </Column>
                  <Column header="Opening Balance">
                    <template #body="slotProps">
                      {{ ConversionUtility.toStringRupeesConversion(slotProps.data.openingBalance) }}
                    </template>
                  </Column>
                  <Column header="Deposit">
                    <template #body="slotProps">
                      {{ ConversionUtility.toStringRupeesConversion(slotProps.data.deposit) }}
                    </template>
                  </Column>
                  <Column header="Penalty Amount">
                    <template #body="slotProps">
                      {{ ConversionUtility.toStringRupeesConversion(slotProps.data.penaltyAmount) }}
                    </template>
                  </Column>
                  <Column header="Withdraw">
                    <template #body="slotProps">
                      {{ ConversionUtility.toStringRupeesConversion(slotProps.data.withdrawal) }}
                    </template>
                  </Column>
                  <Column header="Closing Balance">
                    <template #body="slotProps">
                      {{ ConversionUtility.toStringRupeesConversion(slotProps.data.closingBalance) }}
                    </template>
                  </Column>
                </DataTable>
                </Panel>
                <Tag v-if="activeRdId === rdAccount.rdId && transVisible && !rdAccount.transactions " class="mt-2" severity="secondary">No transactions found.</Tag><br>
              </div>

            </div>

            <div v-else>
                <Tag class="mb-2" severity="secondary">No RD's linked to this customer.</Tag><br>
                <Tag v-if="customerDetails?.sbAccount?.isClosed" severity="danger">SB Account has closed for this customer.Can't create RD A/C.</Tag>
            </div>
            <div class="end-button-container">
                <CustomButton
                v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2' || Storage.getEmpAccessRole() == null)" 
                title="Create new RD"
                icon="add"
                @click="searchRDSchemes"
                :disabled="customerDetails?.sbAccount?.isClosed"
                />
            </div>
        </div>

        <Dialog
          :visible="withdrawRdVisible"
          :header="`Withdraw ${new Date().getTime() < withdrawalRd?.maturityAt!! ? 'Premature' : ''} RD no.-${withdrawalRd?.rdId}`"
          modal
          :draggable="false"
          :closable="false"
          style="width: 55vw"
        >
          <div class="cont">
            <div class="holder-details" style="flex-direction: column">
              <div style="padding-bottom: 1rem">
                <span class="text-heading">Name: </span>
                <span class="text"
                  >{{ customerDetails?.customer?.firstName }}
                  {{ customerDetails?.customer?.middleName ?? '' }}
                  {{ customerDetails?.customer?.lastName }}</span
                >
              </div>
              <Panel header="Details" :toggleable="false">
                <div class="rd-withdrawal-div">
                  <span class="text-heading">Withdraw RD to</span>
                  <span class="radio-button">
                    <span class="radio-button-options">
                      <RadioButton
                        v-model="withdrawTo"
                        inputId="account1"
                        name="customer"
                        value="Customer"
                      />
                      <label for="account1" class="text-heading">Customer</label>
                    </span>
                    <span class="radio-button-options">
                      <RadioButton
                        v-model="withdrawTo"
                        inputId="account2"
                        name="customer"
                        value="Nominee"
                        :disabled="nomineeDetails?.sbAccount?.isClosed!"
                      />
                      <label for="account2" class="text-heading">Nominee</label>
                    </span>
                  </span>
                </div>
                <div class="rd-withdrawal-div">
                  <span class="text-heading">Principal Amount </span>
                  <span class="text"
                  >{{ withdrawalRd?.transactions?.length! >= 0 ?
                   ConversionUtility.toStringRupeesConversion(withdrawalRd?.transactions[withdrawalRd.transactions.length-1].closingBalance!)
                    : 0}}<br
                  /></span>
                </div>
                <div class="rd-withdrawal-div">
                  <span class="text-heading"
                    >Total Interest for @{{withdrawalRd?.rateOfInterest}}% {{ new Date().getTime() < withdrawalRd?.maturityAt!! &&  withdrawalRd?.transactions?.length == withdrawalRd?.tenureInMonths  ? '' : '(Pre-Mature)'}}</span
                  >
                  <span class="text"
                    >{{ new Date().getTime() > withdrawalRd?.maturityAt!! ||  withdrawalRd?.transactions?.length == withdrawalRd?.tenureInMonths ? ConversionUtility.toStringRupeesConversion(withdrawalRd?.interestAmount!!) : ConversionUtility.toStringRupeesConversion(0)}}<br
                  /></span>
                </div>
                <div style="padding-top: 1.5rem" class="rd-withdrawal-div">
                  <span class="text-heading"></span>
                  <span>
                    <FloatLabel v-if="new Date().getTime() < withdrawalRd?.maturityAt!! || withdrawalRd?.transactions?.length == withdrawalRd?.tenureInMonths">
                      <InputNumber
                        v-model="penaltyAmount"
                        locale="en-IN"
                        prefix="- ₹ "
                        id="penaltyAmount"
                        :min="0"
                        @beforeinput="
                          () => {
                            if (
                              penaltyAmount! >
                              ConversionUtility.toRuppesCoversion(
                                withdrawalRd?.interestAmount!! +
                                  withdrawalRd?.totalDeposit!!
                              )
                            ) {
                              showToast(
                                'Penalty Amount is greater than total RD amount',
                                '',
                                'error'
                              )
                            }
                          }
                        "
                        :disabled="withdrawalRd?.transactions?.length == withdrawalRd?.tenureInMonths"
                      />
                      <label for="penaltyAmount">Enter penalty amount </label>
                    </FloatLabel>
                    <br />
                    <FloatLabel>
                    <InputNumber
                      :useGrouping="false"
                      v-model="voucherNo"
                      id="voucher"
                      :min="1"
                    />
                    <label for="voucher" class="mandatory">Voucher No</label>
                    </FloatLabel>
                  </span>
                </div>
                <div
                  class="rd-withdrawal-div"
                  v-if="new Date().getTime() < withdrawalRd?.maturityAt!!"
                >
                  <span class="note"
                    ><span class="mandatory" style="color: red">*</span>After entering penalty
                    amount press enter.</span
                  >
                </div>
                <Divider />
                <div class="rd-withdrawal-div">
                  <span class="text-heading">Total: </span>
                  <span class="text"
                    >{{ penaltyAmount ? ConversionUtility.toStringRupeesConversion(
                                (withdrawalRd?.transactions.length! >= 0 ? withdrawalRd?.transactions[withdrawalRd.transactions.length-1].closingBalance! : 0) + (new Date().getTime() >= withdrawalRd?.maturityAt!! ? withdrawalRd?.interestAmount!! : 0)!! - ConversionUtility.toPaiseConversion(penaltyAmount)) :  
                                ConversionUtility.toStringRupeesConversion((withdrawalRd?.transactions?.length! >= 0 ? withdrawalRd?.transactions[withdrawalRd.transactions.length-1].closingBalance! : 0) + (new Date().getTime() >= withdrawalRd?.maturityAt!! ||  withdrawalRd?.transactions?.length == withdrawalRd?.tenureInMonths ? withdrawalRd?.interestAmount!! : 0))
                    }}<br
                  /></span>
                </div>
              </Panel>
            </div>
            <div class="operations">
              <p></p>
              <CustomButton
                title="Withdraw and Close RD"
                icon="money-bill"
                @click="handelWithdraw()"
              />
            </div>
          </div>
          <div class="end-button-container">
            <CustomButton
              title="close"
              icon="times"
              @click="(withdrawRdVisible = false), (withdrawalRd = undefined),(penaltyAmount = undefined), (voucherNo = undefined)"
            />
          </div>
        </Dialog>

        <Dialog
          header="Create new Recurring Deposit"
          :visible="isCreateRdVisible"
          modal
          :closable="false"
          :draggable="false"
          style="width: 61rem"
        >
          <Stepper :value="currentStep" linear>
            <StepList value="">
              <Step value="1">Select a Scheme</Step>
              <Step value="2">RD Details</Step>
              <Step value="3">Nominee Details</Step>
            </StepList>
            <StepPanels value="">
              <StepPanel value="1">
                <div class="dialog-container">
                  <Fieldset class="schemes" legend="Schemes">
                    <div class="next-tag center-tag">
                      <Tag severity="secondary"
                        >Once you select a scheme you will be redirected to the next step.
                      </Tag>
                    </div>
                    <div>
                      <Panel header="Select a Scheme">
                        <DataTable
                          v-model:selection="selectedScheme"
                          @row-select="
                            (currentStep = '2'),
                              (rdInterestRate =
                                new Date().getFullYear() -
                                  new Date(
                                    customerDetails?.customer?.dateOfBirth!!
                                  ).getFullYear() >=
                                60
                                  ? selectedScheme?.scInterestRate
                                    ? selectedScheme?.scInterestRate
                                    : undefined
                                  : selectedScheme?.generalInterestRate
                                    ? selectedScheme?.generalInterestRate
                                    : undefined)"
                          :value="schemes"
                          dataKey="id"
                          tableStyle="min-width: 50rem"
                        >
                        <Column selectionMode="single" ></Column>

                          <Column header="Duration" field="duration">
                            <template #body="slotProps">
                              {{ slotProps.data.duration }} - {{ slotProps.data.duration == 1 ? 'Year' : 'Years' }}
                            </template>
                          </Column>
                          <Column header="General Interest Rates" field="generalInterestRates" >
                            <template #body="slotProps">
                              {{ slotProps.data.generalInterestRate ?? `null}` }}
                              %
                            </template>
                          </Column>
                          <Column header="Senior Citizen Interest Rates" field="scInterestRate">
                            <template #body="slotProps">
                              {{ slotProps.data.scInterestRate ?? `null}` }}
                              %
                            </template>
                          </Column>
                        </DataTable>
                      </Panel>
                    </div>
                  </Fieldset>
                </div>
              </StepPanel>
              <StepPanel value="2">
                  <Fieldset legend="Selected Scheme">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Duration: </span>
                        <span class="text"
                          >{{ selectedScheme?.duration }} - {{ selectedScheme?.duration! == 1 ? 'Year' : 'Years'}}</span>
                      </div>
                      <div>
                        <span class="text-heading">Applicable Interest Rate: </span>
                        <span class="text">{{ rdInterestRate }}%</span>
                      </div>
                      <div>
                        <span class="text-heading">Balance: </span>
                        <span class="text">{{ ConversionUtility.toStringRupeesConversion( customerDetails?.sbAccount?.balance!!)}}</span>
                      </div>
                    </div>
                    <div class="center-tag">
                      <Tag severity="secondary"
                        >*Principal amount should be less than customers balance.
                      </Tag>
                    </div>
                  </Fieldset>


                  <Fieldset legend="RD Calculator">
                    <div class="holder-details mt-5">
                        <FloatLabel>
                          <InputNumber
                            v-model="rdAmount"
                            input-id="intgeronly"
                            :min="0"
                            locale="en-IN"
                            prefix="₹ "
                            id="amount"
                            class="input-text"
                          />
                          <label for="amount" class="mandatory">Enter RD amount</label>
                        </FloatLabel>

                        <FloatLabel>
                          <InputNumber
                            v-model="depositDate"
                            input-id="intgeronly"
                            :min="0"
                            locale="en-IN"
                            :suffix="depositDate ? ([11, 12, 13].includes(depositDate % 100) ? 'th' : ['st', 'nd', 'rd'][(depositDate % 10) - 1] || 'th') : ''"
                            id="date"
                            :max="28"
                            class="input-text"
                          />
                          <label for="date" class="mandatory">Enter deposit date</label>
                        </FloatLabel>

                        <FloatLabel>
                          <label for="selectedDate" class="mandatory">From</label>
                          <DatePicker
                          :disabled="true"
                            v-model:model-value="startDate"
                            id="selectedDate"
                            class="input-text"
                            :min-date="minDate"
                            :maxDate="maxDate"
                            dateFormat="dd/mm/yy"
                          />
                        </FloatLabel>
                        <CustomButton title="Calculate" icon="calculator" @click="calculations" />
                      </div>
                  </Fieldset>
                  <Fieldset legend="RD Details">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Interest Rate: </span>
                        <span class="text">{{ rdInterestRate }}%</span>
                      </div>
                      <div>
                        <span class="text-heading">RD matures on: </span>
                        <span class="text">{{ 
                        startDate ? ConversionUtility.toDateFormat(DateUtils.getTimestampAfterYears(new Date(startDate).getTime(), selectedScheme?.duration!)) : '---' 
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Principal Amount: </span>
                        <span class="text">{{
                          rdAmount ? ConversionUtility.toStringRupees(rdAmount!! * selectedScheme?.duration! * 12) : ' --- '
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Interest Amount: </span>
                        <span class="text">{{ interestAmount ? ConversionUtility.toStringRupeesConversion(interestAmount) : '---'
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Total Amount: </span>
                        <span class="text">{{ withInterest ? ConversionUtility.toStringRupeesConversion(withInterest) : '---'
                        }}</span>
                      </div>
                    </div>
                    <div class="operations">
                      <p></p>
                      <CustomButton
                        title="Next"
                        icon="add"
                        :outlined="true"
                        :disabled="!interestAmount"
                        @click="depositDate ? currentStep = '3': showToast('Deposit date is empty','Please enter deposit date.', 'error')"
                      />
                    </div>
                    <p class="note">
                      Note: The Interest rate is applied based on the age of Customer.
                    </p>
                  </Fieldset>
              </StepPanel>

              <StepPanel value="3">
                  <span class="note">Note: Nominee has to be existing customer in the bank.</span>
                  <div class="cont" style="padding-top: 10px">
                    <SearchComponent
                      title="Search Nominee"
                      placeholder="Search with Customer ID."
                      subtitle="Search for a nominee using Customer ID."
                      @search-click="handelNomineeSearch"
                      @enter-press="handelNomineeSearch"
                    />
                    <Fieldset legend="Nominee Details" v-if="nomineeDetails">
                      <div class="holder-details">
                        <div>
                          <span class="text-heading">Nominee name: </span>
                          <span class="text"
                            >{{ nomineeDetails.customer?.firstName }}
                            {{ nomineeDetails.customer?.middleName ?? '' }}
                            {{ nomineeDetails.customer?.lastName }}</span
                          >
                        </div>
                        <div>
                          <span class="text-heading">Phone number: </span>
                          <span class="text">{{ nomineeDetails.customer?.phoneNumber }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Created on: </span>
                          <span class="text">{{
                            ConversionUtility.toDateFormat(nomineeDetails.customer?.createdAt!!)
                          }}</span>
                        </div>
                      </div>
                      <div class="operations">
                        <Select
                          class="relationship-select"
                          placeholder="Select relation with nominee"
                          v-model="nomineeRelation"
                          inputId="relationships"
                          :options="relationships"
                          filter
                          optionLabel="name"
                          overlayClass="gender-overlay"
                        />
                        <CustomButton
                          title="Create RD"
                          icon="add"
                          :outlined="true"
                          :disabled="!nomineeRelation && !nomineeCustomerId"
                          @click="handleCreateRD()"
                        />
                      </div>
                    </Fieldset>
                  </div>
              </StepPanel>
            </StepPanels>
          </Stepper>
          <div class="end-button-container">
            <CustomButton
              title="Back"
              icon="arrow-left"
              class="mr-2"
              :outlined="true"
              :disabled="true"
              @click="currentStep = (Number(currentStep) - 1).toString()"
            />
            <CustomButton
              title="close"
              icon="times"
              @click="(isCreateRdVisible = false, clearAllData())"
            />
          </div>
        </Dialog>

        </template>
      </Card>  
    </main>
</template>

<style scoped>
.nomineeDiv {
  display: flex;
  flex-direction: column;

  .header {
    font-size: small;
    margin-bottom: 5px;
  }

  .field {
    font-weight: 600;
  }
}

.nomineeSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 15px;
  row-gap: 20px;
  padding: 1rem;
}

.rd-withdrawal-div {
  display: flex;
  justify-content: space-between;
}

.end-button-container {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: end;
}

.dialog-container {
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.schemes {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  flex-direction: column;
  gap: 1rem;

  .next-tag {
    margin: 0 0 1rem 0;
  }
}

.center-tag {
  display: flex;
  justify-content: center;
}


.radio-button {
  display: flex;
  gap: 1rem;
  padding-bottom: 0.5rem;

  .radio-button-options {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.holder-details-rd {
  display: grid;
  grid-template-columns: repeat(5, 5fr);
  gap: 1rem; 
  margin-bottom: 1rem;
}


.relationship-select {
  width: 30vw;
  height: 45px;
  font-family: Epilogue, serif;
}
</style>