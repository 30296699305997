<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue'
import Card from 'primevue/card'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import type { ToastMessageOptions } from 'primevue/toast'
import ConversionUtility from '@/utils/ConversionUtility'
import * as XLSX from 'xlsx'
import ProgressBar from 'primevue/progressbar'
import type AllLoanReportData from '@/api/models/reports/AllLoanReportData'
import type GetAllLoanReportsReq from '@/api/models/reports/GetAllLoanReportReq'

const loadingProgress = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const nextPageKey = ref(null)
const minDate = ref(new Date(2024, 8, 1))
const maxDate = ref(new Date())
const fromDate = ref<Date>()
const toDate = ref<Date | null>()
const isClosed = ref(false)
const loanReport = ref<AllLoanReportData[]>([])

function getLoanReports(nextKey: number) {
  if (fromDate.value && !toDate.value) {
    showToast('To Date cannot be empty', 'You have selected from date. Please Select To Date', 'error')
    return
  }

  if (toDate.value && !fromDate.value) {
    showToast('From Date cannot be empty', 'You have selected to date. Please Select To Date', 'error')
    return
  }

  isLoading.value = true

  const requestParam: GetAllLoanReportsReq = {
    startDate: fromDate.value ? new Date(fromDate.value).setHours(0, 0, 0, 0) : null,
    endDate: toDate.value ? new Date(toDate.value).setHours(23, 59, 59, 999) : null,
    isClosed: isClosed.value ? isClosed.value : null,
    nextPageKey: nextKey
  }

  ApiService.getAllLoanReports(requestParam)
    .then((response) => {
      if (response.statusCode == 200) {
        loanReport.value.push(...response.data.data)
        if (response.data.nextPageKey != null) {
          getLoanReports(response.data.nextPageKey)
        }
        if (response.data.nextPageKey == null) {
          downloadLoanReport()
        }
      } else {
        showToast('Error', 'No loan records found', 'error')
      }
    })
    .catch((error) => {
      showToast('Error', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function downloadLoanReport() {
  loadingProgress.value = 25
  isLoading.value = true

  const reportDataDownload = [
    ['Loan Report Detail'],
    ['Total Loans', loanReport.value.length],
    [],
    [
      'Sl No',
      'Loan ID',
      'Customer ID',
      'Customer Name',
      'Guarantor 1 ID',
      'Guarantor 1 Name',
      'Guarantor 2 ID',
      'Guarantor 2 Name',
      'Director ID',
      'Director Name',
      'Loan Type',
      'Loan Amount',
      'EMI Amount',
      'Total Paid',
      'Outstanding Amount',
      'Remaining Principal',
      'Remaining Interest',
      'Loan Start Date',
      'Loan End Date',
      'Next Due Date',
      'EMI Date',
      'Loan Status',
      'Branch Code',
      'Created At'
    ]
  ]

  let index = 0
  loanReport.value?.forEach((loan) => {
    index += 1
    reportDataDownload.push([
      index,
      loan.loanId,
      loan.customerId,
      loan.customerName,
      loan.guarantors[0] || '',
      loan.guarantorName1,
      loan.guarantors[1] || '',
      loan.guarantorName2,
      loan.directorId,
      loan.directorName,
      loan.loanType,
      ConversionUtility.toRuppesCoversion(loan.loanAmount),
      ConversionUtility.toRuppesCoversion(loan.emiAmount),
      ConversionUtility.toRuppesCoversion(loan.totalPaid),
      ConversionUtility.toRuppesCoversion(loan.outstandingAmount),
      ConversionUtility.toRuppesCoversion(loan.remainingPrincipalAmount),
      ConversionUtility.toRuppesCoversion(loan.remainingInterestAmount),
      ConversionUtility.toDateFormat(loan.loanStartDate),
      ConversionUtility.toDateFormat(loan.loanEndDate),
      ConversionUtility.toDateFormat(loan.nextDueDate),
      ConversionUtility.toDateFormat(loan.emiDate),
      loan.loanStatus,
      loan.branchCode,
      ConversionUtility.toDateFormat(loan.createdAt)
    ])
  })

  const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload)
  worksheet['!cols'] = Array(24).fill({ wch: 20 }) // Set all columns width
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' })

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    `All_Loan_Detail_Report as on ${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`
  )
  document.body.appendChild(link)
  link.click()
  isLoading.value = false
  loanReport.value = []
  showToast('Success', 'Loan report has been downloaded', 'success')
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <Toast class="error-p-toast" />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Loan Report</h2>
      </template>
      <template #content>
        <Divider />
        <div class="form-2">
          <FloatLabel>
            <label for="fromDate">From</label>
            <DatePicker
              v-model="fromDate"
              id="fromDate"
              class="input-text"
              :min-date="minDate"
              :max-date="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>
          <FloatLabel>
            <label for="toDate">To</label>
            <DatePicker
              v-model="toDate"
              id="toDate"
              class="input-text"
              :min-date="fromDate"
              :max-date="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>

          <div style="align-items: start; display: flex; justify-content: space-evenly">
            <label for="toDate">Get only Closed: </label>
            <ToggleSwitch v-model="isClosed" />
          </div>
          <CustomButton title="Download" @click="getLoanReports(0)" class="cstmbtn" />
        </div>

        <Dialog
          :visible="isLoading"
          modal
          :draggable="false"
          :closable="false"
          style="width: 60vw"
          header="Downloading Loan Report..."
        >
          <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
        </Dialog>
      </template>
    </Card>
  </main>
</template>
